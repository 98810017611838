import {Observable, of} from "rxjs";
import {Injectable} from "@angular/core";

export const acquisition_types = {
    ACQUISITION_COMMUNITY_ACQUIRED: 'Community Acquired',
    ACQUISITION_NOSOCOMIAL: 'Nosocomial'
};

const TIMEZONES = [
    'Africa/Abidjan',
    'Africa/Accra',
    'Africa/Addis_Ababa',
    'Africa/Algiers',
    'Africa/Asmara',
    'Africa/Asmera',
    'Africa/Bamako',
    'Africa/Bangui',
    'Africa/Banjul',
    'Africa/Bissau',
    'Africa/Blantyre',
    'Africa/Brazzaville',
    'Africa/Bujumbura',
    'Africa/Cairo',
    'Africa/Casablanca',
    'Africa/Ceuta',
    'Africa/Conakry',
    'Africa/Dakar',
    'Africa/Dar_es_Salaam',
    'Africa/Djibouti',
    'Africa/Douala',
    'Africa/El_Aaiun',
    'Africa/Freetown',
    'Africa/Gaborone',
    'Africa/Harare',
    'Africa/Johannesburg',
    'Africa/Juba',
    'Africa/Kampala',
    'Africa/Khartoum',
    'Africa/Kigali',
    'Africa/Kinshasa',
    'Africa/Lagos',
    'Africa/Libreville',
    'Africa/Lome',
    'Africa/Luanda',
    'Africa/Lubumbashi',
    'Africa/Lusaka',
    'Africa/Malabo',
    'Africa/Maputo',
    'Africa/Maseru',
    'Africa/Mbabane',
    'Africa/Mogadishu',
    'Africa/Monrovia',
    'Africa/Nairobi',
    'Africa/Ndjamena',
    'Africa/Niamey',
    'Africa/Nouakchott',
    'Africa/Ouagadougou',
    'Africa/Porto-Novo',
    'Africa/Sao_Tome',
    'Africa/Timbuktu',
    'Africa/Tripoli',
    'Africa/Tunis',
    'Africa/Windhoek',
    'America/Adak',
    'America/Anchorage',
    'America/Anguilla',
    'America/Antigua',
    'America/Araguaina',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Catamarca',
    'America/Argentina/ComodRivadavia',
    'America/Argentina/Cordoba',
    'America/Argentina/Jujuy',
    'America/Argentina/La_Rioja',
    'America/Argentina/Mendoza',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Salta',
    'America/Argentina/San_Juan',
    'America/Argentina/San_Luis',
    'America/Argentina/Tucuman',
    'America/Argentina/Ushuaia',
    'America/Aruba',
    'America/Asuncion',
    'America/Atikokan',
    'America/Atka',
    'America/Bahia',
    'America/Bahia_Banderas',
    'America/Barbados',
    'America/Belem',
    'America/Belize',
    'America/Blanc-Sablon',
    'America/Boa_Vista',
    'America/Bogota',
    'America/Boise',
    'America/Buenos_Aires',
    'America/Cambridge_Bay',
    'America/Campo_Grande',
    'America/Cancun',
    'America/Caracas',
    'America/Catamarca',
    'America/Cayenne',
    'America/Cayman',
    'America/Chicago',
    'America/Chihuahua',
    'America/Ciudad_Juarez',
    'America/Coral_Harbour',
    'America/Cordoba',
    'America/Costa_Rica',
    'America/Creston',
    'America/Cuiaba',
    'America/Curacao',
    'America/Danmarkshavn',
    'America/Dawson',
    'America/Dawson_Creek',
    'America/Denver',
    'America/Detroit',
    'America/Dominica',
    'America/Edmonton',
    'America/Eirunepe',
    'America/El_Salvador',
    'America/Ensenada',
    'America/Fort_Nelson',
    'America/Fort_Wayne',
    'America/Fortaleza',
    'America/Glace_Bay',
    'America/Godthab',
    'America/Goose_Bay',
    'America/Grand_Turk',
    'America/Grenada',
    'America/Guadeloupe',
    'America/Guatemala',
    'America/Guayaquil',
    'America/Guyana',
    'America/Halifax',
    'America/Havana',
    'America/Hermosillo',
    'America/Indiana/Indianapolis',
    'America/Indiana/Knox',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Tell_City',
    'America/Indiana/Vevay',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indianapolis',
    'America/Inuvik',
    'America/Iqaluit',
    'America/Jamaica',
    'America/Jujuy',
    'America/Juneau',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Knox_IN',
    'America/Kralendijk',
    'America/La_Paz',
    'America/Lima',
    'America/Los_Angeles',
    'America/Louisville',
    'America/Lower_Princes',
    'America/Maceio',
    'America/Managua',
    'America/Manaus',
    'America/Marigot',
    'America/Martinique',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Mendoza',
    'America/Menominee',
    'America/Merida',
    'America/Metlakatla',
    'America/Mexico_City',
    'America/Miquelon',
    'America/Moncton',
    'America/Monterrey',
    'America/Montevideo',
    'America/Montreal',
    'America/Montserrat',
    'America/Nassau',
    'America/New_York',
    'America/Nipigon',
    'America/Nome',
    'America/Noronha',
    'America/North_Dakota/Beulah',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/Nuuk',
    'America/Ojinaga',
    'America/Panama',
    'America/Pangnirtung',
    'America/Paramaribo',
    'America/Phoenix',
    'America/Port-au-Prince',
    'America/Port_of_Spain',
    'America/Porto_Acre',
    'America/Porto_Velho',
    'America/Puerto_Rico',
    'America/Punta_Arenas',
    'America/Rainy_River',
    'America/Rankin_Inlet',
    'America/Recife',
    'America/Regina',
    'America/Resolute',
    'America/Rio_Branco',
    'America/Rosario',
    'America/Santa_Isabel',
    'America/Santarem',
    'America/Santiago',
    'America/Santo_Domingo',
    'America/Sao_Paulo',
    'America/Scoresbysund',
    'America/Shiprock',
    'America/Sitka',
    'America/St_Barthelemy',
    'America/St_Johns',
    'America/St_Kitts',
    'America/St_Lucia',
    'America/St_Thomas',
    'America/St_Vincent',
    'America/Swift_Current',
    'America/Tegucigalpa',
    'America/Thule',
    'America/Thunder_Bay',
    'America/Tijuana',
    'America/Toronto',
    'America/Tortola',
    'America/Vancouver',
    'America/Virgin',
    'America/Whitehorse',
    'America/Winnipeg',
    'America/Yakutat',
    'America/Yellowknife',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville',
    'Antarctica/Macquarie',
    'Antarctica/Mawson',
    'Antarctica/McMurdo',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/South_Pole',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'Arctic/Longyearbyen',
    'Asia/Aden',
    'Asia/Almaty',
    'Asia/Amman',
    'Asia/Anadyr',
    'Asia/Aqtau',
    'Asia/Aqtobe',
    'Asia/Ashgabat',
    'Asia/Ashkhabad',
    'Asia/Atyrau',
    'Asia/Baghdad',
    'Asia/Bahrain',
    'Asia/Baku',
    'Asia/Bangkok',
    'Asia/Barnaul',
    'Asia/Beirut',
    'Asia/Bishkek',
    'Asia/Brunei',
    'Asia/Calcutta',
    'Asia/Chita',
    'Asia/Choibalsan',
    'Asia/Chongqing',
    'Asia/Chungking',
    'Asia/Colombo',
    'Asia/Dacca',
    'Asia/Damascus',
    'Asia/Dhaka',
    'Asia/Dili',
    'Asia/Dubai',
    'Asia/Dushanbe',
    'Asia/Famagusta',
    'Asia/Gaza',
    'Asia/Harbin',
    'Asia/Hebron',
    'Asia/Ho_Chi_Minh',
    'Asia/Hong_Kong',
    'Asia/Hovd',
    'Asia/Irkutsk',
    'Asia/Istanbul',
    'Asia/Jakarta',
    'Asia/Jayapura',
    'Asia/Jerusalem',
    'Asia/Kabul',
    'Asia/Kamchatka',
    'Asia/Karachi',
    'Asia/Kashgar',
    'Asia/Kathmandu',
    'Asia/Katmandu',
    'Asia/Khandyga',
    'Asia/Kolkata',
    'Asia/Krasnoyarsk',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Asia/Kuwait',
    'Asia/Macao',
    'Asia/Macau',
    'Asia/Magadan',
    'Asia/Makassar',
    'Asia/Manila',
    'Asia/Muscat',
    'Asia/Nicosia',
    'Asia/Novokuznetsk',
    'Asia/Novosibirsk',
    'Asia/Omsk',
    'Asia/Oral',
    'Asia/Phnom_Penh',
    'Asia/Pontianak',
    'Asia/Pyongyang',
    'Asia/Qatar',
    'Asia/Qostanay',
    'Asia/Qyzylorda',
    'Asia/Rangoon',
    'Asia/Riyadh',
    'Asia/Saigon',
    'Asia/Sakhalin',
    'Asia/Samarkand',
    'Asia/Seoul',
    'Asia/Shanghai',
    'Asia/Singapore',
    'Asia/Srednekolymsk',
    'Asia/Taipei',
    'Asia/Tashkent',
    'Asia/Tbilisi',
    'Asia/Tehran',
    'Asia/Tel_Aviv',
    'Asia/Thimbu',
    'Asia/Thimphu',
    'Asia/Tokyo',
    'Asia/Tomsk',
    'Asia/Ujung_Pandang',
    'Asia/Ulaanbaatar',
    'Asia/Ulan_Bator',
    'Asia/Urumqi',
    'Asia/Ust-Nera',
    'Asia/Vientiane',
    'Asia/Vladivostok',
    'Asia/Yakutsk',
    'Asia/Yangon',
    'Asia/Yekaterinburg',
    'Asia/Yerevan',
    'Atlantic/Azores',
    'Atlantic/Bermuda',
    'Atlantic/Canary',
    'Atlantic/Cape_Verde',
    'Atlantic/Faeroe',
    'Atlantic/Faroe',
    'Atlantic/Jan_Mayen',
    'Atlantic/Madeira',
    'Atlantic/Reykjavik',
    'Atlantic/South_Georgia',
    'Atlantic/St_Helena',
    'Atlantic/Stanley',
    'Australia/ACT',
    'Australia/Adelaide',
    'Australia/Brisbane',
    'Australia/Broken_Hill',
    'Australia/Canberra',
    'Australia/Currie',
    'Australia/Darwin',
    'Australia/Eucla',
    'Australia/Hobart',
    'Australia/LHI',
    'Australia/Lindeman',
    'Australia/Lord_Howe',
    'Australia/Melbourne',
    'Australia/NSW',
    'Australia/North',
    'Australia/Perth',
    'Australia/Queensland',
    'Australia/South',
    'Australia/Sydney',
    'Australia/Tasmania',
    'Australia/Victoria',
    'Australia/West',
    'Australia/Yancowinna',
    'Brazil/Acre',
    'Brazil/DeNoronha',
    'Brazil/East',
    'Brazil/West',
    'CET',
    'CST6CDT',
    'Canada/Atlantic',
    'Canada/Central',
    'Canada/Eastern',
    'Canada/Mountain',
    'Canada/Newfoundland',
    'Canada/Pacific',
    'Canada/Saskatchewan',
    'Canada/Yukon',
    'Chile/Continental',
    'Chile/EasterIsland',
    'Cuba',
    'EET',
    'EST',
    'EST5EDT',
    'Egypt',
    'Eire',
    'Etc/GMT',
    'Etc/GMT+0',
    'Etc/GMT+1',
    'Etc/GMT+10',
    'Etc/GMT+11',
    'Etc/GMT+12',
    'Etc/GMT+2',
    'Etc/GMT+3',
    'Etc/GMT+4',
    'Etc/GMT+5',
    'Etc/GMT+6',
    'Etc/GMT+7',
    'Etc/GMT+8',
    'Etc/GMT+9',
    'Etc/GMT-0',
    'Etc/GMT-1',
    'Etc/GMT-10',
    'Etc/GMT-11',
    'Etc/GMT-12',
    'Etc/GMT-13',
    'Etc/GMT-14',
    'Etc/GMT-2',
    'Etc/GMT-3',
    'Etc/GMT-4',
    'Etc/GMT-5',
    'Etc/GMT-6',
    'Etc/GMT-7',
    'Etc/GMT-8',
    'Etc/GMT-9',
    'Etc/GMT0',
    'Etc/Greenwich',
    'Etc/UCT',
    'Etc/UTC',
    'Etc/Universal',
    'Etc/Zulu',
    'Europe/Amsterdam',
    'Europe/Andorra',
    'Europe/Astrakhan',
    'Europe/Athens',
    'Europe/Belfast',
    'Europe/Belgrade',
    'Europe/Berlin',
    'Europe/Bratislava',
    'Europe/Brussels',
    'Europe/Bucharest',
    'Europe/Budapest',
    'Europe/Busingen',
    'Europe/Chisinau',
    'Europe/Copenhagen',
    'Europe/Dublin',
    'Europe/Gibraltar',
    'Europe/Guernsey',
    'Europe/Helsinki',
    'Europe/Isle_of_Man',
    'Europe/Istanbul',
    'Europe/Jersey',
    'Europe/Kaliningrad',
    'Europe/Kiev',
    'Europe/Kirov',
    'Europe/Kyiv',
    'Europe/Lisbon',
    'Europe/Ljubljana',
    'Europe/London',
    'Europe/Luxembourg',
    'Europe/Madrid',
    'Europe/Malta',
    'Europe/Mariehamn',
    'Europe/Minsk',
    'Europe/Monaco',
    'Europe/Moscow',
    'Europe/Nicosia',
    'Europe/Oslo',
    'Europe/Paris',
    'Europe/Podgorica',
    'Europe/Prague',
    'Europe/Riga',
    'Europe/Rome',
    'Europe/Samara',
    'Europe/San_Marino',
    'Europe/Sarajevo',
    'Europe/Saratov',
    'Europe/Simferopol',
    'Europe/Skopje',
    'Europe/Sofia',
    'Europe/Stockholm',
    'Europe/Tallinn',
    'Europe/Tirane',
    'Europe/Tiraspol',
    'Europe/Ulyanovsk',
    'Europe/Uzhgorod',
    'Europe/Vaduz',
    'Europe/Vatican',
    'Europe/Vienna',
    'Europe/Vilnius',
    'Europe/Volgograd',
    'Europe/Warsaw',
    'Europe/Zagreb',
    'Europe/Zaporozhye',
    'Europe/Zurich',
    'GB',
    'GB-Eire',
    'GMT',
    'GMT+0',
    'GMT-0',
    'GMT0',
    'Greenwich',
    'HST',
    'Hongkong',
    'Iceland',
    'Indian/Antananarivo',
    'Indian/Chagos',
    'Indian/Christmas',
    'Indian/Cocos',
    'Indian/Comoro',
    'Indian/Kerguelen',
    'Indian/Mahe',
    'Indian/Maldives',
    'Indian/Mauritius',
    'Indian/Mayotte',
    'Indian/Reunion',
    'Iran',
    'Israel',
    'Jamaica',
    'Japan',
    'Kwajalein',
    'Libya',
    'MET',
    'MST',
    'MST7MDT',
    'Mexico/BajaNorte',
    'Mexico/BajaSur',
    'Mexico/General',
    'NZ',
    'NZ-CHAT',
    'Navajo',
    'PRC',
    'PST8PDT',
    'Pacific/Apia',
    'Pacific/Auckland',
    'Pacific/Bougainville',
    'Pacific/Chatham',
    'Pacific/Chuuk',
    'Pacific/Easter',
    'Pacific/Efate',
    'Pacific/Enderbury',
    'Pacific/Fakaofo',
    'Pacific/Fiji',
    'Pacific/Funafuti',
    'Pacific/Galapagos',
    'Pacific/Gambier',
    'Pacific/Guadalcanal',
    'Pacific/Guam',
    'Pacific/Honolulu',
    'Pacific/Johnston',
    'Pacific/Kanton',
    'Pacific/Kiritimati',
    'Pacific/Kosrae',
    'Pacific/Kwajalein',
    'Pacific/Majuro',
    'Pacific/Marquesas',
    'Pacific/Midway',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Norfolk',
    'Pacific/Noumea',
    'Pacific/Pago_Pago',
    'Pacific/Palau',
    'Pacific/Pitcairn',
    'Pacific/Pohnpei',
    'Pacific/Ponape',
    'Pacific/Port_Moresby',
    'Pacific/Rarotonga',
    'Pacific/Saipan',
    'Pacific/Samoa',
    'Pacific/Tahiti',
    'Pacific/Tarawa',
    'Pacific/Tongatapu',
    'Pacific/Truk',
    'Pacific/Wake',
    'Pacific/Wallis',
    'Pacific/Yap',
    'Poland',
    'Portugal',
    'ROC',
    'ROK',
    'Singapore',
    'Turkey',
    'UCT',
    'US/Alaska',
    'US/Aleutian',
    'US/Arizona',
    'US/Central',
    'US/East-Indiana',
    'US/Eastern',
    'US/Hawaii',
    'US/Indiana-Starke',
    'US/Michigan',
    'US/Mountain',
    'US/Pacific',
    'US/Samoa',
    'UTC',
    'Universal',
    'W-SU',
    'WET',
    'Zulu',

];

export const CONSTANTS = {
    CENSUS_ADMISSION: 'Admission',
    CENSUS_DISCHARGE_RE: 'Discharge - Return Expected',
    CENSUS_DISCHARGE_RNA: 'Discharge',
    CENSUS_EXPIRED: 'Expired',
    CENSUS_HOSPITAL_LEAVE: 'Hospital Leave',
    CENSUS_INFORMATION_CHANGE: 'Information Change',
    CENSUS_OUTPATIENT: 'Outpatient',
    CENSUS_OUTPATIENT_DISCHARGE: 'Outpatient Discharge',
    CENSUS_PENDING_ADMISSION: 'PreAdmission Hold',
    CENSUS_PENDING_DISCHARGE: 'Pending Discharge',
    CENSUS_RETURN: 'Return',
    CENSUS_THERAPEUTIC_LEAVE: 'Therapeutic Leave',
    CENSUS_READMISSION: 'Readmission',
    CENSUS_STATUS_DETAIL_20: 'Admit to Practice',
    CENSUS_STATUS_DETAIL_21: 'Discharge from Practice',
    CENSUS_STATUS_DETAIL_1: 'Transfer from a Hospital',
    CENSUS_STATUS_DETAIL_2: 'Transfer from a SNF',
    CENSUS_STATUS_DETAIL_3: 'Discontinued Emergency Room',
    CENSUS_STATUS_DETAIL_4: 'Discontinued TransFromAnotherHomeHlthAge',
    CENSUS_STATUS_DETAIL_5: 'Physician Referral',
    CENSUS_STATUS_DETAIL_6: 'Transfer from Other',
    CENSUS_STATUS_DETAIL_7: 'Discharged to home or self care',
    CENSUS_STATUS_DETAIL_8: 'Discharged/transferred to hospital',
    CENSUS_STATUS_DETAIL_9: 'Discharged/transferred to SNF',
    CENSUS_STATUS_DETAIL_10: 'Expired in a medical facility, such as a hospital, SNF, ICF or freestanding hospice (Hospice claims only)',
    CENSUS_STATUS_DETAIL_11: 'In House',
    CENSUS_STATUS_DETAIL_12: 'Discharged / Transferred to an ICF',
    CENSUS_STATUS_DETAIL_13: 'Discharged / Transferred to home under care of organized home health service organization',
    CENSUS_STATUS_DETAIL_14: 'D/C to another type of institution',
    CENSUS_STATUS_DETAIL_15: 'Discharge to home for hospice care (procedure) - 428361000124107',
    CENSUS_STATUS_DETAIL_16: 'Discharge to healthcare facility for hospice care (procedure) - 428371000124100 ',
    CENSUS_STATUS_DETAIL_17: 'Emergency hospital admission (procedure) -  183452005',
    CENSUS_STATUS_DETAIL_18: 'Hospital admission (procedure) - 32485007',
    CENSUS_STATUS_DETAIL_19: 'Hospital admission, elective (procedure) - 8715000',
    CHRONIC_DIAGNOSIS_ACQUIRED_HYPOTHYROIDISM: 'Acquired Hypothyroidism',
    CHRONIC_DIAGNOSIS_ACUTE_MYOCARDIAL_INFARCTION: 'Acute Myocardial Infarction',
    CHRONIC_DIAGNOSIS_ADHD__CONDUCT_DISORDERS_AND_HYPERKINETIC_SYNDROME: 'Adhd, Conduct Disorders And Hyperkinetic Syndrome',
    CHRONIC_DIAGNOSIS_ALCOHOL_USE_DISORDERS: 'Alcohol Use Disorders',
    CHRONIC_DIAGNOSIS_ALZHEIMER_DISEASE: 'Alzheimer Disease',
    CHRONIC_DIAGNOSIS_ANEMIA: 'Anemia',
    CHRONIC_DIAGNOSIS_ANXIETY_DISORDERS: 'Anxiety Disorders',
    CHRONIC_DIAGNOSIS_ASTHMA: 'Asthma',
    CHRONIC_DIAGNOSIS_ATRIAL_FIBRILLATION: 'Atrial Fibrillation',
    CHRONIC_DIAGNOSIS_AUTISM_SPECTRUM_DISORDERS: 'Autism Spectrum Disorders',
    CHRONIC_DIAGNOSIS_BENIGN_PROSTATIC_HYPERPLASIA: 'Benign Prostatic Hyperplasia',
    CHRONIC_DIAGNOSIS_BIPOLAR_DISORDER: 'Bipolar Disorder',
    CHRONIC_DIAGNOSIS_BLINDNESS_AND_VISUAL_IMPAIRMENT: 'Blindness And Visual Impairment',
    CHRONIC_DIAGNOSIS_BREAST_CANCER: 'Breast Cancer',
    CHRONIC_DIAGNOSIS_CATARACT: 'Cataract',
    CHRONIC_DIAGNOSIS_CEREBRAL_PALSY: 'Cerebral Palsy',
    CHRONIC_DIAGNOSIS_CHRONIC_KIDNEY_DISEASE: 'Chronic Kidney Disease',
    CHRONIC_DIAGNOSIS_CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE: 'Chronic Obstructive Pulmonary Disease',
    CHRONIC_DIAGNOSIS_COLORECTAL_CANCER: 'Colorectal Cancer',
    CHRONIC_DIAGNOSIS_CYSTIC_FIBROSIS_AND_OTHER_METABOLIC_DEVELOPMENTAL_DISORDERS: 'Cystic Fibrosis And Other Metabolic Developmental Disorders',
    CHRONIC_DIAGNOSIS_DEAFNESS_AND_HEARING_IMPAIRMENT: 'Deafness And Hearing Impairment',
    CHRONIC_DIAGNOSIS_DEMENTIA: 'Dementia',
    CHRONIC_DIAGNOSIS_DEPRESSION: 'Depression',
    CHRONIC_DIAGNOSIS_DEPRESSIVE_DISORDERS: 'Depressive Disorders',
    CHRONIC_DIAGNOSIS_DIABETES: 'Diabetes',
    CHRONIC_DIAGNOSIS_DRUG_USE_DISORDERS: 'Drug Use Disorders',
    CHRONIC_DIAGNOSIS_ENDOMETRIAL_CANCER: 'Endometrial Cancer',
    CHRONIC_DIAGNOSIS_EPILEPSY: 'Epilepsy',
    CHRONIC_DIAGNOSIS_FIBROMYALGIA_AND_CHRONIC_PAIN_AND_FATIGUE: 'Fibromyalgia And Chronic Pain And Fatigue',
    CHRONIC_DIAGNOSIS_GLAUCOMA: 'Glaucoma',
    CHRONIC_DIAGNOSIS_HEART_FAILURE_OR_HEART_DISEASE: 'Heart Failure Or Heart Disease',
    CHRONIC_DIAGNOSIS_HIP_OR_PELVIC_FRACTURE: 'Hip Or Pelvic Fracture',
    CHRONIC_DIAGNOSIS_HUMAN_IMMUNODEFICIENCY_VIRUS_OR_ACQUIRED_IMMUNODEFICIENCY_SYNDROME: 'Human Immunodeficiency Virus Or Acquired Immunodeficiency Syndrome',
    CHRONIC_DIAGNOSIS_HYPERLIPIDEMIA: 'Hyperlipidemia',
    CHRONIC_DIAGNOSIS_HYPERTENSION: 'Hypertension',
    CHRONIC_DIAGNOSIS_INTELLECTUAL_DISABILITIES_AND_RELATED_CONDITIONS: 'Intellectual Disabilities And Related Conditions',
    CHRONIC_DIAGNOSIS_ISCHEMIC_HEART_DISEASE: 'Ischemic Heart Disease',
    CHRONIC_DIAGNOSIS_LEARNING_DISABILITIES: 'Learning Disabilities',
    CHRONIC_DIAGNOSIS_LEUKEMIAS_AND_LYMPHOMAS: 'Leukemias And Lymphomas',
    CHRONIC_DIAGNOSIS_LIVER_DISEASE__CIRRHOSIS_AND_OTHER_LIVER_CONDITIONS: 'Liver Disease, Cirrhosis And Other Liver Conditions',
    CHRONIC_DIAGNOSIS_LUNG_CANCER: 'Lung Cancer',
    CHRONIC_DIAGNOSIS_MIGRAINE_AND_CHRONIC_HEADACHE: 'Migraine And Chronic Headache',
    CHRONIC_DIAGNOSIS_MOBILITY_IMPAIRMENTS: 'Mobility Impairments',
    CHRONIC_DIAGNOSIS_MULTIPLE_SCLEROSIS_AND_TRANSVERSE_MYELITIS: 'Multiple Sclerosis And Transverse Myelitis',
    CHRONIC_DIAGNOSIS_MUSCULAR_DYSTROPHY: 'Muscular Dystrophy',
    CHRONIC_DIAGNOSIS_NON_ALZHEIMERS_DEMENTIA: 'Non Alzheimers Dementia',
    CHRONIC_DIAGNOSIS_OBESITY: 'Obesity',
    CHRONIC_DIAGNOSIS_OPIOID_USE_DISORDER: 'Opioid Use Disorder',
    CHRONIC_DIAGNOSIS_OSTEOPOROSIS: 'Osteoporosis',
    CHRONIC_DIAGNOSIS_OTHER_DEVELOPMENTAL_DELAYS: 'Other Developmental Delays',
    CHRONIC_DIAGNOSIS_PARKINSONS_DISEASE_AND_SECONDARY_PARKINSONISM: 'Parkinsons Disease And Secondary Parkinsonism',
    CHRONIC_DIAGNOSIS_PERIPHERAL_VASCULAR_DISEASE: 'Peripheral Vascular Disease',
    CHRONIC_DIAGNOSIS_PERSONALITY_DISORDERS: 'Personality Disorders',
    CHRONIC_DIAGNOSIS_PNEUMONIA: 'Pneumonia',
    CHRONIC_DIAGNOSIS_POST_TRAUMATIC_STRESS_DISORDER: 'Post Traumatic Stress Disorder',
    CHRONIC_DIAGNOSIS_PRESSURE_AND_CHRONIC_ULCERS: 'Pressure And Chronic Ulcers',
    CHRONIC_DIAGNOSIS_PROSTATE_CANCER: 'Prostate Cancer',
    CHRONIC_DIAGNOSIS_RHEUMATOID_ARTHRITIS_OR_OSTEOARTHRITIS: 'Rheumatoid Arthritis Or Osteoarthritis',
    CHRONIC_DIAGNOSIS_SCHIZOPHRENIA: 'Schizophrenia',
    CHRONIC_DIAGNOSIS_SCHIZOPHRENIA_AND_OTHER_PSYCHOTIC_DISORDERS: 'Schizophrenia And Other Psychotic Disorders',
    CHRONIC_DIAGNOSIS_SICKLE_CELL_DISEASE: 'Sickle Cell Disease',
    CHRONIC_DIAGNOSIS_SPINAL_CORD_INJURY: 'Spinal Cord Injury',
    CHRONIC_DIAGNOSIS_SPINA_BIFIDA_AND_OTHER_CONGENITAL_ANOMALIES_OF_THE_NERVOUS_SYSTEM: 'Spina Bifida And Other Congenital Anomalies Of The Nervous System',
    CHRONIC_DIAGNOSIS_STROKE_OR_TRANSIENT_ISCHEMIC_ATTACK: 'Stroke Or Transient Ischemic Attack',
    CHRONIC_DIAGNOSIS_TOBACCO_USE: 'Tobacco Use',
    CHRONIC_DIAGNOSIS_TRAUMATIC_BRAIN_INJURY_AND_NONPSYCHOTIC_MENTAL_DISORDERS_DUE_TO_BRAIN_DAMAGE: 'Traumatic Brain Injury And Nonpsychotic Mental Disorders Due To Brain Damage',
    CHRONIC_DIAGNOSIS_UROLOGIC_CANCER: 'Urologic Cancer',
    CHRONIC_DIAGNOSIS_VIRAL_HEPATITIS: 'Viral Hepatitis',
    ACHIEVEMENT_STATUS_NONE: '',
    ACHIEVEMENT_STATUS_PROPOSED: 'Proposed',
    ACHIEVEMENT_STATUS_IMPROVING: 'Improving',
    ACHIEVEMENT_STATUS_WORSENING: 'Worsening',
    ACHIEVEMENT_STATUS_NO_CHANGE: 'No Change',
    ACHIEVEMENT_STATUS_ACHIEVED: 'Achieved',
    ACHIEVEMENT_STATUS_SUSTAINING: 'Sustaining',
    ACHIEVEMENT_STATUS_NOT_ACHIEVED: 'Not Achieved',
    ACHIEVEMENT_STATUS_NO_PROGRESS: 'No Progress',
    ACHIEVEMENT_STATUS_NOT_ATTAINABLE: 'Not Attainable',
    GOAL_CATEGORY_NONE: '',
    GOAL_CATEGORY_DIETARY: 'Dietary',
    GOAL_CATEGORY_SAFETY: 'Safety',
    GOAL_CATEGORY_BEHAVIORAL: 'Behavioral',
    GOAL_CATEGORY_NURSING: 'Nursing',
    GOAL_CATEGORY_PHYSIOTHERAPY: 'Physiotherapy',
    GOAL_PRIORITY_NONE: '',
    GOAL_PRIORITY_HIGH_PRIORITY: 'High Priority',
    GOAL_PRIORITY_MEDIUM_PRIORITY: 'Medium Priority',
    GOAL_PRIORITY_LOW_PRIORITY: 'Low Priority',
    GOAL_TARGET_MEASURE_TEXT: 'Text',
    GOAL_TARGET_MEASURE_DECIMAL: 'Decimal',
    GOAL_TARGET_MEASURE_RANGE: 'Range',
    GOAL_TARGET_MEASURE_SNOMED: 'SNOMED CT Concept',
    LIFECYCLE_STATUS_NONE: '',
    LIFECYCLE_STATUS_PROPOSED: 'Proposed',
    LIFECYCLE_STATUS_PLANNED: 'Planned',
    LIFECYCLE_STATUS_ACCEPTED: 'Accepted',
    LIFECYCLE_STATUS_ACTIVVE: 'Active',
    LIFECYCLE_STATUS_ON_HOLD: 'On Hold',
    LIFECYCLE_STATUS_COMPLETED: 'Completed',
    LIFECYCLE_STATUS_CANCELLED: 'Cancelled',
    LIFECYCLE_STATUS_ENTERED_IN_ERROR: 'Entered in Error',
    LIFECYCLE_STATUS_REJECTED: 'Rejected',
    ROOM_TYPE_PATIENT_ROOM: 'Patient room',
    INFECTION_REPORT_TYPE_SNF: 'Skilled Nursing Facility',
    INFECTION_REPORT_TYPE_ACF: 'Acute Care Facility',
    INFECTION_REPORT_TYPE_FPM: 'Floorplan Map',
    CONTACT_POINT_USE_CHOICES_HOME: 'home',
    CONTACT_POINT_USE_CHOICES_WORK: 'work',
    CONTACT_POINT_USE_CHOICES_TEMP: 'temp',
    CONTACT_POINT_USE_CHOICES_OLD: 'old',
    CONTACT_POINT_USE_CHOICES_MOBILE: 'mobile',
    CONTACT_POINT_USE_CHOICES_OTHER: 'other',
    RELATIONSHIP_ROLES: {'GUARDIAN': 'Guardian', 'GUARANTOR': 'Guarantor', 'CAREGIVER': 'Caregiver', 'DPOA_FIN': 'DPOA Financial', 'DPOA_MED': 'DPOA Medical', 'DPOA': 'DPOA', 'EMER_CONTACT': 'Emergency Contact', 'RESP_PARTY': 'Responsible Party', 'NOT_AUTH': 'Not Authorized', 'INSURED': 'Insured', 'OTHER': 'Other'},
    ADDRESS_USE_CHOICES_HOME: 'home',
    ADDRESS_USE_CHOICES_WORK: 'work',
    ADDRESS_USE_CHOICES_TEMP: 'temp',
    ADDRESS_USE_CHOICES_OLD: 'old',
    ADDRESS_USE_CHOICES_BILLING: 'billing',
    ADDRESS_USE_CHOICES_OTHER: 'other',
    NUCC_101200000X: 'Behavioral Health & Social Service Providers; Drama Therapist',
    NUCC_101Y00000X: 'Behavioral Health & Social Service Providers; Counselor',
    NUCC_101YA0400X: 'Behavioral Health & Social Service Providers; Counselor, Addiction (Substance Use Disorder)',
    NUCC_101YM0800X: 'Behavioral Health & Social Service Providers; Counselor, Mental Health',
    NUCC_101YP1600X: 'Behavioral Health & Social Service Providers; Counselor, Pastoral',
    NUCC_101YP2500X: 'Behavioral Health & Social Service Providers; Counselor, Professional',
    NUCC_101YS0200X: 'Behavioral Health & Social Service Providers; Counselor, School',
    NUCC_102L00000X: 'Behavioral Health & Social Service Providers; Psychoanalyst',
    NUCC_102X00000X: 'Behavioral Health & Social Service Providers; Poetry Therapist',
    NUCC_103G00000X: 'Behavioral Health & Social Service Providers; Clinical Neuropsychologist',
    NUCC_103K00000X: 'Behavioral Health & Social Service Providers; Behavior Analyst',
    NUCC_103T00000X: 'Behavioral Health & Social Service Providers; Psychologist',
    NUCC_103TA0400X: 'Behavioral Health & Social Service Providers; Psychologist, Addiction (Substance Use Disorder)',
    NUCC_103TA0700X: 'Behavioral Health & Social Service Providers; Psychologist, Adult Development & Aging',
    NUCC_103TB0200X: 'Behavioral Health & Social Service Providers; Psychologist, Cognitive & Behavioral',
    NUCC_103TC0700X: 'Behavioral Health & Social Service Providers; Psychologist, Clinical',
    NUCC_103TC1900X: 'Behavioral Health & Social Service Providers; Psychologist, Counseling',
    NUCC_103TC2200X: 'Behavioral Health & Social Service Providers; Psychologist, Clinical Child & Adolescent',
    NUCC_103TE1100X: 'Behavioral Health & Social Service Providers; Psychologist, Exercise & Sports',
    NUCC_103TF0000X: 'Behavioral Health & Social Service Providers; Psychologist, Family',
    NUCC_103TF0200X: 'Behavioral Health & Social Service Providers; Psychologist, Forensic',
    NUCC_103TH0004X: 'Behavioral Health & Social Service Providers; Psychologist, Health',
    NUCC_103TH0100X: 'Behavioral Health & Social Service Providers; Psychologist, Health Service',
    NUCC_103TM1800X: 'Behavioral Health & Social Service Providers; Psychologist, Intellectual & Developmental Disabilities',
    NUCC_103TP0016X: 'Behavioral Health & Social Service Providers; Psychologist, Prescribing (Medical)',
    NUCC_103TP0814X: 'Behavioral Health & Social Service Providers; Psychologist, Psychoanalysis',
    NUCC_103TP2701X: 'Behavioral Health & Social Service Providers; Psychologist, Group Psychotherapy',
    NUCC_103TR0400X: 'Behavioral Health & Social Service Providers; Psychologist, Rehabilitation',
    NUCC_103TS0200X: 'Behavioral Health & Social Service Providers; Psychologist, School',
    NUCC_104100000X: 'Behavioral Health & Social Service Providers; Social Worker',
    NUCC_1041C0700X: 'Behavioral Health & Social Service Providers; Social Worker, Clinical',
    NUCC_1041S0200X: 'Behavioral Health & Social Service Providers; Social Worker, School',
    NUCC_106E00000X: 'Behavioral Health & Social Service Providers; Assistant Behavior Analyst',
    NUCC_106H00000X: 'Behavioral Health & Social Service Providers; Marriage & Family Therapist',
    NUCC_106S00000X: 'Behavioral Health & Social Service Providers; Behavior Technician',
    NUCC_111N00000X: 'Chiropractic Providers; Chiropractor',
    NUCC_111NI0013X: 'Chiropractic Providers; Chiropractor, Independent Medical Examiner',
    NUCC_111NI0900X: 'Chiropractic Providers; Chiropractor, Internist',
    NUCC_111NN0400X: 'Chiropractic Providers; Chiropractor, Neurology',
    NUCC_111NN1001X: 'Chiropractic Providers; Chiropractor, Nutrition',
    NUCC_111NP0017X: 'Chiropractic Providers; Chiropractor, Pediatric Chiropractor',
    NUCC_111NR0200X: 'Chiropractic Providers; Chiropractor, Radiology',
    NUCC_111NR0400X: 'Chiropractic Providers; Chiropractor, Rehabilitation',
    NUCC_111NS0005X: 'Chiropractic Providers; Chiropractor, Sports Physician',
    NUCC_111NT0100X: 'Chiropractic Providers; Chiropractor, Thermography',
    NUCC_111NX0100X: 'Chiropractic Providers; Chiropractor, Occupational Health',
    NUCC_111NX0800X: 'Chiropractic Providers; Chiropractor, Orthopedic',
    NUCC_122300000X: 'Dental Providers; Dentist',
    NUCC_1223D0001X: 'Dental Providers; Dentist, Dental Public Health',
    NUCC_1223D0004X: 'Dental Providers; Dentist, Dentist Anesthesiologist',
    NUCC_1223E0200X: 'Dental Providers; Dentist, Endodontics',
    NUCC_1223G0001X: 'Dental Providers; Dentist, General Practice',
    NUCC_1223P0106X: 'Dental Providers; Dentist, Oral and Maxillofacial Pathology',
    NUCC_1223P0221X: 'Dental Providers; Dentist, Pediatric Dentistry',
    NUCC_1223P0300X: 'Dental Providers; Dentist, Periodontics',
    NUCC_1223P0700X: 'Dental Providers; Dentist, Prosthodontics',
    NUCC_1223S0112X: 'Dental Providers; Dentist, Oral and Maxillofacial Surgery',
    NUCC_1223X0008X: 'Dental Providers; Dentist, Oral and Maxillofacial Radiology',
    NUCC_1223X0400X: 'Dental Providers; Dentist, Orthodontics and Dentofacial Orthopedics',
    NUCC_1223X2210X: 'Dental Providers; Dentist, Orofacial Pain',
    NUCC_122400000X: 'Dental Providers; Denturist',
    NUCC_124Q00000X: 'Dental Providers; Dental Hygienist',
    NUCC_125J00000X: 'Dental Providers; Dental Therapist',
    NUCC_125K00000X: 'Dental Providers; Advanced Practice Dental Therapist',
    NUCC_125Q00000X: 'Dental Providers; Oral Medicinist',
    NUCC_126800000X: 'Dental Providers; Dental Assistant',
    NUCC_126900000X: 'Dental Providers; Dental Laboratory Technician',
    NUCC_132700000X: 'Dietary & Nutritional Service Providers; Dietary Manager',
    NUCC_133N00000X: 'Dietary & Nutritional Service Providers; Nutritionist',
    NUCC_133NN1002X: 'Dietary & Nutritional Service Providers; Nutritionist, Nutrition, Education',
    NUCC_133V00000X: 'Dietary & Nutritional Service Providers; Dietitian, Registered',
    NUCC_133VN1004X: 'Dietary & Nutritional Service Providers; Dietitian, Registered, Nutrition, Pediatric',
    NUCC_133VN1005X: 'Dietary & Nutritional Service Providers; Dietitian, Registered, Nutrition, Renal',
    NUCC_133VN1006X: 'Dietary & Nutritional Service Providers; Dietitian, Registered, Nutrition, Metabolic',
    NUCC_133VN1101X: 'Dietary & Nutritional Service Providers; Dietitian, Registered, Nutrition, Gerontological',
    NUCC_133VN1201X: 'Dietary & Nutritional Service Providers; Dietitian, Registered, Nutrition, Obesity and Weight Management',
    NUCC_133VN1301X: 'Dietary & Nutritional Service Providers; Dietitian, Registered, Nutrition, Oncology',
    NUCC_133VN1401X: 'Dietary & Nutritional Service Providers; Dietitian, Registered, Nutrition, Pediatric Critical Care',
    NUCC_133VN1501X: 'Dietary & Nutritional Service Providers; Dietitian, Registered, Nutrition, Sports Dietetics',
    NUCC_136A00000X: 'Dietary & Nutritional Service Providers; Dietetic Technician, Registered',
    NUCC_146D00000X: 'Emergency Medical Service Providers; Personal Emergency Response Attendant',
    NUCC_146L00000X: 'Emergency Medical Service Providers; Emergency Medical Technician, Paramedic',
    NUCC_146M00000X: 'Emergency Medical Service Providers; Emergency Medical Technician, Intermediate',
    NUCC_146N00000X: 'Emergency Medical Service Providers; Emergency Medical Technician, Basic',
    NUCC_152W00000X: 'Eye and Vision Services Providers; Optometrist',
    NUCC_152WC0802X: 'Eye and Vision Services Providers; Optometrist, Corneal and Contact Management',
    NUCC_152WL0500X: 'Eye and Vision Services Providers; Optometrist, Low Vision Rehabilitation',
    NUCC_152WP0200X: 'Eye and Vision Services Providers; Optometrist, Pediatrics',
    NUCC_152WS0006X: 'Eye and Vision Services Providers; Optometrist, Sports Vision',
    NUCC_152WV0400X: 'Eye and Vision Services Providers; Optometrist, Vision Therapy',
    NUCC_152WX0102X: 'Eye and Vision Services Providers; Optometrist, Occupational Vision',
    NUCC_156F00000X: 'Eye and Vision Services Providers; Technician/Technologist',
    NUCC_156FC0800X: 'Eye and Vision Services Providers; Technician/Technologist, Contact Lens',
    NUCC_156FC0801X: 'Eye and Vision Services Providers; Technician/Technologist, Contact Lens Fitter',
    NUCC_156FX1100X: 'Eye and Vision Services Providers; Technician/Technologist, Ophthalmic',
    NUCC_156FX1101X: 'Eye and Vision Services Providers; Technician/Technologist, Ophthalmic Assistant',
    NUCC_156FX1201X: 'Eye and Vision Services Providers; Technician/Technologist, Optometric Assistant',
    NUCC_156FX1202X: 'Eye and Vision Services Providers; Technician/Technologist, Optometric Technician',
    NUCC_156FX1700X: 'Eye and Vision Services Providers; Technician/Technologist, Ocularist',
    NUCC_156FX1800X: 'Eye and Vision Services Providers; Technician/Technologist, Optician',
    NUCC_156FX1900X: 'Eye and Vision Services Providers; Technician/Technologist, Orthoptist',
    NUCC_163W00000X: 'Nursing Service Providers; Registered Nurse',
    NUCC_163WA0400X: 'Nursing Service Providers; Registered Nurse, Addiction (Substance Use Disorder)',
    NUCC_163WA2000X: 'Nursing Service Providers; Registered Nurse, Administrator',
    NUCC_163WC0200X: 'Nursing Service Providers; Registered Nurse, Critical Care Medicine',
    NUCC_163WC0400X: 'Nursing Service Providers; Registered Nurse, Case Management',
    NUCC_163WC1400X: 'Nursing Service Providers; Registered Nurse, College Health',
    NUCC_163WC1500X: 'Nursing Service Providers; Registered Nurse, Community Health',
    NUCC_163WC1600X: 'Nursing Service Providers; Registered Nurse, Continuing Education/Staff Development',
    NUCC_163WC2100X: 'Nursing Service Providers; Registered Nurse, Continence Care',
    NUCC_163WC3500X: 'Nursing Service Providers; Registered Nurse, Cardiac Rehabilitation',
    NUCC_163WD0400X: 'Nursing Service Providers; Registered Nurse, Diabetes Educator',
    NUCC_163WD1100X: 'Nursing Service Providers; Registered Nurse, Dialysis, Peritoneal',
    NUCC_163WE0003X: 'Nursing Service Providers; Registered Nurse, Emergency',
    NUCC_163WE0900X: 'Nursing Service Providers; Registered Nurse, Enterostomal Therapy',
    NUCC_163WF0300X: 'Nursing Service Providers; Registered Nurse, Flight',
    NUCC_163WG0000X: 'Nursing Service Providers; Registered Nurse, General Practice',
    NUCC_163WG0100X: 'Nursing Service Providers; Registered Nurse, Gastroenterology',
    NUCC_163WG0600X: 'Nursing Service Providers; Registered Nurse, Gerontology',
    NUCC_163WH0200X: 'Nursing Service Providers; Registered Nurse, Home Health',
    NUCC_163WH0500X: 'Nursing Service Providers; Registered Nurse, Hemodialysis',
    NUCC_163WH1000X: 'Nursing Service Providers; Registered Nurse, Hospice',
    NUCC_163WI0500X: 'Nursing Service Providers; Registered Nurse, Infusion Therapy',
    NUCC_163WI0600X: 'Nursing Service Providers; Registered Nurse, Infection Control',
    NUCC_163WL0100X: 'Nursing Service Providers; Registered Nurse, Lactation Consultant',
    NUCC_163WM0102X: 'Nursing Service Providers; Registered Nurse, Maternal Newborn',
    NUCC_163WM0705X: 'Nursing Service Providers; Registered Nurse, Medical-Surgical',
    NUCC_163WM1400X: 'Nursing Service Providers; Registered Nurse, Nurse Massage Therapist (NMT)',
    NUCC_163WN0002X: 'Nursing Service Providers; Registered Nurse, Neonatal Intensive Care',
    NUCC_163WN0003X: 'Nursing Service Providers; Registered Nurse, Neonatal, Low-Risk',
    NUCC_163WN0300X: 'Nursing Service Providers; Registered Nurse, Nephrology',
    NUCC_163WN0800X: 'Nursing Service Providers; Registered Nurse, Neuroscience',
    NUCC_163WN1003X: 'Nursing Service Providers; Registered Nurse, Nutrition Support',
    NUCC_163WP0000X: 'Nursing Service Providers; Registered Nurse, Pain Management',
    NUCC_163WP0200X: 'Nursing Service Providers; Registered Nurse, Pediatrics',
    NUCC_163WP0218X: 'Nursing Service Providers; Registered Nurse, Pediatric Oncology',
    NUCC_163WP0807X: 'Nursing Service Providers; Registered Nurse, Psychiatric/Mental Health, Child & Adolescent',
    NUCC_163WP0808X: 'Nursing Service Providers; Registered Nurse, Psychiatric/Mental Health',
    NUCC_163WP0809X: 'Nursing Service Providers; Registered Nurse, Psychiatric/Mental Health, Adult',
    NUCC_163WP1700X: 'Nursing Service Providers; Registered Nurse, Perinatal',
    NUCC_163WP2201X: 'Nursing Service Providers; Registered Nurse, Ambulatory Care',
    NUCC_163WR0006X: 'Nursing Service Providers; Registered Nurse, Registered Nurse First Assistant',
    NUCC_163WR0400X: 'Nursing Service Providers; Registered Nurse, Rehabilitation',
    NUCC_163WR1000X: 'Nursing Service Providers; Registered Nurse, Reproductive Endocrinology/Infertility',
    NUCC_163WS0121X: 'Nursing Service Providers; Registered Nurse, Plastic Surgery',
    NUCC_163WS0200X: 'Nursing Service Providers; Registered Nurse, School',
    NUCC_163WU0100X: 'Nursing Service Providers; Registered Nurse, Urology',
    NUCC_163WW0000X: 'Nursing Service Providers; Registered Nurse, Wound Care',
    NUCC_163WW0101X: 'Nursing Service Providers; Registered Nurse, Womens Health Care, Ambulatory',
    NUCC_163WX0002X: 'Nursing Service Providers; Registered Nurse, Obstetric, High-Risk',
    NUCC_163WX0003X: 'Nursing Service Providers; Registered Nurse, Obstetric, Inpatient',
    NUCC_163WX0106X: 'Nursing Service Providers; Registered Nurse, Occupational Health',
    NUCC_163WX0200X: 'Nursing Service Providers; Registered Nurse, Oncology',
    NUCC_163WX0601X: 'Nursing Service Providers; Registered Nurse, Otorhinolaryngology & Head-Neck',
    NUCC_163WX0800X: 'Nursing Service Providers; Registered Nurse, Orthopedic',
    NUCC_163WX1100X: 'Nursing Service Providers; Registered Nurse, Ophthalmic',
    NUCC_163WX1500X: 'Nursing Service Providers; Registered Nurse, Ostomy Care',
    NUCC_164W00000X: 'Nursing Service Providers; Licensed Practical Nurse',
    NUCC_164X00000X: 'Nursing Service Providers; Licensed Vocational Nurse',
    NUCC_167G00000X: 'Nursing Service Providers; Licensed Psychiatric Technician',
    NUCC_170100000X: 'Other Service Providers; Medical Genetics, Ph.D. Medical Genetics',
    NUCC_170300000X: 'Other Service Providers; Genetic Counselor, MS',
    NUCC_171000000X: 'Other Service Providers; Military Health Care Provider',
    NUCC_1710I1002X: 'Other Service Providers; Military Health Care Provider, Independent Duty Corpsman',
    NUCC_1710I1003X: 'Other Service Providers; Military Health Care Provider, Independent Duty Medical Technicians',
    NUCC_171100000X: 'Other Service Providers; Acupuncturist',
    NUCC_171400000X: 'Other Service Providers; Health & Wellness Coach',
    NUCC_171M00000X: 'Other Service Providers; Case Manager/Care Coordinator',
    NUCC_171R00000X: 'Other Service Providers; Interpreter',
    NUCC_171W00000X: 'Other Service Providers; Contractor',
    NUCC_171WH0202X: 'Other Service Providers; Contractor, Home Modifications',
    NUCC_171WV0202X: 'Other Service Providers; Contractor, Vehicle Modifications',
    NUCC_172A00000X: 'Other Service Providers; Driver',
    NUCC_172M00000X: 'Other Service Providers; Mechanotherapist',
    NUCC_172P00000X: 'Other Service Providers; Naprapath',
    NUCC_172V00000X: 'Other Service Providers; Community Health Worker',
    NUCC_173000000X: 'Other Service Providers; Legal Medicine',
    NUCC_173C00000X: 'Other Service Providers; Reflexologist',
    NUCC_173F00000X: 'Other Service Providers; Sleep Specialist, PhD',
    NUCC_174200000X: 'Other Service Providers; Meals',
    NUCC_174400000X: 'Other Service Providers; Specialist',
    NUCC_1744G0900X: 'Other Service Providers; Specialist, Graphics Designer',
    NUCC_1744P3200X: 'Other Service Providers; Specialist, Prosthetics Case Management',
    NUCC_1744R1102X: 'Other Service Providers; Specialist, Research Study',
    NUCC_1744R1103X: 'Other Service Providers; Specialist, Research Data Abstracter/Coder',
    NUCC_174H00000X: 'Other Service Providers; Health Educator',
    NUCC_174M00000X: 'Other Service Providers; Veterinarian',
    NUCC_174MM1900X: 'Other Service Providers; Veterinarian, Medical Research',
    NUCC_174N00000X: 'Other Service Providers; Lactation Consultant, Non-RN',
    NUCC_174V00000X: 'Other Service Providers; Clinical Ethicist',
    NUCC_175F00000X: 'Other Service Providers; Naturopath',
    NUCC_175L00000X: 'Other Service Providers; Homeopath',
    NUCC_175M00000X: 'Other Service Providers; Midwife, Lay',
    NUCC_175T00000X: 'Other Service Providers; Peer Specialist',
    NUCC_176B00000X: 'Other Service Providers; Midwife',
    NUCC_176P00000X: 'Other Service Providers; Funeral Director',
    NUCC_177F00000X: 'Other Service Providers; Lodging',
    NUCC_183500000X: 'Pharmacy Service Providers; Pharmacist',
    NUCC_1835C0205X: 'Pharmacy Service Providers; Pharmacist, Critical Care',
    NUCC_1835G0303X: 'Pharmacy Service Providers; Pharmacist, Geriatric',
    NUCC_1835N0905X: 'Pharmacy Service Providers; Pharmacist, Nuclear',
    NUCC_1835N1003X: 'Pharmacy Service Providers; Pharmacist, Nutrition Support',
    NUCC_1835P0018X: 'Pharmacy Service Providers; Pharmacist, Pharmacist Clinician (PhC)/ Clinical Pharmacy Specialist',
    NUCC_1835P0200X: 'Pharmacy Service Providers; Pharmacist, Pediatrics',
    NUCC_1835P1200X: 'Pharmacy Service Providers; Pharmacist, Pharmacotherapy',
    NUCC_1835P1300X: 'Pharmacy Service Providers; Pharmacist, Psychiatric',
    NUCC_1835P2201X: 'Pharmacy Service Providers; Pharmacist, Ambulatory Care',
    NUCC_1835X0200X: 'Pharmacy Service Providers; Pharmacist, Oncology',
    NUCC_183700000X: 'Pharmacy Service Providers; Pharmacy Technician',
    NUCC_193200000X: 'Group; Multi-Specialty',
    NUCC_193400000X: 'Group; Single Specialty',
    NUCC_202C00000X: 'Allopathic & Osteopathic Physicians; Independent Medical Examiner',
    NUCC_202D00000X: 'Allopathic & Osteopathic Physicians; Integrative Medicine',
    NUCC_202K00000X: 'Allopathic & Osteopathic Physicians; Phlebology',
    NUCC_204C00000X: 'Allopathic & Osteopathic Physicians; Neuromusculoskeletal Medicine, Sports Medicine',
    NUCC_204D00000X: 'Allopathic & Osteopathic Physicians; Neuromusculoskeletal Medicine & OMM',
    NUCC_204E00000X: 'Allopathic & Osteopathic Physicians; Oral & Maxillofacial Surgery',
    NUCC_204F00000X: 'Allopathic & Osteopathic Physicians; Transplant Surgery',
    NUCC_204R00000X: 'Allopathic & Osteopathic Physicians; Electrodiagnostic Medicine',
    NUCC_207K00000X: 'Allopathic & Osteopathic Physicians; Allergy & Immunology',
    NUCC_207KA0200X: 'Allopathic & Osteopathic Physicians; Allergy & Immunology, Allergy',
    NUCC_207KI0005X: 'Allopathic & Osteopathic Physicians; Allergy & Immunology, Clinical & Laboratory Immunology',
    NUCC_207L00000X: 'Allopathic & Osteopathic Physicians; Anesthesiology',
    NUCC_207LA0401X: 'Allopathic & Osteopathic Physicians; Anesthesiology, Addiction Medicine',
    NUCC_207LC0200X: 'Allopathic & Osteopathic Physicians; Anesthesiology, Critical Care Medicine',
    NUCC_207LH0002X: 'Allopathic & Osteopathic Physicians; Anesthesiology, Hospice and Palliative Medicine',
    NUCC_207LP2900X: 'Allopathic & Osteopathic Physicians; Anesthesiology, Pain Medicine',
    NUCC_207LP3000X: 'Allopathic & Osteopathic Physicians; Anesthesiology, Pediatric Anesthesiology',
    NUCC_207N00000X: 'Allopathic & Osteopathic Physicians; Dermatology',
    NUCC_207ND0101X: 'Allopathic & Osteopathic Physicians; Dermatology, MOHS-Micrographic Surgery',
    NUCC_207ND0900X: 'Allopathic & Osteopathic Physicians; Dermatology, Dermatopathology',
    NUCC_207NI0002X: 'Allopathic & Osteopathic Physicians; Dermatology, Clinical & Laboratory Dermatological Immunology',
    NUCC_207NP0225X: 'Allopathic & Osteopathic Physicians; Dermatology, Pediatric Dermatology',
    NUCC_207NS0135X: 'Allopathic & Osteopathic Physicians; Dermatology, Procedural Dermatology',
    NUCC_207P00000X: 'Allopathic & Osteopathic Physicians; Emergency Medicine',
    NUCC_207PE0004X: 'Allopathic & Osteopathic Physicians; Emergency Medicine, Emergency Medical Services',
    NUCC_207PE0005X: 'Allopathic & Osteopathic Physicians; Emergency Medicine, Undersea and Hyperbaric Medicine',
    NUCC_207PH0002X: 'Allopathic & Osteopathic Physicians; Emergency Medicine, Hospice and Palliative Medicine',
    NUCC_207PP0204X: 'Allopathic & Osteopathic Physicians; Emergency Medicine, Pediatric Emergency Medicine',
    NUCC_207PS0010X: 'Allopathic & Osteopathic Physicians; Emergency Medicine, Sports Medicine',
    NUCC_207PT0002X: 'Allopathic & Osteopathic Physicians; Emergency Medicine, Medical Toxicology',
    NUCC_207Q00000X: 'Allopathic & Osteopathic Physicians; Family Medicine',
    NUCC_207QA0000X: 'Allopathic & Osteopathic Physicians; Family Medicine, Adolescent Medicine',
    NUCC_207QA0401X: 'Allopathic & Osteopathic Physicians; Family Medicine, Addiction Medicine',
    NUCC_207QA0505X: 'Allopathic & Osteopathic Physicians; Family Medicine, Adult Medicine',
    NUCC_207QB0002X: 'Allopathic & Osteopathic Physicians; Family Medicine, Obesity Medicine',
    NUCC_207QG0300X: 'Allopathic & Osteopathic Physicians; Family Medicine, Geriatric Medicine',
    NUCC_207QH0002X: 'Allopathic & Osteopathic Physicians; Family Medicine, Hospice and Palliative Medicine',
    NUCC_207QS0010X: 'Allopathic & Osteopathic Physicians; Family Medicine, Sports Medicine',
    NUCC_207QS1201X: 'Allopathic & Osteopathic Physicians; Family Medicine, Sleep Medicine',
    NUCC_207R00000X: 'Allopathic & Osteopathic Physicians; Internal Medicine',
    NUCC_207RA0000X: 'Allopathic & Osteopathic Physicians; Internal Medicine, Adolescent Medicine',
    NUCC_207RA0001X: 'Allopathic & Osteopathic Physicians; Internal Medicine, Advanced Heart Failure and Transplant Cardiology',
    NUCC_207RA0002X: 'Allopathic & Osteopathic Physicians; Internal Medicine, Adult Congenital Heart Disease',
    NUCC_207RA0201X: 'Allopathic & Osteopathic Physicians; Internal Medicine, Allergy & Immunology',
    NUCC_207RA0401X: 'Allopathic & Osteopathic Physicians; Internal Medicine, Addiction Medicine',
    NUCC_207RB0002X: 'Allopathic & Osteopathic Physicians; Internal Medicine, Obesity Medicine',
    NUCC_207RC0000X: 'Allopathic & Osteopathic Physicians; Internal Medicine, Cardiovascular Disease',
    NUCC_207RC0001X: 'Allopathic & Osteopathic Physicians; Internal Medicine, Clinical Cardiac Electrophysiology',
    NUCC_207RC0200X: 'Allopathic & Osteopathic Physicians; Internal Medicine, Critical Care Medicine',
    NUCC_207RE0101X: 'Allopathic & Osteopathic Physicians; Internal Medicine, Endocrinology, Diabetes & Metabolism',
    NUCC_207RG0100X: 'Allopathic & Osteopathic Physicians; Internal Medicine, Gastroenterology',
    NUCC_207RG0300X: 'Allopathic & Osteopathic Physicians; Internal Medicine, Geriatric Medicine',
    NUCC_207RH0000X: 'Allopathic & Osteopathic Physicians; Internal Medicine, Hematology',
    NUCC_207RH0002X: 'Allopathic & Osteopathic Physicians; Internal Medicine, Hospice and Palliative Medicine',
    NUCC_207RH0003X: 'Allopathic & Osteopathic Physicians; Internal Medicine, Hematology & Oncology',
    NUCC_207RH0005X: 'Allopathic & Osteopathic Physicians; Internal Medicine, Hypertension Specialist',
    NUCC_207RI0001X: 'Allopathic & Osteopathic Physicians; Internal Medicine, Clinical & Laboratory Immunology',
    NUCC_207RI0008X: 'Allopathic & Osteopathic Physicians; Internal Medicine, Hepatology',
    NUCC_207RI0011X: 'Allopathic & Osteopathic Physicians; Internal Medicine, Interventional Cardiology',
    NUCC_207RI0200X: 'Allopathic & Osteopathic Physicians; Internal Medicine, Infectious Disease',
    NUCC_207RM1200X: 'Allopathic & Osteopathic Physicians; Internal Medicine, Magnetic Resonance Imaging (MRI)',
    NUCC_207RN0300X: 'Allopathic & Osteopathic Physicians; Internal Medicine, Nephrology',
    NUCC_207RP1001X: 'Allopathic & Osteopathic Physicians; Internal Medicine, Pulmonary Disease',
    NUCC_207RR0500X: 'Allopathic & Osteopathic Physicians; Internal Medicine, Rheumatology',
    NUCC_207RS0010X: 'Allopathic & Osteopathic Physicians; Internal Medicine, Sports Medicine',
    NUCC_207RS0012X: 'Allopathic & Osteopathic Physicians; Internal Medicine, Sleep Medicine',
    NUCC_207RT0003X: 'Allopathic & Osteopathic Physicians; Internal Medicine, Transplant Hepatology',
    NUCC_207RX0202X: 'Allopathic & Osteopathic Physicians; Internal Medicine, Medical Oncology',
    NUCC_207SC0300X: 'Allopathic & Osteopathic Physicians; Medical Genetics, Clinical Cytogenetics',
    NUCC_207SG0201X: 'Allopathic & Osteopathic Physicians; Medical Genetics, Clinical Genetics (M.D.)',
    NUCC_207SG0202X: 'Allopathic & Osteopathic Physicians; Medical Genetics, Clinical Biochemical Genetics',
    NUCC_207SG0203X: 'Allopathic & Osteopathic Physicians; Medical Genetics, Clinical Molecular Genetics',
    NUCC_207SG0205X: 'Allopathic & Osteopathic Physicians; Medical Genetics, Ph.D. Medical Genetics',
    NUCC_207SM0001X: 'Allopathic & Osteopathic Physicians; Medical Genetics, Molecular Genetic Pathology',
    NUCC_207T00000X: 'Allopathic & Osteopathic Physicians; Neurological Surgery',
    NUCC_207U00000X: 'Allopathic & Osteopathic Physicians; Nuclear Medicine',
    NUCC_207UN0901X: 'Allopathic & Osteopathic Physicians; Nuclear Medicine, Nuclear Cardiology',
    NUCC_207UN0902X: 'Allopathic & Osteopathic Physicians; Nuclear Medicine, Nuclear Imaging & Therapy',
    NUCC_207UN0903X: 'Allopathic & Osteopathic Physicians; Nuclear Medicine, In Vivo & In Vitro Nuclear Medicine',
    NUCC_207V00000X: 'Allopathic & Osteopathic Physicians; Obstetrics & Gynecology',
    NUCC_207VB0002X: 'Allopathic & Osteopathic Physicians; Obstetrics & Gynecology, Obesity Medicine',
    NUCC_207VC0200X: 'Allopathic & Osteopathic Physicians; Obstetrics & Gynecology, Critical Care Medicine',
    NUCC_207VC0300X: 'Allopathic & Osteopathic Physicians; Obstetrics & Gynecology, Complex Family Planning',
    NUCC_207VE0102X: 'Allopathic & Osteopathic Physicians; Obstetrics & Gynecology, Reproductive Endocrinology',
    NUCC_207VF0040X: 'Allopathic & Osteopathic Physicians; Obstetrics & Gynecology, Female Pelvic Medicine and Reconstructive Surgery',
    NUCC_207VG0400X: 'Allopathic & Osteopathic Physicians; Obstetrics & Gynecology, Gynecology',
    NUCC_207VH0002X: 'Allopathic & Osteopathic Physicians; Obstetrics & Gynecology, Hospice and Palliative Medicine',
    NUCC_207VM0101X: 'Allopathic & Osteopathic Physicians; Obstetrics & Gynecology, Maternal & Fetal Medicine',
    NUCC_207VX0000X: 'Allopathic & Osteopathic Physicians; Obstetrics & Gynecology, Obstetrics',
    NUCC_207VX0201X: 'Allopathic & Osteopathic Physicians; Obstetrics & Gynecology, Gynecologic Oncology',
    NUCC_207W00000X: 'Allopathic & Osteopathic Physicians; Ophthalmology',
    NUCC_207WX0009X: 'Allopathic & Osteopathic Physicians; Ophthalmology, Glaucoma Specialist',
    NUCC_207WX0107X: 'Allopathic & Osteopathic Physicians; Ophthalmology, Retina Specialist',
    NUCC_207WX0108X: 'Allopathic & Osteopathic Physicians; Ophthalmology, Uveitis and Ocular Inflammatory Disease',
    NUCC_207WX0109X: 'Allopathic & Osteopathic Physicians; Ophthalmology, Neuro-ophthalmology',
    NUCC_207WX0110X: 'Allopathic & Osteopathic Physicians; Ophthalmology, Pediatric Ophthalmology and Strabismus Specialist',
    NUCC_207WX0120X: 'Allopathic & Osteopathic Physicians; Ophthalmology, Cornea and External Diseases Specialist',
    NUCC_207WX0200X: 'Allopathic & Osteopathic Physicians; Ophthalmology, Ophthalmic Plastic and Reconstructive Surgery',
    NUCC_207X00000X: 'Allopathic & Osteopathic Physicians; Orthopaedic Surgery',
    NUCC_207XP3100X: 'Allopathic & Osteopathic Physicians; Orthopaedic Surgery, Pediatric Orthopaedic Surgery',
    NUCC_207XS0106X: 'Allopathic & Osteopathic Physicians; Orthopaedic Surgery, Hand Surgery',
    NUCC_207XS0114X: 'Allopathic & Osteopathic Physicians; Orthopaedic Surgery, Adult Reconstructive Orthopaedic Surgery',
    NUCC_207XS0117X: 'Allopathic & Osteopathic Physicians; Orthopaedic Surgery, Orthopaedic Surgery of the Spine',
    NUCC_207XX0004X: 'Allopathic & Osteopathic Physicians; Orthopaedic Surgery, Foot and Ankle Surgery',
    NUCC_207XX0005X: 'Allopathic & Osteopathic Physicians; Orthopaedic Surgery, Sports Medicine',
    NUCC_207XX0801X: 'Allopathic & Osteopathic Physicians; Orthopaedic Surgery, Orthopaedic Trauma',
    NUCC_207Y00000X: 'Allopathic & Osteopathic Physicians; Otolaryngology',
    NUCC_207YP0228X: 'Allopathic & Osteopathic Physicians; Otolaryngology, Pediatric Otolaryngology',
    NUCC_207YS0012X: 'Allopathic & Osteopathic Physicians; Otolaryngology, Sleep Medicine',
    NUCC_207YS0123X: 'Allopathic & Osteopathic Physicians; Otolaryngology, Facial Plastic Surgery',
    NUCC_207YX0007X: 'Allopathic & Osteopathic Physicians; Otolaryngology, Plastic Surgery within the Head & Neck',
    NUCC_207YX0602X: 'Allopathic & Osteopathic Physicians; Otolaryngology, Otolaryngic Allergy',
    NUCC_207YX0901X: 'Allopathic & Osteopathic Physicians; Otolaryngology, Otology & Neurotology',
    NUCC_207YX0905X: 'Allopathic & Osteopathic Physicians; Otolaryngology, Otolaryngology/Facial Plastic Surgery',
    NUCC_207ZB0001X: 'Allopathic & Osteopathic Physicians; Pathology, Blood Banking & Transfusion Medicine',
    NUCC_207ZC0006X: 'Allopathic & Osteopathic Physicians; Pathology, Clinical Pathology',
    NUCC_207ZC0008X: 'Allopathic & Osteopathic Physicians; Pathology, Clinical Informatics',
    NUCC_207ZC0500X: 'Allopathic & Osteopathic Physicians; Pathology, Cytopathology',
    NUCC_207ZD0900X: 'Allopathic & Osteopathic Physicians; Pathology, Dermatopathology',
    NUCC_207ZF0201X: 'Allopathic & Osteopathic Physicians; Pathology, Forensic Pathology',
    NUCC_207ZH0000X: 'Allopathic & Osteopathic Physicians; Pathology, Hematology',
    NUCC_207ZI0100X: 'Allopathic & Osteopathic Physicians; Pathology, Immunopathology',
    NUCC_207ZM0300X: 'Allopathic & Osteopathic Physicians; Pathology, Medical Microbiology',
    NUCC_207ZN0500X: 'Allopathic & Osteopathic Physicians; Pathology, Neuropathology',
    NUCC_207ZP0007X: 'Allopathic & Osteopathic Physicians; Pathology, Molecular Genetic Pathology',
    NUCC_207ZP0101X: 'Allopathic & Osteopathic Physicians; Pathology, Anatomic Pathology',
    NUCC_207ZP0102X: 'Allopathic & Osteopathic Physicians; Pathology, Anatomic Pathology & Clinical Pathology',
    NUCC_207ZP0104X: 'Allopathic & Osteopathic Physicians; Pathology, Chemical Pathology',
    NUCC_207ZP0105X: 'Allopathic & Osteopathic Physicians; Pathology, Clinical Pathology/Laboratory Medicine',
    NUCC_207ZP0213X: 'Allopathic & Osteopathic Physicians; Pathology, Pediatric Pathology',
    NUCC_208000000X: 'Allopathic & Osteopathic Physicians; Pediatrics',
    NUCC_2080A0000X: 'Allopathic & Osteopathic Physicians; Pediatrics, Adolescent Medicine',
    NUCC_2080B0002X: 'Allopathic & Osteopathic Physicians; Pediatrics, Obesity Medicine',
    NUCC_2080C0008X: 'Allopathic & Osteopathic Physicians; Pediatrics, Child Abuse Pediatrics',
    NUCC_2080H0002X: 'Allopathic & Osteopathic Physicians; Pediatrics, Hospice and Palliative Medicine',
    NUCC_2080I0007X: 'Allopathic & Osteopathic Physicians; Pediatrics, Clinical & Laboratory Immunology',
    NUCC_2080N0001X: 'Allopathic & Osteopathic Physicians; Pediatrics, Neonatal-Perinatal Medicine',
    NUCC_2080P0006X: 'Allopathic & Osteopathic Physicians; Pediatrics, Developmental - Behavioral Pediatrics',
    NUCC_2080P0008X: 'Allopathic & Osteopathic Physicians; Pediatrics, Neurodevelopmental Disabilities',
    NUCC_2080P0201X: 'Allopathic & Osteopathic Physicians; Pediatrics, Pediatric Allergy/Immunology',
    NUCC_2080P0202X: 'Allopathic & Osteopathic Physicians; Pediatrics, Pediatric Cardiology',
    NUCC_2080P0203X: 'Allopathic & Osteopathic Physicians; Pediatrics, Pediatric Critical Care Medicine',
    NUCC_2080P0204X: 'Allopathic & Osteopathic Physicians; Pediatrics, Pediatric Emergency Medicine',
    NUCC_2080P0205X: 'Allopathic & Osteopathic Physicians; Pediatrics, Pediatric Endocrinology',
    NUCC_2080P0206X: 'Allopathic & Osteopathic Physicians; Pediatrics, Pediatric Gastroenterology',
    NUCC_2080P0207X: 'Allopathic & Osteopathic Physicians; Pediatrics, Pediatric Hematology-Oncology',
    NUCC_2080P0208X: 'Allopathic & Osteopathic Physicians; Pediatrics, Pediatric Infectious Diseases',
    NUCC_2080P0210X: 'Allopathic & Osteopathic Physicians; Pediatrics, Pediatric Nephrology',
    NUCC_2080P0214X: 'Allopathic & Osteopathic Physicians; Pediatrics, Pediatric Pulmonology',
    NUCC_2080P0216X: 'Allopathic & Osteopathic Physicians; Pediatrics, Pediatric Rheumatology',
    NUCC_2080S0010X: 'Allopathic & Osteopathic Physicians; Pediatrics, Sports Medicine',
    NUCC_2080S0012X: 'Allopathic & Osteopathic Physicians; Pediatrics, Sleep Medicine',
    NUCC_2080T0002X: 'Allopathic & Osteopathic Physicians; Pediatrics, Medical Toxicology',
    NUCC_2080T0004X: 'Allopathic & Osteopathic Physicians; Pediatrics, Pediatric Transplant Hepatology',
    NUCC_208100000X: 'Allopathic & Osteopathic Physicians; Physical Medicine & Rehabilitation',
    NUCC_2081H0002X: 'Allopathic & Osteopathic Physicians; Physical Medicine & Rehabilitation, Hospice and Palliative Medicine',
    NUCC_2081N0008X: 'Allopathic & Osteopathic Physicians; Physical Medicine & Rehabilitation, Neuromuscular Medicine',
    NUCC_2081P0004X: 'Allopathic & Osteopathic Physicians; Physical Medicine & Rehabilitation, Spinal Cord Injury Medicine',
    NUCC_2081P0010X: 'Allopathic & Osteopathic Physicians; Physical Medicine & Rehabilitation, Pediatric Rehabilitation Medicine',
    NUCC_2081P0301X: 'Allopathic & Osteopathic Physicians; Physical Medicine & Rehabilitation, Brain Injury Medicine',
    NUCC_2081P2900X: 'Allopathic & Osteopathic Physicians; Physical Medicine & Rehabilitation, Pain Medicine',
    NUCC_2081S0010X: 'Allopathic & Osteopathic Physicians; Physical Medicine & Rehabilitation, Sports Medicine',
    NUCC_208200000X: 'Allopathic & Osteopathic Physicians; Plastic Surgery',
    NUCC_2082S0099X: 'Allopathic & Osteopathic Physicians; Plastic Surgery, Plastic Surgery Within the Head and Neck',
    NUCC_2082S0105X: 'Allopathic & Osteopathic Physicians; Plastic Surgery, Surgery of the Hand',
    NUCC_2083A0100X: 'Allopathic & Osteopathic Physicians; Preventive Medicine, Aerospace Medicine',
    NUCC_2083A0300X: 'Allopathic & Osteopathic Physicians; Preventive Medicine, Addiction Medicine',
    NUCC_2083B0002X: 'Allopathic & Osteopathic Physicians; Preventive Medicine, Obesity Medicine',
    NUCC_2083C0008X: 'Allopathic & Osteopathic Physicians; Preventive Medicine, Clinical Informatics',
    NUCC_2083P0011X: 'Allopathic & Osteopathic Physicians; Preventive Medicine, Undersea and Hyperbaric Medicine',
    NUCC_2083P0500X: 'Allopathic & Osteopathic Physicians; Preventive Medicine, Preventive Medicine/Occupational Environmental Medicine',
    NUCC_2083P0901X: 'Allopathic & Osteopathic Physicians; Preventive Medicine, Public Health & General Preventive Medicine',
    NUCC_2083S0010X: 'Allopathic & Osteopathic Physicians; Preventive Medicine, Sports Medicine',
    NUCC_2083T0002X: 'Allopathic & Osteopathic Physicians; Preventive Medicine, Medical Toxicology',
    NUCC_2083X0100X: 'Allopathic & Osteopathic Physicians; Preventive Medicine, Occupational Medicine',
    NUCC_2084A0401X: 'Allopathic & Osteopathic Physicians; Psychiatry & Neurology, Addiction Medicine',
    NUCC_2084A2900X: 'Allopathic & Osteopathic Physicians; Psychiatry & Neurology, Neurocritical Care',
    NUCC_2084B0002X: 'Allopathic & Osteopathic Physicians; Psychiatry & Neurology, Obesity Medicine',
    NUCC_2084B0040X: 'Allopathic & Osteopathic Physicians; Psychiatry & Neurology, Behavioral Neurology & Neuropsychiatry',
    NUCC_2084D0003X: 'Allopathic & Osteopathic Physicians; Psychiatry & Neurology, Diagnostic Neuroimaging',
    NUCC_2084E0001X: 'Allopathic & Osteopathic Physicians; Psychiatry & Neurology, Epilepsy',
    NUCC_2084F0202X: 'Allopathic & Osteopathic Physicians; Psychiatry & Neurology, Forensic Psychiatry',
    NUCC_2084H0002X: 'Allopathic & Osteopathic Physicians; Psychiatry & Neurology, Hospice and Palliative Medicine',
    NUCC_2084N0008X: 'Allopathic & Osteopathic Physicians; Psychiatry & Neurology, Neuromuscular Medicine',
    NUCC_2084N0400X: 'Allopathic & Osteopathic Physicians; Psychiatry & Neurology, Neurology',
    NUCC_2084N0402X: 'Allopathic & Osteopathic Physicians; Psychiatry & Neurology, Neurology with Special Qualifications in Child Neurology',
    NUCC_2084N0600X: 'Allopathic & Osteopathic Physicians; Psychiatry & Neurology, Clinical Neurophysiology',
    NUCC_2084P0005X: 'Allopathic & Osteopathic Physicians; Psychiatry & Neurology, Neurodevelopmental Disabilities',
    NUCC_2084P0015X: 'Allopathic & Osteopathic Physicians; Psychiatry & Neurology, Psychosomatic Medicine',
    NUCC_2084P0301X: 'Allopathic & Osteopathic Physicians; Psychiatry & Neurology, Brain Injury Medicine',
    NUCC_2084P0800X: 'Allopathic & Osteopathic Physicians; Psychiatry & Neurology, Psychiatry',
    NUCC_2084P0802X: 'Allopathic & Osteopathic Physicians; Psychiatry & Neurology, Addiction Psychiatry',
    NUCC_2084P0804X: 'Allopathic & Osteopathic Physicians; Psychiatry & Neurology, Child & Adolescent Psychiatry',
    NUCC_2084P0805X: 'Allopathic & Osteopathic Physicians; Psychiatry & Neurology, Geriatric Psychiatry',
    NUCC_2084P2900X: 'Allopathic & Osteopathic Physicians; Psychiatry & Neurology, Pain Medicine',
    NUCC_2084S0010X: 'Allopathic & Osteopathic Physicians; Psychiatry & Neurology, Sports Medicine',
    NUCC_2084S0012X: 'Allopathic & Osteopathic Physicians; Psychiatry & Neurology, Sleep Medicine',
    NUCC_2084V0102X: 'Allopathic & Osteopathic Physicians; Psychiatry & Neurology, Vascular Neurology',
    NUCC_2085B0100X: 'Allopathic & Osteopathic Physicians; Radiology, Body Imaging',
    NUCC_2085D0003X: 'Allopathic & Osteopathic Physicians; Radiology, Diagnostic Neuroimaging',
    NUCC_2085H0002X: 'Allopathic & Osteopathic Physicians; Radiology, Hospice and Palliative Medicine',
    NUCC_2085N0700X: 'Allopathic & Osteopathic Physicians; Radiology, Neuroradiology',
    NUCC_2085N0904X: 'Allopathic & Osteopathic Physicians; Radiology, Nuclear Radiology',
    NUCC_2085P0229X: 'Allopathic & Osteopathic Physicians; Radiology, Pediatric Radiology',
    NUCC_2085R0001X: 'Allopathic & Osteopathic Physicians; Radiology, Radiation Oncology',
    NUCC_2085R0202X: 'Allopathic & Osteopathic Physicians; Radiology, Diagnostic Radiology',
    NUCC_2085R0203X: 'Allopathic & Osteopathic Physicians; Radiology, Therapeutic Radiology',
    NUCC_2085R0204X: 'Allopathic & Osteopathic Physicians; Radiology, Vascular & Interventional Radiology',
    NUCC_2085R0205X: 'Allopathic & Osteopathic Physicians; Radiology, Radiological Physics',
    NUCC_2085U0001X: 'Allopathic & Osteopathic Physicians; Radiology, Diagnostic Ultrasound',
    NUCC_208600000X: 'Allopathic & Osteopathic Physicians; Surgery',
    NUCC_2086H0002X: 'Allopathic & Osteopathic Physicians; Surgery, Hospice and Palliative Medicine',
    NUCC_2086S0102X: 'Allopathic & Osteopathic Physicians; Surgery, Surgical Critical Care',
    NUCC_2086S0105X: 'Allopathic & Osteopathic Physicians; Surgery, Surgery of the Hand',
    NUCC_2086S0120X: 'Allopathic & Osteopathic Physicians; Surgery, Pediatric Surgery',
    NUCC_2086S0122X: 'Allopathic & Osteopathic Physicians; Surgery, Plastic and Reconstructive Surgery',
    NUCC_2086S0127X: 'Allopathic & Osteopathic Physicians; Surgery, Trauma Surgery',
    NUCC_2086S0129X: 'Allopathic & Osteopathic Physicians; Surgery, Vascular Surgery',
    NUCC_2086X0206X: 'Allopathic & Osteopathic Physicians; Surgery, Surgical Oncology',
    NUCC_208800000X: 'Allopathic & Osteopathic Physicians; Urology',
    NUCC_2088F0040X: 'Allopathic & Osteopathic Physicians; Urology, Female Pelvic Medicine and Reconstructive Surgery',
    NUCC_2088P0231X: 'Allopathic & Osteopathic Physicians; Urology, Pediatric Urology',
    NUCC_208C00000X: 'Allopathic & Osteopathic Physicians; Colon & Rectal Surgery',
    NUCC_208D00000X: 'Allopathic & Osteopathic Physicians; General Practice',
    NUCC_208G00000X: 'Allopathic & Osteopathic Physicians; Thoracic Surgery (Cardiothoracic Vascular Surgery)',
    NUCC_208M00000X: 'Allopathic & Osteopathic Physicians; Hospitalist',
    NUCC_208U00000X: 'Allopathic & Osteopathic Physicians; Clinical Pharmacology',
    NUCC_208VP0000X: 'Allopathic & Osteopathic Physicians; Pain Medicine, Pain Medicine',
    NUCC_208VP0014X: 'Allopathic & Osteopathic Physicians; Pain Medicine, Interventional Pain Medicine',
    NUCC_209800000X: 'Allopathic & Osteopathic Physicians; Legal Medicine',
    NUCC_211D00000X: 'Podiatric Medicine & Surgery Service Providers; Assistant, Podiatric',
    NUCC_213E00000X: 'Podiatric Medicine & Surgery Service Providers; Podiatrist',
    NUCC_213EP0504X: 'Podiatric Medicine & Surgery Service Providers; Podiatrist, Public Medicine',
    NUCC_213EP1101X: 'Podiatric Medicine & Surgery Service Providers; Podiatrist, Primary Podiatric Medicine',
    NUCC_213ER0200X: 'Podiatric Medicine & Surgery Service Providers; Podiatrist, Radiology',
    NUCC_213ES0000X: 'Podiatric Medicine & Surgery Service Providers; Podiatrist, Sports Medicine',
    NUCC_213ES0103X: 'Podiatric Medicine & Surgery Service Providers; Podiatrist, Foot & Ankle Surgery',
    NUCC_213ES0131X: 'Podiatric Medicine & Surgery Service Providers; Podiatrist, Foot Surgery',
    NUCC_221700000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Art Therapist',
    NUCC_222Q00000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Developmental Therapist',
    NUCC_222Z00000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Orthotist',
    NUCC_224900000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Mastectomy Fitter',
    NUCC_224L00000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Pedorthist',
    NUCC_224P00000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Prosthetist',
    NUCC_224Y00000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Clinical Exercise Physiologist',
    NUCC_224Z00000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Occupational Therapy Assistant',
    NUCC_224ZE0001X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Occupational Therapy Assistant, Environmental Modification',
    NUCC_224ZF0002X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Occupational Therapy Assistant, Feeding, Eating & Swallowing',
    NUCC_224ZL0004X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Occupational Therapy Assistant, Low Vision',
    NUCC_224ZR0403X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Occupational Therapy Assistant, Driving and Community Mobility',
    NUCC_225000000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Orthotic Fitter',
    NUCC_225100000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Physical Therapist',
    NUCC_2251C2600X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Physical Therapist, Cardiopulmonary',
    NUCC_2251E1200X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Physical Therapist, Ergonomics',
    NUCC_2251E1300X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Physical Therapist, Electrophysiology, Clinical',
    NUCC_2251G0304X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Physical Therapist, Geriatrics',
    NUCC_2251H1200X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Physical Therapist, Hand',
    NUCC_2251H1300X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Physical Therapist, Human Factors',
    NUCC_2251N0400X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Physical Therapist, Neurology',
    NUCC_2251P0200X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Physical Therapist, Pediatrics',
    NUCC_2251S0007X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Physical Therapist, Sports',
    NUCC_2251X0800X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Physical Therapist, Orthopedic',
    NUCC_225200000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Physical Therapy Assistant',
    NUCC_225400000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Rehabilitation Practitioner',
    NUCC_225500000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Specialist/Technologist',
    NUCC_2255A2300X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Specialist/Technologist, Athletic Trainer',
    NUCC_2255R0406X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Specialist/Technologist, Rehabilitation, Blind',
    NUCC_225600000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Dance Therapist',
    NUCC_225700000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Massage Therapist',
    NUCC_225800000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Recreation Therapist',
    NUCC_225A00000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Music Therapist',
    NUCC_225B00000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Pulmonary Function Technologist',
    NUCC_225C00000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Rehabilitation Counselor',
    NUCC_225CA2400X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Rehabilitation Counselor, Assistive Technology Practitioner',
    NUCC_225CA2500X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Rehabilitation Counselor, Assistive Technology Supplier',
    NUCC_225CX0006X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Rehabilitation Counselor, Orientation and Mobility Training Provider',
    NUCC_225X00000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Occupational Therapist',
    NUCC_225XE0001X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Occupational Therapist, Environmental Modification',
    NUCC_225XE1200X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Occupational Therapist, Ergonomics',
    NUCC_225XF0002X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Occupational Therapist, Feeding, Eating & Swallowing',
    NUCC_225XG0600X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Occupational Therapist, Gerontology',
    NUCC_225XH1200X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Occupational Therapist, Hand',
    NUCC_225XH1300X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Occupational Therapist, Human Factors',
    NUCC_225XL0004X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Occupational Therapist, Low Vision',
    NUCC_225XM0800X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Occupational Therapist, Mental Health',
    NUCC_225XN1300X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Occupational Therapist, Neurorehabilitation',
    NUCC_225XP0019X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Occupational Therapist, Physical Rehabilitation',
    NUCC_225XP0200X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Occupational Therapist, Pediatrics',
    NUCC_225XR0403X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Occupational Therapist, Driving and Community Mobility',
    NUCC_226000000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Recreational Therapist Assistant',
    NUCC_226300000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Kinesiotherapist',
    NUCC_227800000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Respiratory Therapist, Certified',
    NUCC_2278C0205X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Respiratory Therapist, Certified, Critical Care',
    NUCC_2278E0002X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Respiratory Therapist, Certified, Emergency Care',
    NUCC_2278E1000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Respiratory Therapist, Certified, Educational',
    NUCC_2278G0305X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Respiratory Therapist, Certified, Geriatric Care',
    NUCC_2278G1100X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Respiratory Therapist, Certified, General Care',
    NUCC_2278H0200X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Respiratory Therapist, Certified, Home Health',
    NUCC_2278P1004X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Respiratory Therapist, Certified, Pulmonary Diagnostics',
    NUCC_2278P1005X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Respiratory Therapist, Certified, Pulmonary Rehabilitation',
    NUCC_2278P1006X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Respiratory Therapist, Certified, Pulmonary Function Technologist',
    NUCC_2278P3800X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Respiratory Therapist, Certified, Palliative/Hospice',
    NUCC_2278P3900X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Respiratory Therapist, Certified, Neonatal/Pediatrics',
    NUCC_2278P4000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Respiratory Therapist, Certified, Patient Transport',
    NUCC_2278S1500X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Respiratory Therapist, Certified, SNF/Subacute Care',
    NUCC_227900000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Respiratory Therapist, Registered',
    NUCC_2279C0205X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Respiratory Therapist, Registered, Critical Care',
    NUCC_2279E0002X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Respiratory Therapist, Registered, Emergency Care',
    NUCC_2279E1000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Respiratory Therapist, Registered, Educational',
    NUCC_2279G0305X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Respiratory Therapist, Registered, Geriatric Care',
    NUCC_2279G1100X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Respiratory Therapist, Registered, General Care',
    NUCC_2279H0200X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Respiratory Therapist, Registered, Home Health',
    NUCC_2279P1004X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Respiratory Therapist, Registered, Pulmonary Diagnostics',
    NUCC_2279P1005X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Respiratory Therapist, Registered, Pulmonary Rehabilitation',
    NUCC_2279P1006X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Respiratory Therapist, Registered, Pulmonary Function Technologist',
    NUCC_2279P3800X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Respiratory Therapist, Registered, Palliative/Hospice',
    NUCC_2279P3900X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Respiratory Therapist, Registered, Neonatal/Pediatrics',
    NUCC_2279P4000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Respiratory Therapist, Registered, Patient Transport',
    NUCC_2279S1500X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Respiratory Therapist, Registered, SNF/Subacute Care',
    NUCC_229N00000X: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers; Anaplastologist',
    NUCC_231H00000X: 'Speech, Language and Hearing Service Providers; Audiologist',
    NUCC_231HA2400X: 'Speech, Language and Hearing Service Providers; Audiologist, Assistive Technology Practitioner',
    NUCC_231HA2500X: 'Speech, Language and Hearing Service Providers; Audiologist, Assistive Technology Supplier',
    NUCC_235500000X: 'Speech, Language and Hearing Service Providers; Specialist/Technologist',
    NUCC_2355A2700X: 'Speech, Language and Hearing Service Providers; Specialist/Technologist, Audiology Assistant',
    NUCC_2355S0801X: 'Speech, Language and Hearing Service Providers; Specialist/Technologist, Speech-Language Assistant',
    NUCC_235Z00000X: 'Speech, Language and Hearing Service Providers; Speech-Language Pathologist',
    NUCC_237600000X: 'Speech, Language and Hearing Service Providers; Audiologist-Hearing Aid Fitter',
    NUCC_237700000X: 'Speech, Language and Hearing Service Providers; Hearing Instrument Specialist',
    NUCC_242T00000X: 'Technologists, Technicians & Other Technical Service Providers; Perfusionist',
    NUCC_243U00000X: 'Technologists, Technicians & Other Technical Service Providers; Radiology Practitioner Assistant',
    NUCC_246Q00000X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Pathology',
    NUCC_246QB0000X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Pathology, Blood Banking',
    NUCC_246QC1000X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Pathology, Chemistry',
    NUCC_246QC2700X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Pathology, Cytotechnology',
    NUCC_246QH0000X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Pathology, Hematology',
    NUCC_246QH0401X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Pathology, Hemapheresis Practitioner',
    NUCC_246QH0600X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Pathology, Histology',
    NUCC_246QI0000X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Pathology, Immunology',
    NUCC_246QL0900X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Pathology, Laboratory Management',
    NUCC_246QL0901X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Pathology, Laboratory Management, Diplomate',
    NUCC_246QM0706X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Pathology, Medical Technologist',
    NUCC_246QM0900X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Pathology, Microbiology',
    NUCC_246R00000X: 'Technologists, Technicians & Other Technical Service Providers; Technician, Pathology',
    NUCC_246RH0600X: 'Technologists, Technicians & Other Technical Service Providers; Technician, Pathology, Histology',
    NUCC_246RM2200X: 'Technologists, Technicians & Other Technical Service Providers; Technician, Pathology, Medical Laboratory',
    NUCC_246RP1900X: 'Technologists, Technicians & Other Technical Service Providers; Technician, Pathology, Phlebotomy',
    NUCC_246W00000X: 'Technologists, Technicians & Other Technical Service Providers; Technician, Cardiology',
    NUCC_246X00000X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist Cardiovascular',
    NUCC_246XC2901X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist Cardiovascular, Cardiovascular Invasive Specialist',
    NUCC_246XC2903X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist Cardiovascular, Vascular Specialist',
    NUCC_246XS1301X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist Cardiovascular, Sonography',
    NUCC_246Y00000X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Health Information',
    NUCC_246YC3301X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Health Information, Coding Specialist, Hospital Based',
    NUCC_246YC3302X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Health Information, Coding Specialist, Physician Office Based',
    NUCC_246YR1600X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Health Information, Registered Record Administrator',
    NUCC_246Z00000X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Other',
    NUCC_246ZA2600X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Other, Art, Medical',
    NUCC_246ZB0301X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Other, Biomedical Engineering',
    NUCC_246ZB0302X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Other, Biomedical Photographer',
    NUCC_246ZB0500X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Other, Biochemist',
    NUCC_246ZB0600X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Other, Biostatistician',
    NUCC_246ZC0007X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Other, Surgical Assistant',
    NUCC_246ZE0500X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Other, EEG',
    NUCC_246ZE0600X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Other, Electroneurodiagnostic',
    NUCC_246ZG0701X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Other, Graphics Methods',
    NUCC_246ZG1000X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Other, Geneticist, Medical (PhD)',
    NUCC_246ZI1000X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Other, Illustration, Medical',
    NUCC_246ZN0300X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Other, Nephrology',
    NUCC_246ZS0410X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Other, Surgical Technologist',
    NUCC_246ZX2200X: 'Technologists, Technicians & Other Technical Service Providers; Specialist/Technologist, Other, Orthopedic Assistant',
    NUCC_247000000X: 'Technologists, Technicians & Other Technical Service Providers; Technician, Health Information',
    NUCC_2470A2800X: 'Technologists, Technicians & Other Technical Service Providers; Technician, Health Information, Assistant Record Technician',
    NUCC_247100000X: 'Technologists, Technicians & Other Technical Service Providers; Radiologic Technologist',
    NUCC_2471B0102X: 'Technologists, Technicians & Other Technical Service Providers; Radiologic Technologist, Bone Densitometry',
    NUCC_2471C1101X: 'Technologists, Technicians & Other Technical Service Providers; Radiologic Technologist, Cardiovascular-Interventional Technology',
    NUCC_2471C1106X: 'Technologists, Technicians & Other Technical Service Providers; Radiologic Technologist, Cardiac-Interventional Technology',
    NUCC_2471C3401X: 'Technologists, Technicians & Other Technical Service Providers; Radiologic Technologist, Computed Tomography',
    NUCC_2471C3402X: 'Technologists, Technicians & Other Technical Service Providers; Radiologic Technologist, Radiography',
    NUCC_2471M1202X: 'Technologists, Technicians & Other Technical Service Providers; Radiologic Technologist, Magnetic Resonance Imaging',
    NUCC_2471M2300X: 'Technologists, Technicians & Other Technical Service Providers; Radiologic Technologist, Mammography',
    NUCC_2471N0900X: 'Technologists, Technicians & Other Technical Service Providers; Radiologic Technologist, Nuclear Medicine Technology',
    NUCC_2471Q0001X: 'Technologists, Technicians & Other Technical Service Providers; Radiologic Technologist, Quality Management',
    NUCC_2471R0002X: 'Technologists, Technicians & Other Technical Service Providers; Radiologic Technologist, Radiation Therapy',
    NUCC_2471S1302X: 'Technologists, Technicians & Other Technical Service Providers; Radiologic Technologist, Sonography',
    NUCC_2471V0105X: 'Technologists, Technicians & Other Technical Service Providers; Radiologic Technologist, Vascular Sonography',
    NUCC_2471V0106X: 'Technologists, Technicians & Other Technical Service Providers; Radiologic Technologist, Vascular-Interventional Technology',
    NUCC_247200000X: 'Technologists, Technicians & Other Technical Service Providers; Technician, Other',
    NUCC_2472B0301X: 'Technologists, Technicians & Other Technical Service Providers; Technician, Other, Biomedical Engineering',
    NUCC_2472D0500X: 'Technologists, Technicians & Other Technical Service Providers; Technician, Other, Darkroom',
    NUCC_2472E0500X: 'Technologists, Technicians & Other Technical Service Providers; Technician, Other, EEG',
    NUCC_2472R0900X: 'Technologists, Technicians & Other Technical Service Providers; Technician, Other, Renal Dialysis',
    NUCC_2472V0600X: 'Technologists, Technicians & Other Technical Service Providers; Technician, Other, Veterinary',
    NUCC_247ZC0005X: 'Technologists, Technicians & Other Technical Service Providers; Technician, Pathology, Clinical Laboratory Director, Non-physician',
    NUCC_251300000X: 'Agencies; Local Education Agency (LEA)',
    NUCC_251B00000X: 'Agencies; Case Management',
    NUCC_251C00000X: 'Agencies; Day Training, Developmentally Disabled Services',
    NUCC_251E00000X: 'Agencies; Home Health',
    NUCC_251F00000X: 'Agencies; Home Infusion',
    NUCC_251G00000X: 'Agencies; Hospice Care, Community Based',
    NUCC_251J00000X: 'Agencies; Nursing Care',
    NUCC_251K00000X: 'Agencies; Public Health or Welfare',
    NUCC_251S00000X: 'Agencies; Community/Behavioral Health',
    NUCC_251T00000X: 'Agencies; Program of All-Inclusive Care for the Elderly (PACE) Provider Organization',
    NUCC_251V00000X: 'Agencies; Voluntary or Charitable',
    NUCC_251X00000X: 'Agencies; Supports Brokerage',
    NUCC_252Y00000X: 'Agencies; Early Intervention Provider Agency',
    NUCC_253J00000X: 'Agencies; Foster Care Agency',
    NUCC_253Z00000X: 'Agencies; In Home Supportive Care',
    NUCC_261Q00000X: 'Ambulatory Health Care Facilities; Clinic/Center',
    NUCC_261QA0005X: 'Ambulatory Health Care Facilities; Clinic/Center, Ambulatory Family Planning Facility',
    NUCC_261QA0006X: 'Ambulatory Health Care Facilities; Clinic/Center, Ambulatory Fertility Facility',
    NUCC_261QA0600X: 'Ambulatory Health Care Facilities; Clinic/Center, Adult Day Care',
    NUCC_261QA0900X: 'Ambulatory Health Care Facilities; Clinic/Center, Amputee',
    NUCC_261QA1903X: 'Ambulatory Health Care Facilities; Clinic/Center, Ambulatory Surgical',
    NUCC_261QA3000X: 'Ambulatory Health Care Facilities; Clinic/Center, Augmentative Communication',
    NUCC_261QB0400X: 'Ambulatory Health Care Facilities; Clinic/Center, Birthing',
    NUCC_261QC0050X: 'Ambulatory Health Care Facilities; Clinic/Center, Critical Access Hospital',
    NUCC_261QC1500X: 'Ambulatory Health Care Facilities; Clinic/Center, Community Health',
    NUCC_261QC1800X: 'Ambulatory Health Care Facilities; Clinic/Center, Corporate Health',
    NUCC_261QD0000X: 'Ambulatory Health Care Facilities; Clinic/Center, Dental',
    NUCC_261QD1600X: 'Ambulatory Health Care Facilities; Clinic/Center, Developmental Disabilities',
    NUCC_261QE0002X: 'Ambulatory Health Care Facilities; Clinic/Center, Emergency Care',
    NUCC_261QE0700X: 'Ambulatory Health Care Facilities; Clinic/Center, End-Stage Renal Disease (ESRD) Treatment',
    NUCC_261QE0800X: 'Ambulatory Health Care Facilities; Clinic/Center, Endoscopy',
    NUCC_261QF0050X: 'Ambulatory Health Care Facilities; Clinic/Center, Family Planning, Non-Surgical',
    NUCC_261QF0400X: 'Ambulatory Health Care Facilities; Clinic/Center, Federally Qualified Health Center (FQHC)',
    NUCC_261QG0250X: 'Ambulatory Health Care Facilities; Clinic/Center, Genetics',
    NUCC_261QH0100X: 'Ambulatory Health Care Facilities; Clinic/Center, Health Service',
    NUCC_261QH0700X: 'Ambulatory Health Care Facilities; Clinic/Center, Hearing and Speech',
    NUCC_261QI0500X: 'Ambulatory Health Care Facilities; Clinic/Center, Infusion Therapy',
    NUCC_261QL0400X: 'Ambulatory Health Care Facilities; Clinic/Center, Lithotripsy',
    NUCC_261QM0801X: 'Ambulatory Health Care Facilities; Clinic/Center, Mental Health (Including Community Mental Health Center)',
    NUCC_261QM0850X: 'Ambulatory Health Care Facilities; Clinic/Center, Adult Mental Health',
    NUCC_261QM0855X: 'Ambulatory Health Care Facilities; Clinic/Center, Adolescent and Children Mental Health',
    NUCC_261QM1000X: 'Ambulatory Health Care Facilities; Clinic/Center, Migrant Health',
    NUCC_261QM1100X: 'Ambulatory Health Care Facilities; Clinic/Center, Military/U.S. Coast Guard Outpatient',
    NUCC_261QM1101X: 'Ambulatory Health Care Facilities; Clinic/Center, Military and U.S. Coast Guard Ambulatory Procedure',
    NUCC_261QM1102X: 'Ambulatory Health Care Facilities; Clinic/Center, Military Outpatient Operational (Transportable) Component',
    NUCC_261QM1103X: 'Ambulatory Health Care Facilities; Clinic/Center, Military Ambulatory Procedure Visits Operational (Transportable)',
    NUCC_261QM1200X: 'Ambulatory Health Care Facilities; Clinic/Center, Magnetic Resonance Imaging (MRI)',
    NUCC_261QM1300X: 'Ambulatory Health Care Facilities; Clinic/Center, Multi-Specialty',
    NUCC_261QM2500X: 'Ambulatory Health Care Facilities; Clinic/Center, Medical Specialty',
    NUCC_261QM2800X: 'Ambulatory Health Care Facilities; Clinic/Center, Methadone',
    NUCC_261QM3000X: 'Ambulatory Health Care Facilities; Clinic/Center, Medically Fragile Infants and Children Day Care',
    NUCC_261QP0904X: 'Ambulatory Health Care Facilities; Clinic/Center, Public Health, Federal',
    NUCC_261QP0905X: 'Ambulatory Health Care Facilities; Clinic/Center, Public Health, State or Local',
    NUCC_261QP1100X: 'Ambulatory Health Care Facilities; Clinic/Center, Podiatric',
    NUCC_261QP2000X: 'Ambulatory Health Care Facilities; Clinic/Center, Physical Therapy',
    NUCC_261QP2300X: 'Ambulatory Health Care Facilities; Clinic/Center, Primary Care',
    NUCC_261QP2400X: 'Ambulatory Health Care Facilities; Clinic/Center, Prison Health',
    NUCC_261QP3300X: 'Ambulatory Health Care Facilities; Clinic/Center, Pain',
    NUCC_261QR0200X: 'Ambulatory Health Care Facilities; Clinic/Center, Radiology',
    NUCC_261QR0206X: 'Ambulatory Health Care Facilities; Clinic/Center, Radiology, Mammography',
    NUCC_261QR0207X: 'Ambulatory Health Care Facilities; Clinic/Center, Radiology, Mobile Mammography',
    NUCC_261QR0208X: 'Ambulatory Health Care Facilities; Clinic/Center, Radiology, Mobile',
    NUCC_261QR0400X: 'Ambulatory Health Care Facilities; Clinic/Center, Rehabilitation',
    NUCC_261QR0401X: 'Ambulatory Health Care Facilities; Clinic/Center, Rehabilitation, Comprehensive Outpatient Rehabilitation Facility (CORF)',
    NUCC_261QR0404X: 'Ambulatory Health Care Facilities; Clinic/Center, Rehabilitation, Cardiac Facilities',
    NUCC_261QR0405X: 'Ambulatory Health Care Facilities; Clinic/Center, Rehabilitation, Substance Use Disorder',
    NUCC_261QR0800X: 'Ambulatory Health Care Facilities; Clinic/Center, Recovery Care',
    NUCC_261QR1100X: 'Ambulatory Health Care Facilities; Clinic/Center, Research',
    NUCC_261QR1300X: 'Ambulatory Health Care Facilities; Clinic/Center, Rural Health',
    NUCC_261QS0112X: 'Ambulatory Health Care Facilities; Clinic/Center, Oral and Maxillofacial Surgery',
    NUCC_261QS0132X: 'Ambulatory Health Care Facilities; Clinic/Center, Ophthalmologic Surgery',
    NUCC_261QS1000X: 'Ambulatory Health Care Facilities; Clinic/Center, Student Health',
    NUCC_261QS1200X: 'Ambulatory Health Care Facilities; Clinic/Center, Sleep Disorder Diagnostic',
    NUCC_261QU0200X: 'Ambulatory Health Care Facilities; Clinic/Center, Urgent Care',
    NUCC_261QV0200X: 'Ambulatory Health Care Facilities; Clinic/Center, VA',
    NUCC_261QX0100X: 'Ambulatory Health Care Facilities; Clinic/Center, Occupational Medicine',
    NUCC_261QX0200X: 'Ambulatory Health Care Facilities; Clinic/Center, Oncology',
    NUCC_261QX0203X: 'Ambulatory Health Care Facilities; Clinic/Center, Oncology, Radiation',
    NUCC_273100000X: 'Hospital Units; Epilepsy Unit',
    NUCC_273R00000X: 'Hospital Units; Psychiatric Unit',
    NUCC_273Y00000X: 'Hospital Units; Rehabilitation Unit',
    NUCC_275N00000X: 'Hospital Units; Medicare Defined Swing Bed Unit',
    NUCC_276400000X: 'Hospital Units; Rehabilitation, Substance Use Disorder Unit',
    NUCC_281P00000X: 'Hospitals; Chronic Disease Hospital',
    NUCC_281PC2000X: 'Hospitals; Chronic Disease Hospital, Children',
    NUCC_282E00000X: 'Hospitals; Long Term Care Hospital',
    NUCC_282J00000X: 'Hospitals; Religious Nonmedical Health Care Institution',
    NUCC_282N00000X: 'Hospitals; General Acute Care Hospital',
    NUCC_282NC0060X: 'Hospitals; General Acute Care Hospital, Critical Access',
    NUCC_282NC2000X: 'Hospitals; General Acute Care Hospital, Children',
    NUCC_282NR1301X: 'Hospitals; General Acute Care Hospital, Rural',
    NUCC_282NW0100X: 'Hospitals; General Acute Care Hospital, Women',
    NUCC_283Q00000X: 'Hospitals; Psychiatric Hospital',
    NUCC_283X00000X: 'Hospitals; Rehabilitation Hospital',
    NUCC_283XC2000X: 'Hospitals; Rehabilitation Hospital, Children',
    NUCC_284300000X: 'Hospitals; Special Hospital',
    NUCC_286500000X: 'Hospitals; Military Hospital',
    NUCC_2865M2000X: 'Hospitals; Military Hospital, Military General Acute Care Hospital',
    NUCC_2865X1600X: 'Hospitals; Military Hospital, Military General Acute Care Hospital. Operational (Transportable)',
    NUCC_291900000X: 'Laboratories; Military Clinical Medical Laboratory',
    NUCC_291U00000X: 'Laboratories; Clinical Medical Laboratory',
    NUCC_292200000X: 'Laboratories; Dental Laboratory',
    NUCC_293D00000X: 'Laboratories; Physiological Laboratory',
    NUCC_302F00000X: 'Managed Care Organizations; Exclusive Provider Organization',
    NUCC_302R00000X: 'Managed Care Organizations; Health Maintenance Organization',
    NUCC_305R00000X: 'Managed Care Organizations; Preferred Provider Organization',
    NUCC_305S00000X: 'Managed Care Organizations; Point of Service',
    NUCC_310400000X: 'Nursing & Custodial Care Facilities; Assisted Living Facility',
    NUCC_3104A0625X: 'Nursing & Custodial Care Facilities; Assisted Living Facility, Assisted Living, Mental Illness',
    NUCC_3104A0630X: 'Nursing & Custodial Care Facilities; Assisted Living Facility, Assisted Living, Behavioral Disturbances',
    NUCC_310500000X: 'Nursing & Custodial Care Facilities; Intermediate Care Facility, Mental Illness',
    NUCC_311500000X: 'Nursing & Custodial Care Facilities; Alzheimer Center (Dementia Center)',
    NUCC_311Z00000X: 'Nursing & Custodial Care Facilities; Custodial Care Facility',
    NUCC_311ZA0620X: 'Nursing & Custodial Care Facilities; Custodial Care Facility, Adult Care Home',
    NUCC_313M00000X: 'Nursing & Custodial Care Facilities; Nursing Facility/Intermediate Care Facility',
    NUCC_314000000X: 'Nursing & Custodial Care Facilities; Skilled Nursing Facility',
    NUCC_3140N1450X: 'Nursing & Custodial Care Facilities; Skilled Nursing Facility, Nursing Care, Pediatric',
    NUCC_315D00000X: 'Nursing & Custodial Care Facilities; Hospice, Inpatient',
    NUCC_315P00000X: 'Nursing & Custodial Care Facilities; Intermediate Care Facility, Intellectual Disabilities',
    NUCC_320600000X: 'Residential Treatment Facilities; Residential Treatment Facility, Intellectual and/or Developmental Disabilities',
    NUCC_320700000X: 'Residential Treatment Facilities; Residential Treatment Facility, Physical Disabilities',
    NUCC_320800000X: 'Residential Treatment Facilities; Community Based Residential Treatment Facility, Mental Illness',
    NUCC_320900000X: 'Residential Treatment Facilities; Community Based Residential Treatment Facility, Intellectual and/or Developmental Disabilities',
    NUCC_322D00000X: 'Residential Treatment Facilities; Residential Treatment Facility, Emotionally Disturbed Children',
    NUCC_323P00000X: 'Residential Treatment Facilities; Psychiatric Residential Treatment Facility',
    NUCC_324500000X: 'Residential Treatment Facilities; Substance Abuse Rehabilitation Facility',
    NUCC_3245S0500X: 'Residential Treatment Facilities; Substance Abuse Rehabilitation Facility, Substance Abuse Treatment, Children',
    NUCC_331L00000X: 'Suppliers; Blood Bank',
    NUCC_332000000X: 'Suppliers; Military/U.S. Coast Guard Pharmacy',
    NUCC_332100000X: 'Suppliers; Department of Veterans Affairs (VA) Pharmacy',
    NUCC_332800000X: 'Suppliers; Indian Health Service/Tribal/Urban Indian Health (I/T/U) Pharmacy',
    NUCC_332900000X: 'Suppliers; Non-Pharmacy Dispensing Site',
    NUCC_332B00000X: 'Suppliers; Durable Medical Equipment & Medical Supplies',
    NUCC_332BC3200X: 'Suppliers; Durable Medical Equipment & Medical Supplies, Customized Equipment',
    NUCC_332BD1200X: 'Suppliers; Durable Medical Equipment & Medical Supplies, Dialysis Equipment & Supplies',
    NUCC_332BN1400X: 'Suppliers; Durable Medical Equipment & Medical Supplies, Nursing Facility Supplies',
    NUCC_332BP3500X: 'Suppliers; Durable Medical Equipment & Medical Supplies, Parenteral & Enteral Nutrition',
    NUCC_332BX2000X: 'Suppliers; Durable Medical Equipment & Medical Supplies, Oxygen Equipment & Supplies',
    NUCC_332G00000X: 'Suppliers; Eye Bank',
    NUCC_332H00000X: 'Suppliers; Eyewear Supplier',
    NUCC_332S00000X: 'Suppliers; Hearing Aid Equipment',
    NUCC_332U00000X: 'Suppliers; Home Delivered Meals',
    NUCC_333300000X: 'Suppliers; Emergency Response System Companies',
    NUCC_333600000X: 'Suppliers; Pharmacy',
    NUCC_3336C0002X: 'Suppliers; Pharmacy, Clinic Pharmacy',
    NUCC_3336C0003X: 'Suppliers; Pharmacy, Community/Retail Pharmacy',
    NUCC_3336C0004X: 'Suppliers; Pharmacy, Compounding Pharmacy',
    NUCC_3336H0001X: 'Suppliers; Pharmacy, Home Infusion Therapy Pharmacy',
    NUCC_3336I0012X: 'Suppliers; Pharmacy, Institutional Pharmacy',
    NUCC_3336L0003X: 'Suppliers; Pharmacy, Long Term Care Pharmacy',
    NUCC_3336M0002X: 'Suppliers; Pharmacy, Mail Order Pharmacy',
    NUCC_3336M0003X: 'Suppliers; Pharmacy, Managed Care Organization Pharmacy',
    NUCC_3336N0007X: 'Suppliers; Pharmacy, Nuclear Pharmacy',
    NUCC_3336S0011X: 'Suppliers; Pharmacy, Specialty Pharmacy',
    NUCC_335E00000X: 'Suppliers; Prosthetic/Orthotic Supplier',
    NUCC_335G00000X: 'Suppliers; Medical Foods Supplier',
    NUCC_335U00000X: 'Suppliers; Organ Procurement Organization',
    NUCC_335V00000X: 'Suppliers; Portable X-ray and/or Other Portable Diagnostic Imaging Supplier',
    NUCC_341600000X: 'Transportation Services; Ambulance',
    NUCC_3416A0800X: 'Transportation Services; Ambulance, Air Transport',
    NUCC_3416L0300X: 'Transportation Services; Ambulance, Land Transport',
    NUCC_3416S0300X: 'Transportation Services; Ambulance, Water Transport',
    NUCC_341800000X: 'Transportation Services; Military/U.S. Coast Guard Transport',
    NUCC_3418M1110X: 'Transportation Services; Military/U.S. Coast Guard Transport, Military or U.S. Coast Guard Ambulance, Ground Transport',
    NUCC_3418M1120X: 'Transportation Services; Military/U.S. Coast Guard Transport, Military or U.S. Coast Guard Ambulance, Air Transport',
    NUCC_3418M1130X: 'Transportation Services; Military/U.S. Coast Guard Transport, Military or U.S. Coast Guard Ambulance, Water Transport',
    NUCC_342000000X: 'Transportation Services; Transportation Network Company',
    NUCC_343800000X: 'Transportation Services; Secured Medical Transport (VAN)',
    NUCC_343900000X: 'Transportation Services; Non-emergency Medical Transport (VAN)',
    NUCC_344600000X: 'Transportation Services; Taxi',
    NUCC_344800000X: 'Transportation Services; Air Carrier',
    NUCC_347B00000X: 'Transportation Services; Bus',
    NUCC_347C00000X: 'Transportation Services; Private Vehicle',
    NUCC_347D00000X: 'Transportation Services; Train',
    NUCC_347E00000X: 'Transportation Services; Transportation Broker',
    NUCC_363A00000X: 'Physician Assistants & Advanced Practice Nursing Providers; Physician Assistant',
    NUCC_363AM0700X: 'Physician Assistants & Advanced Practice Nursing Providers; Physician Assistant, Medical',
    NUCC_363AS0400X: 'Physician Assistants & Advanced Practice Nursing Providers; Physician Assistant, Surgical',
    NUCC_363L00000X: 'Physician Assistants & Advanced Practice Nursing Providers; Nurse Practitioner',
    NUCC_363LA2100X: 'Physician Assistants & Advanced Practice Nursing Providers; Nurse Practitioner, Acute Care',
    NUCC_363LA2200X: 'Physician Assistants & Advanced Practice Nursing Providers; Nurse Practitioner, Adult Health',
    NUCC_363LC0200X: 'Physician Assistants & Advanced Practice Nursing Providers; Nurse Practitioner, Critical Care Medicine',
    NUCC_363LC1500X: 'Physician Assistants & Advanced Practice Nursing Providers; Nurse Practitioner, Community Health',
    NUCC_363LF0000X: 'Physician Assistants & Advanced Practice Nursing Providers; Nurse Practitioner, Family',
    NUCC_363LG0600X: 'Physician Assistants & Advanced Practice Nursing Providers; Nurse Practitioner, Gerontology',
    NUCC_363LN0000X: 'Physician Assistants & Advanced Practice Nursing Providers; Nurse Practitioner, Neonatal',
    NUCC_363LN0005X: 'Physician Assistants & Advanced Practice Nursing Providers; Nurse Practitioner, Neonatal, Critical Care',
    NUCC_363LP0200X: 'Physician Assistants & Advanced Practice Nursing Providers; Nurse Practitioner, Pediatrics',
    NUCC_363LP0222X: 'Physician Assistants & Advanced Practice Nursing Providers; Nurse Practitioner, Pediatrics, Critical Care',
    NUCC_363LP0808X: 'Physician Assistants & Advanced Practice Nursing Providers; Nurse Practitioner, Psychiatric/Mental Health',
    NUCC_363LP1700X: 'Physician Assistants & Advanced Practice Nursing Providers; Nurse Practitioner, Perinatal',
    NUCC_363LP2300X: 'Physician Assistants & Advanced Practice Nursing Providers; Nurse Practitioner, Primary Care',
    NUCC_363LS0200X: 'Physician Assistants & Advanced Practice Nursing Providers; Nurse Practitioner, School',
    NUCC_363LW0102X: 'Physician Assistants & Advanced Practice Nursing Providers; Nurse Practitioner, Womens Health',
    NUCC_363LX0001X: 'Physician Assistants & Advanced Practice Nursing Providers; Nurse Practitioner, Obstetrics & Gynecology',
    NUCC_363LX0106X: 'Physician Assistants & Advanced Practice Nursing Providers; Nurse Practitioner, Occupational Health',
    NUCC_364S00000X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist',
    NUCC_364SA2100X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Acute Care',
    NUCC_364SA2200X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Adult Health',
    NUCC_364SC0200X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Critical Care Medicine',
    NUCC_364SC1501X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Community Health/Public Health',
    NUCC_364SC2300X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Chronic Care',
    NUCC_364SE0003X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Emergency',
    NUCC_364SE1400X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Ethics',
    NUCC_364SF0001X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Family Health',
    NUCC_364SG0600X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Gerontology',
    NUCC_364SH0200X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Home Health',
    NUCC_364SH1100X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Holistic',
    NUCC_364SI0800X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Informatics',
    NUCC_364SL0600X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Long-Term Care',
    NUCC_364SM0705X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Medical-Surgical',
    NUCC_364SN0000X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Neonatal',
    NUCC_364SN0800X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Neuroscience',
    NUCC_364SP0200X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Pediatrics',
    NUCC_364SP0807X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Psychiatric/Mental Health, Child & Adolescent',
    NUCC_364SP0808X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Psychiatric/Mental Health',
    NUCC_364SP0809X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Psychiatric/Mental Health, Adult',
    NUCC_364SP0810X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Psychiatric/Mental Health, Child & Family',
    NUCC_364SP0811X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Psychiatric/Mental Health, Chronically Ill',
    NUCC_364SP0812X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Psychiatric/Mental Health, Community',
    NUCC_364SP0813X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Psychiatric/Mental Health, Geropsychiatric',
    NUCC_364SP1700X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Perinatal',
    NUCC_364SP2800X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Perioperative',
    NUCC_364SR0400X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Rehabilitation',
    NUCC_364SS0200X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, School',
    NUCC_364ST0500X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Transplantation',
    NUCC_364SW0102X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Womens Health',
    NUCC_364SX0106X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Occupational Health',
    NUCC_364SX0200X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Oncology',
    NUCC_364SX0204X: 'Physician Assistants & Advanced Practice Nursing Providers; Clinical Nurse Specialist, Oncology, Pediatrics',
    NUCC_367500000X: 'Physician Assistants & Advanced Practice Nursing Providers; Nurse Anesthetist, Certified Registered',
    NUCC_367A00000X: 'Physician Assistants & Advanced Practice Nursing Providers; Advanced Practice Midwife',
    NUCC_367H00000X: 'Physician Assistants & Advanced Practice Nursing Providers; Anesthesiologist Assistant',
    NUCC_372500000X: 'Nursing Service Related Providers; Chore Provider',
    NUCC_372600000X: 'Nursing Service Related Providers; Adult Companion',
    NUCC_373H00000X: 'Nursing Service Related Providers; Day Training/Habilitation Specialist',
    NUCC_374700000X: 'Nursing Service Related Providers; Technician',
    NUCC_3747A0650X: 'Nursing Service Related Providers; Technician, Attendant Care Provider',
    NUCC_3747P1801X: 'Nursing Service Related Providers; Technician, Personal Care Attendant',
    NUCC_374J00000X: 'Nursing Service Related Providers; Doula',
    NUCC_374K00000X: 'Nursing Service Related Providers; Religious Nonmedical Practitioner',
    NUCC_374T00000X: 'Nursing Service Related Providers; Religious Nonmedical Nursing Personnel',
    NUCC_374U00000X: 'Nursing Service Related Providers; Home Health Aide',
    NUCC_376G00000X: 'Nursing Service Related Providers; Nursing Home Administrator',
    NUCC_376J00000X: 'Nursing Service Related Providers; Homemaker',
    NUCC_376K00000X: 'Nursing Service Related Providers; Nurses Aide',
    NUCC_385H00000X: 'Respite Care Facility; Respite Care',
    NUCC_385HR2050X: 'Respite Care Facility; Respite Care, Respite Care Camp',
    NUCC_385HR2055X: 'Respite Care Facility; Respite Care, Respite Care, Mental Illness, Child',
    NUCC_385HR2060X: 'Respite Care Facility; Respite Care, Respite Care, Intellectual and/or Developmental Disabilities, Child',
    NUCC_385HR2065X: 'Respite Care Facility; Respite Care, Respite Care, Physical Disabilities, Child',
    NUCC_390200000X: 'Student, Health Care; Student in an Organized Health Care Education/Training Program',
    NUCC_405300000X: 'Other Service Providers; Prevention Professional',
    ADDRESS_TYPE_CHOICES_POSTAL: 'postal',
    ADDRESS_TYPE_CHOICES_PHYSICAL: 'physical',
    ADDRESS_TYPE_CHOICES_BOTH: 'both',
    SITE_OF_SERVICE_NON_FACILITY: 'Non-facility',
    SITE_OF_SERVICE_FACILITY: 'Facility',
    BILLING_INFO_REQUEST_STATUS_PENDING: 'Pending',
    BILLING_INFO_REQUEST_STATUS_APPROVED: 'Approved',
    BILLING_INFO_REQUEST_STATUS_REFUSED: 'Refused',
    REGISTERED: 'registered',
    PARTIAL: 'partial',
    PRELIMINARY: 'preliminary',
    FINAL: 'final',
    AMENDED: 'amended',
    CORRECTED: 'corrected',
    APPENDED: 'appended',
    CANCELLED: 'cancelled',
    ENTERREDINERROR: 'entered-in-error',
    UNKNOWN: 'unknown',
    PRIMARY_SOURCE_EMR: 'EMR',
    PRIMARY_SOURCE_LAB: 'Lab',
    PRIMARY_SOURCE_PHARMACY: 'Pharmacy',
    EMR_UPLOAD_CATEGORIES_LABORATORY: 'Laboratory',
    EMR_UPLOAD_CATEGORIES_LAB_REVIEW: 'Lab Review',
    EMR_UPLOAD_CATEGORIES_PATIENT_ENCOUNTER: 'Patient Encounter (Optometry)',
    EMR_UPLOAD_CATEGORIES_VISIT_HP: 'Visit H & P',
    EMR_UPLOAD_CATEGORIES_VISIT_SKILLED: 'Visit Skilled',
    EMR_UPLOAD_CATEGORIES_VISIT_ACUTE: 'Visit Acute',
    EMR_UPLOAD_CATEGORIES_VISIT_ROUTINE: 'Visit Routine',
    EMR_UPLOAD_CATEGORIES_VISIT_TELEHEALTH: 'Visit Telehealth',
    EMR_UPLOAD_CATEGORIES_MENTAL_STATUS_EXAM: 'Mental Status Exam',
    EMR_IMPORT_TYPES_PCC: ['admission_records', 'allergies', 'care_plan', 'census', 'census_action_summary', 'census_clinical_action_summary', 'check_daily_census', 'conversations', 'diagnoses', 'documents', 'drugs', 'immunization', 'incidents', 'incidents_historical', 'incidents_only_active', 'insurance', 'medical_professionals', 'mds', 'orders', 'orders_miramar', 'patient_assessments', 'payers', 'progress_notes', 'progress_notes_fall_events_ignite', 'residents_only_new', 'residents_included_insurance_only_active', 'residents_included_inactive', 'residents_all_active', 'vitals', 'height_from_dashboard'],
    EMR_IMPORT_TYPES_NETSMART: ['allergies', 'bed', 'census', 'census_without_bed', 'diagnoses', 'drugs', 'mds', 'orders', 'physicians', 'progress_notes', 'payers', 'patient_notifications', 'residents', 'vitals'],
    EMR_IMPORT_TYPES_MATRIX: ['admission_clinical_assessment', 'allergies', 'baseline_care_plan', 'bed', 'census', 'census_from_face_sheet', 'census_from_face_sheet_only_active', 'diagnoses', 'drugs', 'events', 'face_sheet', 'face_sheet_all', 'face_sheet_all_without_ssn', 'fall_risks', 'hospital', 'hospital_include_physician', 'immunization', 'immunization_v2', 'mds', 'new_admission_clinical_assessment', 'new_baseline_care_plan', 'orders', 'payers', 'payers_pdf', 'progress_notes', 'progress_notes_only_active', 'readmission', 'resident_notes', 'residents', 'residents_from_face_sheet', 'vitals', 'insurance'],
    IMPORT_GROUPS: ['hourly_reports', 'daily_reports', 'daily_full_reports', 'weekly_reports'],
    HL7_ERROR_CODE_SYSTEM: 'SYSTEM',
    HL7_ERROR_CODE_PATIENT_NOT_FOUND: 'PATIENT_NOT_FOUND',
    HL7_ERROR_CODE_FACILITY_NOT_FOUND: 'FACILITY_NOT_FOUND',
    HL7_ERROR_CODE_DEMO_ATTACHMENT: 'DEMO_ATTACHMENT',
    HL7_ERROR_CODE_DEMO_FACILITY_NOT_FOUND: 'DEMO_FACILITY_NOT_FOUND',
    HL7_ERROR_CODE_MESSAGE_FILTERED_OUT: 'MESSAGE_FILTERED_OUT',
    HL7_STATUS_QUEUED: 'QUEUED',
    HL7_STATUS_ERROR: 'ERROR',
    HL7_STATUS_SUCCESS: 'SUCCESS',
    HL7_SOURCE_GAMMAHEALTHCARE: 'GAMMAHEALTHCARE',
    HL7_SOURCE_AIM: 'AIM',
    HL7_SOURCE_MMI: 'MMI',
    HL7_SOURCE_GENOMIC: 'GENOMIC',
    HL7_SOURCE_MEDSCANLAB: 'MEDSCANLAB',
    HL7_SOURCE_MMIEMERGENT: 'MMIEMERGENT',
    HL7_SOURCE_AIMLAB: 'AIMLAB',
    HL7_SOURCE_ASCENSIONRML: 'ASCENSIONRML',
    HL7_SOURCE_LIFESCANLAB: 'LIFESCANLAB',
    HL7_SOURCE_ALPHAMEDICALLAB: 'ALPHAMEDICALLAB',
    HL7_SOURCE_AEL: 'AEL',
    HL7_SOURCE_SOLUTIONS: 'SOLUTIONS',
    HL7_SOURCE_AHA: 'AHA',
    HL7_SOURCE_CERNERCOX: 'CERNERCOX',
    HL7_SOURCE_COREANALYTICS: 'COREANALYTICS',
    HL7_SOURCE_HEARTLAND: 'HEARTLAND',
    HL7_SOURCE_TRINITY: 'TRINITY',
    HL7_SOURCE_PRINCIPLELAB: 'PRINCIPLELAB',
    HL7_SOURCE_CURALAB: 'CURALAB',
    HL7_SOURCE_EMPOWERMELAB: 'EMPOWERMELAB',
    HL7_SOURCE_PICCOLO: 'PICCOLO',
    HL7_SOURCE_MEDONIC: 'MEDONIC',
    HL7_SOURCE_SENIORSCRIPTS: 'SENIORSCRIPTS',
    HL7_SOURCE_REDCROSS: 'REDCROSS',
    HL7_SOURCE_AUBURN: 'AUBURN',
    HL7_SOURCE_GUARDIAN: 'GUARDIAN',
    HL7_SOURCE_WISEDX: 'WISEDX',
    HL7_SOURCE_TENHEALTHCARE: 'TENHEALTHCARE',
    HL7_SOURCE_4MHEALTHLABS: '4MHEALTHLABS',
    HL7_SOURCE_TRIDENT: 'TRIDENT',
    HL7_SOURCE_FMSPHARMACY: 'FMSPHARMACY',
    HL7_SOURCE_NOVOPHARMTAMPA: 'NOVOPHARMTAMPA',
    HL7_SOURCE_ALIXARXWI: 'ALIXARXWI',
    HL7_SOURCE_ALIXARXMOKS: 'ALIXARXMOKS',
    HL7_SOURCE_PHARMERICA: 'PHARMERICA',
    HL7_SOURCE_MERCYONEDMLAB: 'MERCYONEDMLAB',
    HL7_SOURCE_CCLMW: 'CCLMW',
    HL7_SOURCE_UNITEDSCRIPTS: 'UNITEDSCRIPTS',
    HL7_SOURCE_P4CLINICAL: 'P4CLINICAL',
    HL7_SOURCE_WASTAR: 'WAYSTAR',
    HL7_SOURCE_STEDI: 'STEDI',
    HL7_LAB_PROVIDER_ACDX: 'ACDX',
    HL7_MESSAGE_TYPES_ORU: 'ORU',
    HL7_MESSAGE_TYPES_ORM: 'ORM',
    HL7_MESSAGE_TYPES_ADT: 'ADT',
    HL7_MESSAGE_TYPES_RDE: 'RDE',
    HL7_MESSAGE_TYPES_RDS: 'RDS',
    HL7_MESSAGE_TYPES_835: '835',
    HL7_MESSAGE_TYPES_277: '277',
    IMMUNIZATION_REFUSAL_REASONS_IMMUNE: 'immunity',
    IMMUNIZATION_REFUSAL_REASONS_MEDPREC: 'medical precaution',
    IMMUNIZATION_REFUSAL_REASONS_OSTOCK: 'product out of stock',
    IMMUNIZATION_REFUSAL_REASONS_PATOBJ: 'patient objection',
    IMMUNIZATION_REFUSAL_REASONS_PHILISOP: 'philosophical objection',
    IMMUNIZATION_REFUSAL_REASONS_RELIG: 'religious objection',
    IMMUNIZATION_REFUSAL_REASONS_VACEFF: 'vaccine efficacy concerns',
    IMMUNIZATION_REFUSAL_REASONS_VACSAF: 'vaccine safety concerns',
    EMR_UPLOAD_STATUS_QUEUED: 'QUEUED',
    EMR_UPLOAD_STATUS_ERROR: 'ERROR',
    EMR_UPLOAD_STATUS_SUCCESS: 'SUCCESS',
    EMR_UPLOAD_STATUS_ERROR_RESOLVED_IN_OTHER_UPLOAD: 'RESOLVED',
    IMPORT_ORIGIN_AMERICAN_HEALTH_ASSOCIATES: 'AMERICAN_HEALTH_ASSOCIATES',
    IMPORT_ORIGIN_ARSANA: 'ARSANA',
    IMPORT_ORIGIN_AUBURN: 'AUBURN',
    IMPORT_ORIGIN_AYVA: 'AYVA',
    IMPORT_ORIGIN_BIOTECH_XRAY: 'BIOTECH_XRAY',
    IMPORT_ORIGIN_BST: 'BST',
    IMPORT_ORIGIN_CERNER_COX: 'CERNER_COX',
    IMPORT_ORIGIN_FMSPHARMACY: 'FMSPHARMACY',
    IMPORT_ORIGIN_GAMMA: 'GAMMA',
    IMPORT_ORIGIN_GUARDIAN: 'GUARDIAN',
    IMPORT_ORIGIN_HE: 'HE',
    IMPORT_ORIGIN_HEARTLAND_HEALTH_LABORATORIES: 'HEARTLAND_HEALTH_LABORATORIES',
    IMPORT_ORIGIN_HL7: 'HL7',
    IMPORT_ORIGIN_LTC_RX: 'LTC_RX',
    IMPORT_ORIGIN_MATRIX: 'MATRIX',
    IMPORT_ORIGIN_MOBILEX: 'MOBILEX',
    IMPORT_ORIGIN_NETSMART: 'NETSMART',
    IMPORT_ORIGIN_NOEMRMATRIX: 'NOEMRMATRIX',
    IMPORT_ORIGIN_NOEMR_PCC_ADMISSION: 'NOEMR_PCC_ADMISSION',
    IMPORT_ORIGIN_NOEMR_PCC_LISTING: 'NOEMR_PCC_LISTING',
    IMPORT_ORIGIN_NOVOPHARMTAMPA: 'NOVOPHARMTAMPA',
    IMPORT_ORIGIN_PCC: 'PCC',
    IMPORT_ORIGIN_PHARMERICA: 'PHARMERICA',
    IMPORT_ORIGIN_REDCROSS: 'REDCROSS',
    IMPORT_ORIGIN_REVOLUTION_EHR: 'REVOLUTION_EHR',
    IMPORT_ORIGIN_SENIORSCRIPTS: 'SENIORSCRIPTS',
    IMPORT_ORIGIN_SRFAX: 'SRFAX',
    IMPORT_ORIGIN_TRIDENT: 'TRIDENT',
    IMPORT_ORIGIN_UNITEDSCRIPTS: 'UNITEDSCRIPTS',
    IMPORT_ORIGIN_WELLSKY: 'WELLSKY',
    ACCOUNT_STATUS_NOT_CHECKED: 'Not checked yet',
    ACCOUNT_STATUS_ACTIVE: 'Active',
    ACCOUNT_STATUS_BAD_CREDENTIALS: 'Bad Credentials',
    ACCOUNT_STATUS_NEEDS_PASSWORD_CHANGE: 'Needs password change',
    ACCOUNT_STATUS_PASSWORD_CHANGE_FAILED: 'Password change failed',
    ACCOUNT_STATUS_CAPTCHA_REQUIRED: 'Captcha found while importing',
    ACCOUNT_STATUS_LOCK_OUT: 'Account locked out',
    CAPTCHA_STATUS_NO_CAPTCHA: 'No captcha',
    CAPTCHA_STATUS_ACTIVE: 'Captcha active',
    CAPTCHA_STATUS_SIMPLE: 'Puzzle captcha',
    CAPTCHA_STATUS_COLORED: 'Colored captcha',
    CAPTCHA_STATUS_Q: 'Q captcha',
    CAPTCHA_STATUS_IMPOSSIBLE: 'Impossible captcha',
    UPLOAD_REPORT_documents: 'Documents',
    UPLOAD_REPORT_revolution: 'Revolution',
    UPLOAD_REPORT_lab_report_documents: 'Lab Report under Documents',
    UPLOAD_REPORT_lab_report_with_review: 'Lab Report with Review',
    UPLOAD_REPORT_lab_review: 'Only lab review',
    UPLOAD_REPORT_lab_reports: 'Lab Report under Results in EMR',
    UPLOAD_REPORT_sftp_pdf: 'Sftp PDF',
    UPLOAD_REPORT_sftp_hl7: 'Lab Order',
    UPLOAD_REPORT_visits: 'Visits',
    UPLOAD_REPORT_sftp_pdf_local: 'SFTP PDF Local ',
    UPLOAD_REPORT_sftp_elation_hl7: 'SFTP Elation HL7',
    UPLOAD_REPORT_e_prescription: 'E-Prescription',
    UPLOAD_REPORT_mllp_cerner_hl7: 'MLLP Cerner HL7',
    UPLOAD_REPORT_cerner: 'Cerner',
    UPLOAD_REPORT_cerner_lacie: 'Cerner Lacie',
    UPLOAD_REPORT_cerner_community_view: 'Cerner Community View',
    IMPORT_FILE_UPLOAD_ORIGIN_ARSANA: 'supported',
    IMPORT_FILE_UPLOAD_ORIGIN_WELLSKY: 'supported',
    IMPORT_FILE_UPLOAD_ORIGIN_NETSMART: 'supported',
    IMPORT_FILE_UPLOAD_ORIGIN_NOEMRMATRIX: 'supported',
    IMPORT_FILE_UPLOAD_ORIGIN_PCC: 'not_upported',
    IMPORT_FILE_UPLOAD_ORIGIN_MATRIX: 'not_upported',
    SYNC_STATUS_QUEUED: 'Queued',
    SYNC_STATUS_DOWNLOAD_IN_PROGRESS: 'Download in progress',
    SYNC_STATUS_IN_PROGRESS: 'In progress',
    SYNC_STATUS_INTERRUPTED: 'Interrupted',
    SYNC_STATUS_DOWNLOAD_INTERRUPTED: 'Download interrupted',
    SYNC_STATUS_DOWNLOAD_FINISHED: 'Download,finished',
    SYNC_STATUS_FINISHED: 'Finished',
    SYNC_STATUS_INTERRUPTED_BY_CAPTCHA: 'Found captcha.',
    INDICATION_TYPE_PRIMARY: 'Primary',
    INDICATION_TYPE_SECONDARY: 'Secondary',
    INDICATION_TYPE_OFF_LABEL: 'Off Label',
    INDICATION_TYPE_MISSING: 'Missing',
    ACQUISITION_COMMUNITY_ACQUIRED: 'Community Acquired',
    ACQUISITION_NOSOCOMIAL: 'Nosocomial',
    PRODUCT_TYPE_CATEGORY_1: 'ingested or inserted treatments',
    PRODUCT_TYPE_CATEGORY_2: 'topical treatments',
    PRODUCT_TYPE_CATEGORY_3: 'other',
    PCC_INITIAL_IMPORTS: ['allergies', 'admission_records', 'payers', 'vitals', 'insurance', 'progress_notes_fall_events', 'diagnoses', 'drugs', 'orders', 'incidents', 'incidents_historical', 'progress_notes', 'immunization'],
    IMPORT_VALIDATION_TYPES: ['PERMISSION_CHECK', 'CENSUS_CHECK', 'PATIENT_IMPORT', 'PATIENT_LIST_CHECK', 'DATA_IMPORT'],
    MATRIX_INITIAL_IMPORTS: ['progress_notes', 'drugs', 'orders', 'vitals', 'diagnoses', 'events', 'payers', 'immunization', 'immunization_v2', 'face_sheet'],
    LAB_SHIPMENT_STATUS_ORDERED: 'Ordered',
    LAB_SHIPMENT_STATUS_ORDER_CONFIRMED: 'Order Confirmed',
    LAB_SHIPMENT_STATUS_SHIPPED_TO_FACILITY: 'Shipped to Facility',
    LAB_SHIPMENT_STATUS_COLLECTION_STARTED: 'Collection Started',
    LAB_SHIPMENT_STATUS_COLLECTION_FINISHED: 'Collection Finished',
    LAB_SHIPMENT_STATUS_SHIPPED_TO_LAB: 'Shipped to Lab',
    LAB_SHIPMENT_STATUS_ACCESSION_STARTED: 'Accession Started',
    LAB_SHIPMENT_STATUS_ACCESSION_FINISHED: 'Results Pending',
    LAB_SHIPMENT_STATUS_RESULTED: 'Resulted',
    LAB_SHIPMENT_STATUS_CANCELED: 'Canceled',
    ORDERS_REPORT_SERVICE_TYPES: {'Wound': 'wound', 'Enteral': 'enteral', 'Ostomy': 'ostomy', 'Catheter': 'catheter'},
    GENDER_F: 'Female',
    GENDER_M: 'Male',
    GENDER_A: 'Ambiguous',
    GENDER_N: 'Not applicable',
    GENDER_O: 'Other',
    GENDER_U: 'Unknown',
    ADVANCE_DIRECTIVE_CODE_N: '',
    ADVANCE_DIRECTIVE_CODE_DNR: 'DNR',
    ADVANCE_DIRECTIVE_CODE_FULL: 'FULL',
    NAME_USE_A: 'Artist/Stage',
    NAME_USE_ABC: 'Alphabetic',
    NAME_USE_ASGN: 'assigned',
    NAME_USE_C: 'License',
    NAME_USE_I: 'Indigenous/Tribal',
    NAME_USE_IDE: 'Ideographic',
    NAME_USE_L: 'Legal',
    NAME_USE_OR: 'official registry',
    NAME_USE_P: 'pseudonym',
    NAME_USE_PHON: 'phonetic',
    NAME_USE_R: 'Religious',
    NAME_USE_SNDX: 'Soundex',
    NAME_USE_SRCH: 'search',
    NAME_USE_SYL: 'Syllabic',
    NAME_QUALIFIER_AC: 'academic',
    NAME_QUALIFIER_AD: 'adopted',
    NAME_QUALIFIER_BR: 'birth',
    NAME_QUALIFIER_BL: 'billable',
    NAME_QUALIFIER_CL: 'callme',
    NAME_QUALIFIER_IN: 'initial',
    NAME_QUALIFIER_NB: 'nobility',
    NAME_QUALIFIER_PR: 'professional',
    NAME_QUALIFIER_SP: 'spouse',
    NAME_QUALIFIER_TITLE: 'title',
    NAME_QUALIFIER_VV: 'voorvoegsel',
    VISIT_TYPE_INTERVAL: 'Interval',
    VISIT_TYPE_FIRST_ADMISSION: 'H&P',
    VISIT_TYPE_SKILLED: 'Skilled',
    VISIT_TYPE_EPISODIC: 'Episodic',
    VISIT_TYPE_TELEHEALTH: 'Telehealth',
    SUBJECT_TYPE_PATIENT: 'SUBJECT_TYPE_PATIENT',
    SUBJECT_TYPE_PERSON: 'SUBJECT_TYPE_PERSON',
    PI_MEASURE_TYPE_ATHIM: 'Access To Health Information Measure Report',
    PI_MEASURE_TYPE_TOCM: 'Transition Of Care Measure Report',
    PI_MEASURE_TYPE_RARM: 'Receive And Reconcile Measure Report',
    DEVICE_ACTION_CHOICES: {'implanted': 'Implanted', 'explanted': 'Explanted', 'manipulated': 'Manipulated'},
    PSYCHOTROPIC_GROUP_PSYCHOSIS: 'Psychosis',
    PSYCHOTROPIC_GROUP_BIPOLAR: 'Bipolar',
    PSYCHOTROPIC_GROUP_DEPRESSION: 'Depression',
    PSYCHOTROPIC_GROUP_ANXIETY: 'Anxiety',
    PSYCHOTROPIC_GROUP_INSOMNIA: 'Insomnia',
    PSYCHOTROPIC_GROUP_DEMENTIA: 'Dementia',
    PSYCHOTROPIC_GROUP_PARKINSON_S: 'Parkinson\'s',
    PSYCHOTROPIC_GROUP_EPILEPSY: 'Epilepsy',
    PSYCHOTROPIC_GROUP_HALLUCINATIONS: 'Hallucinations',
    PSYCHOTROPIC_TREATMENT_PSYCHOSIS: 'Anti-Psychotics',
    PSYCHOTROPIC_TREATMENT_BIPOLAR: 'Mood Stabilizers',
    PSYCHOTROPIC_TREATMENT_DEPRESSION: 'SSRI / SNRI / TCA',
    PSYCHOTROPIC_TREATMENT_ANXIETY: 'Anxiolytics',
    PSYCHOTROPIC_TREATMENT_INSOMNIA: 'Sedatives / Hypnotics',
    PSYCHOTROPIC_TREATMENT_DEMENTIA: 'Cognitive Enhancers',
    PSYCHOTROPIC_TREATMENT_PARKINSON_S: 'Anti-Parkinsonian',
    PSYCHOTROPIC_TREATMENT_EPILEPSY: 'Seizure / Convulsions',
    PSYCHOTROPIC_TREATMENT_HALLUCINATIONS: 'Anti-Hallucination',
    REPORT_CHOICES: {'PCM_CONDITION_CHANGE': 'PCM Condition Change report', 'ICD_ORDERS': 'Icd codes with orders', 'WOUND_DOCUMENT_PARSE': 'Parsing patient document containing information about wounds, saves information for later use'},
    REPORT_QUEUE_STATUS_CHOICES: {'REPORT_STATUS_QUEUED': 'Queued', 'REPORT_STATUS_IN_PROGRESS': 'In progress', 'REPORT_STATUS_FINISHED': 'Finished', 'REPORT_STATUS_INTERRUPTED': 'Interrupted', 'REPORT_STATUS_CANCELED': 'Canceled'},
    STATUS_OBSERVATION_FUNCTIONAL: 'Functional',
    STATUS_OBSERVATION_COGNITIVE: 'Cognitive',
    M: 'Minute(s)',
    H: 'Hour(s)',
    D: 'Day(s)',
    W: 'Week(s)',
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday',
    Sun: 'Sunday',
    WD: 'Weekdays',
    WE: 'Weekend',
    AM: 'Morning',
    PM: 'Evening',
    SHIFT: '8-hour shift',
    PERMISSION_READ_GENERAL: 'View General Data',
    PERMISSION_READ_SEARCH: 'Search',
    PERMISSION_READ_ADMIN_DASHBOARD: 'View Arsana Views Dashboard',
    PERMISSION_READ_CLINICAL_OVERSIGHT: 'View Clinical Oversight',
    PERMISSION_WRITE_PATIENT_CLINICAL: 'Edit Patient Clinical Data',
    PERMISSION_WRITE_PATIENT_ADMIN: 'Edit Patient Admin Data',
    PERMISSION_READ_CENSUS_OVERSIGHT: 'View Census Oversight',
    PERMISSION_READ_CENSUS_GENERAL: 'View General Census Data',
    PERMISSION_READ_CENSUS_MAP: 'View Census Map',
    PERMISSION_READ_INFECTION_MAP: 'View Infection Map',
    PERMISSION_READ_COVID_MAP: 'View COVID Map',
    PERMISSION_READ_IMMUNIZATIONS: 'View Immunizations',
    PERMISSION_WRITE_IMMUNIZATIONS: 'Edit Immunizations',
    PERMISSION_READ_LAB_INFECTION_CONTROL: 'View Lab Infection Control',
    PERMISSION_READ_LAB_TESTING: 'View Lab Testing',
    PERMISSION_WRITE_LAB_TESTING: 'Edit Lab Testing',
    PERMISSION_READ_LAB_SHIPMENT_STATUS: 'View Lab Shipment Status',
    PERMISSION_WRITE_LAB_SHIPMENT_STATUS: 'Edit Lab Shipment Status',
    PERMISSION_READ_COVID_TESTING: 'View COVID Testing',
    PERMISSION_READ_LAB_SHIPMENTS: 'View Lab Shipments',
    PERMISSION_READ_LAB_COLLECTION: 'View Lab Collection',
    PERMISSION_READ_LAB_ACCESSION: 'View Lab Accession',
    PERMISSION_READ_LAB_WORKLIST: 'View Lab Worklist',
    PERMISSION_READ_TRENDS: 'View Trends',
    PERMISSION_READ_CENSUS_TRENDS: 'View Census Trends',
    HOMEPAGE_CENSUS_TRENDS: 'Census Trends as Homepage',
    PERMISSION_READ_CLINICAL_GENERAL: 'View General Clinical Data',
    PERMISSION_READ_PATIENTS: 'View Patient Related Information',
    PERMISSION_READ_PATIENTS_ASSIGNED: 'View Assigned Patients',
    PERMISSION_READ_PATIENTS_ACO: 'View ACO Patients',
    PERMISSION_READ_PATIENTS_ALL: 'View All Patients',
    PERMISSION_WRITE_PATIENTS: 'Edit Patients',
    PERMISSION_READ_PATIENT_LIST: 'View Patient List',
    PERMISSION_READ_PATIENT_TIMELINE: 'View Patient Timeline',
    PERMISSION_READ_PATIENT_NOTES: 'View Patient Notes',
    PERMISSION_WRITE_PATIENT_NOTES: 'Edit Patient Notes',
    PERMISSION_READ_INFECTION_ENTRY: 'View Infection Entry',
    PERMISSION_WRITE_INFECTION_ENTRY: 'Edit Infection Entry',
    PERMISSION_READ_LAB_REPORTS: 'Read Lab Reports',
    PERMISSION_READ_LAB_REPORT_LIST: 'View Lab Report List',
    PERMISSION_READ_MEDICATION_LIST: 'View Medication List',
    PERMISSION_READ_MEDICATION_INTERACTIONS: 'View Medication Interactions',
    PERMISSION_READ_PATIENT_ASSESSMENTS: 'View Patient Assessments',
    PERMISSION_READ_UPLOADED_REPORTS: 'View Uploaded Reports',
    PERMISSION_WRITE_UPLOADED_REPORTS: 'Download Uploaded Reports',
    PERMISSION_WRITE_UPLOAD_REPORTS: 'Upload Report',
    PERMISSION_READ_USER: 'View User',
    PERMISSION_READ_USER_LIST: 'View User List',
    PERMISSION_WRITE_USER: 'Edit User',
    PERMISSION_WRITE_USER_PASSWORD: 'Edit User Password',
    PERMISSION_READ_LOCATIONS: 'View Locations',
    PERMISSION_WRITE_LOCATIONS: 'Edit Locations',
    PERMISSION_READ_BEDS: 'View Beds',
    PERMISSION_WRITE_BEDS: 'Edit Beds',
    PERMISSION_READ_BED_ASSOCIATIONS: 'View Bed Origin Associations',
    PERMISSION_WRITE_BED_ASSOCIATIONS: 'Edit Bed Origin Associations',
    PERMISSION_WRITE_DRUG_CATEGORIES: 'Edit Drug Categories',
    PERMISSION_WRITE_PSYCHOTROPIC_GROUPS: 'Edit Psychotropic Groups',
    PERMISSION_WRITE_DIAGNOSIS_CATEGORIES: 'Edit Diagnosis Categories',
    PERMISSION_APPROVE_BILLING_INFO_REQUESTS: 'Approve Billing Info Requests',
    PERMISSION_SEND_BILLING_INFO_REQUESTS: 'Send Billing Info Requests',
    PERMISSION_WRITE_FLOOR_PLANS: 'Edit floor plans',
    PERMISSION_READ_EVENTS: 'View Events',
    PERMISSION_READ_ORDERS: 'View Orders',
    PERMISSION_READ_RESIDENT_NOTES: 'View Resident Notes',
    PERMISSION_READ_PROGRESS_NOTES: 'View Progress Notes',
    PERMISSION_READ_PATIENT_DOCUMENTS: 'View Patient Documents',
    PERMISSION_WRITE_PATIENT_DOCUMENTS: 'Upload Patient Documents',
    PERMISSION_READ_PATIENT_ABSTRACT: 'View Patient Abstract',
    PERMISSION_READ_PATIENT_HIE: 'View Patient HIE',
    PERMISSION_READ_ORGANIZATION: 'View Organization',
    PERMISSION_WRITE_ORGANIZATION: 'Edit Organization',
    PERMISSION_READ_FACILITY: 'View Facility',
    PERMISSION_WRITE_FACILITY: 'Edit Facility',
    PERMISSION_READ_IMPORT: 'View Import',
    PERMISSION_READ_HL7_MESSAGES: 'View HL7 Messages',
    PERMISSION_WRITE_HL7_MESSAGES: 'Update/Reprocess HL7 Messages',
    PERMISSION_WRITE_EMR_UPLOADS: 'Update/Reprocess EMR Uploads',
    PERMISSION_READ_EMR_UPLOADS: 'View EMR Uploads',
    PERMISSION_WRITE_IMPORT: 'Edit Import',
    PERMISSION_READ_LOGS: 'View Logs',
    PERMISSION_READ_IMPORT_LOGS: 'View Import Logs',
    PERMISSION_EXPERIMENTAL_FEATURES: 'Experimental features',
    PERMISSION_READ_COACHING_INFECTION_ADMINISTRATION: 'View Infection Administration Coaching',
    PERMISSION_READ_TRENDS_EXPERIMENTAL_FEATURES: 'View Trends experimental features',
    PERMISSION_READ_ANTIBIOTICS: 'View antibiotics',
    PERMISSION_READ_TASKS: 'View tasks and notes',
    PERMISSION_WRITE_TASKS: 'Write tasks and notes',
    PERMISSION_APPROVE_TASKS: 'Approve Tasks as Physician',
    PERMISSION_READ_FACILITY_TASKS: 'View facility tasks',
    PERMISSION_WRITE_PROGRESS_NOTE: 'Write Progress Note',
    PERMISSION_READ_TASK_PROPOSALS: 'View task proposals',
    PERMISSION_READ_E_PRESCRIBING_WORKLIST: 'View E-prescribing Worklist',
    PERMISSION_READ_TASK_SUMMARY: 'View summary of tasks and notes',
    PERMISSION_READ_TASK_HISTORY: 'Download task history',
    PERMISSION_READ_CCM_BILLING: 'View VCM Billing',
    PERMISSION_READ_VCM_BILLING_AUDIT: 'View VCM Billing Audit',
    PERMISSION_DOWNLOAD_VCM_BILLING_TASK_REPORTS: 'Download VCM Billing Task Reports',
    PERMISSION_DOWNLOAD_VCM_BILLING_SUMMARY_BY_FACILITY: 'Download VCM Billing Summary by Facility',
    PERMISSION_READ_VISIT_BILLING_AUDIT: 'View Visit Billing Audit',
    PERMISSION_READ_VISIT_BILLING: 'View Visit Billing',
    PERMISSION_READ_PRODUCTIVITY_REPORT: 'View Productivity Report',
    PERMISSION_READ_VISITS: 'View Visits',
    PERMISSION_WRITE_VISITS: 'Edit Visits',
    PERMISSION_READ_NEXT_VISIT_SCHEDULER: 'View Next Visit Scheduler',
    PERMISSION_WRITE_EPISODIC_PROPOSALS: 'Edit Episodic Visit Proposals',
    PERMISSION_WRITE_FACE_TO_FACE_PROPOSALS: 'Edit Face to Face Visit Proposals',
    PERMISSION_READ_SCHEDULER_WORKLIST: 'View Scheduler Worklist',
    PERMISSION_READ_PHYSICIAN_TEAMS: 'View physician teams',
    PERMISSION_READ_BILLING_CODES: 'View Billing Codes',
    PERMISSION_WRITE_BILLING_CODES: 'Edit Billing Codes',
    PERMISSION_READ_TASK_TYPES: 'View Task Types',
    PERMISSION_WRITE_TASK_TYPES: 'Edit Task Types',
    PERMISSION_WRITE_NOTIFICATION_SETTINGS: 'Edit Notification settings',
    PERMISSION_RECEIVE_FACILITY_NOTIFICATIONS: 'Receive Facility Notifications',
    PERMISSION_FILTER_ASSIGNED_PATIENTS: 'Filter for assigned patients',
    PERMISSION_FILTER_ADVANCED: 'Advanced filtering options',
    PERMISSION_FOLLOW_PATIENTS: 'Follow Patients',
    RESTRICTION_PATIENT_DETAILS_CCM_ELIGIBLE: 'Restricted Patient View besides CCM-eligible Patients',
    RESTRICTION_PATIENT_DETAILS_ASSIGNED: 'Restricted Patient View besides assigned Patients',
    RESTRICTION_NURSE: 'Restricted patient view for the nurses stations',
    RESTRICTION_HIE_PHARMACY: 'Restricted patient view for HIE pharmacy',
    RESTRICTION_LAB_HELIX: 'Restriction to only access HELIX Labs',
    RESTRICTION_LAB_ACDX: 'Restriction to only access ACCESS DX Labs',
    RESTRICTION_PATIENT_LABS: 'Restriction to only view Labs under Patient details',
    RESTRICTION_PATIENT_NO_DETAILS: 'Restriction not to see Patient details',
    RESTRICTION_ACO: 'Restriction to only access ACO Views',
    RESTRICTION_BILLING_DOWNLOAD_SUSPENDED: 'Restriction - Billing Download Suspended',
    RESTRICTION_PROBATION: 'Restriction Probation',
    PERMISSION_PATIENT_DETAILS: 'Patient View Access overriding restrictions',
    PERMISSION_READ_INVOICES: 'Read CCM Invoices',
    PERMISSION_WRITE_INVOICES: 'Create or delete CCM Invoices',
    PERMISSION_READ_IMPORT_ACCOUNTS: 'View Import Accounts',
    PERMISSION_READ_IMPORT_ACCOUNTS_LIST: 'View Import Accounts List',
    PERMISSION_WRITE_IMPORT_ACCOUNTS: 'Edit Import Accounts',
    PERMISSION_READ_HE: 'Read Health Endeavors',
    PERMISSION_ALL_CRITICAL_LAB_EMAIL_NOTIFICATION_RECEIVE: 'Receive emails about all critical Lab',
    PERMISSION_VISIT_BILLING: 'Upload visits and reviews to EMR',
    PERMISSION_READ_CENSUS_HISTORY: 'View Census History',
    PERMISSION_WRITE_CENSUS_HISTORY: 'Edit Census History',
    PERMISSION_MERGE_PATIENTS: 'Merge patiens',
    PERMISSION_READ_USER_PROPOSALS: 'Read User Proposals',
    PERMISSION_READ_PATIENT_PROPOSALS: 'Read Patient Proposals',
    PERMISSION_READ_FACILITY_PROPOSALS: 'Read Facility Proposals',
    PERMISSION_WRITE_USER_PROPOSALS: 'Write User Proposals',
    PERMISSION_WRITE_PATIENT_PROPOSALS: 'Write Patient Proposals',
    PERMISSION_WRITE_FACILITY_PROPOSALS: 'Write Facility Proposals',
    PERMISSION_READ_GROUPS: 'Read Groups',
    PERMISSION_WRITE_GROUPS: 'Write Groups',
    PERMISSION_READ_IMPORT_ERRORS: 'Read import errors',
    PERMISSION_READ_MISSING_IMPORT_PERMISSIONS: 'Ability to view the missing import account permissions for a facility',
    PERMISSION_REQUEST_PERSON_MERGE: 'Request person merge',
    PERMISSION_CONFIRM_PERSON_PROPOSAL: 'Confirm person proposal',
    PERMISSION_WRITE_PERSONS: 'Write persons',
    PERMISSION_READ_NOTIFICATIONS: 'Read notifications',
    PERMISSION_READ_SNF_REPORT: 'Read SNF Report',
    RESTRICTION_CONTENT_TYPE_LAB: 'Restriction content type lab',
    PERMISSION_READ_MEDICAL_PROFESSIONALS: 'Read medical professionals',
    PERMISSION_WRITE_MEDICAL_PROFESSIONALS: 'Write medical professionals',
    PERMISSION_READ_PATIENT_INFORMATION_AUDIT: 'Permission read patient information audit',
    PERMISSION_READ_TEAM_NOTES: 'Permission read team notes',
    PERMISSION_READ_MEDICAL_SPECIALTIES: 'Permission read medical specialties',
    PERMISSION_READ_IMPORT_PRESET: 'Permission allows to use import presets',
    PERMISSION_WRITE_IMPORT_PRESET: 'Permission allow to create, update and delete import presets',
    PERMISSION_REQUEST_ACCESS_PERMISSIONS: 'Request Patient Access',
    PERMISSION_DECIDE_ACCESS_PERMISSIONS: 'Administer Patient Access',
    PERMISSION_READ_PATIENT_RISK_SUMMARY: 'Read patient risk summary',
    PERMISSION_READ_INITIAL_IMPORT: 'Do initial import',
    PERMISSION_READ_MEDICAL_PROGRAM_BILLING: 'Read medical program (remote monitoring) billing',
    PERMISSION_WRITE_MEDICAL_PROGRAMS: 'Edit Medical Programs',
    PERMISSION_READ_REHOSPITALIZATION: 'View Rehospitalization',
    PERMISSION_DOWNLOAD_VISIT_BILLING_EXTENDED_REPORTS: 'Download Visit Billing Extended Reports',
    PERMISSION_FILTER_PATIENT_SPECIALTY: 'Filter patient details by Specialty',
    PERMISSION_UPDATE_CENSUS: 'Update Facility Census Status Settings',
    PERMISSION_READ_ORDERS_REPORT: 'View Part B Report',
    PERMISSION_WRITE_SYSTEM_ALERTS: 'Create System Alerts',
    PERMISSION_READ_SYSTEM_ALERTS: 'Read System Alerts',
    PERMISSION_WRITE_PATIENT_CMRNS: 'Write patient CMRNs',
    PERMISSION_READ_QUESTIONNAIRES: 'Read Questionnaires',
    PERMISSION_WRITE_CUSTOMERS: 'Write Customers',
    PERMISSION_READ_APPLICATIONS: 'Read Applications',
    PERMISSION_WRITE_APPLICATIONS: 'Write Applications',
    PERMISSION_READ_STATUS_OBSERVATIONS: 'Read Status Observations',
    PERMISSION_WRITE_STATUS_OBSERVATIONS: 'Write Status Observations',
    PERMISSION_WRITE_DIAGNOSES: 'Write Diagnosis',
    PERMISSION_WRITE_EVENTS: 'Write Events',
    PERMISSION_WRITE_CAPTCHA: 'Read/Write Captcha',
    PERMISSION_READ_CLINICAL_DOCUMENTS: 'Read Clinical Documents',
    PERMISSION_WRITE_CLINICAL_DOCUMENTS: 'Write Clinical Documents',
    PERMISSION_READ_PATIENTS_AS_REPRESENTATIVE: 'Read Patients as Representative',
    PERMISSION_READ_PATIENT_REPRESENTATIVES: 'Read Patient Representatives',
    PERMISSION_CREATE_USER_FROM_PATIENT: 'Create User From Patient',
    PERMISSION_WRITE_PATIENT_REPRESENTATIVE: 'Create Patient Representative',
    PERMISSION_READ_ACCESS_LOG: 'Read Access Log',
    PERMISSION_WRITE_CDS: 'Edit CDS',
    PERMISSION_READ_CDS: 'Read CDS',
    PERMISSION_GENERATE_QRDA: 'Generate QRDA I & III',
    PERMISSION_READ_PI_MEASURES: 'Generate Promoting Interoperability Measure reports',
    PERMISSION_READ_EHI_EXPORT: 'Generate EHI Export',
    PERMISSION_TRANSMIT_CDA: 'Transmit CDA',
    PERMISSION_IMPORT_PATIENT_FROM_CDA: 'Import Patient information from CDA files',
    PERMISSION_READ_CDA: 'Read CDA',
    PERMISSION_READ_PATIENT_INSURANCE: 'Read patient insurance settings',
    PERMISSION_WRITE_PATIENT_INSURANCE: 'Modify patient insurance settings',
    PERMISSION_PLACEHOLDER: 'Placeholder',
    PERMISSION_READ_UNMATCHED_PATIENT_ACTIONS: 'Read Unmatched Patient Actions',
    PERMISSION_WRITE_UNMATCHED_PATIENT_ACTIONS: 'Write Unmatched Patient Actions',
    PERMISSION_READ_SYSTEM_OPERATIONS: 'Read System Operations',
    PERMISSION_WRITE_PERSON_PROPOSALS: 'Write Person Proposals',
    PERMISSION_READ_ANTIPSYCHOTICS: 'View Antipsychotics',
    PERMISSION_READ_LABELS: 'Read Labels',
    PERMISSION_WRITE_LABELS: 'Write Labels',
    PERMISSION_WRITE_LEVEL_OF_CARE_PAYERS: 'Write Level of Care Payers',
    PERMISSION_READ_LEVEL_OF_CARE_PAYERS: 'Read Level of Care Payers',
    PERMISSION_GENERATE_AI_SUMMARY: 'Generate AI Summary',
    PERMISSION_BATCH_DELETE_TREATMENT_HISTORY: 'Batch Delete Treatment History',
    PERMISSION_INCLUDE_ALL_ARSANA_MEDS: 'Include All Arsana Medications',
    PERMISSION_READ_CHARGE_AMOUNT_SETTINGS: 'Read Charge Amount Settings',
    PERMISSION_WRITE_CHARGE_AMOUNT_SETTINGS: 'Edit Charge Amount Settings',
    PERMISSION_READ_CLAIMS: 'Read Claims',
    PERMISSION_WRITE_CLAIMS: 'Edit Claims',
    PERMISSION_READ_BILLING_GROUP: 'Read Billing Group',
    PERMISSION_WRITE_BILLING_GROUP: 'Edit Billing Group',
    PERMISSION_READ_MEDICAID_PRICE_SETTINGS: 'Read global Medicaid Price Settings',
    PERMISSION_WRITE_MEDICAID_PRICE_SETTINGS: 'Edit global Medicaid Price Settings',
    PERMISSION_READ_ELIGIBILITY_REQUESTS: 'Read Insurance Verifications',
    PERMISSION_WRITE_ELIGIBILITY_REQUESTS: 'Trigger Insurance Verifications',
    PERMISSION_READ_ERX_ORIGINS: 'Read eRx Origins',
    PERMISSION_USE_DICTATION: 'Permission to Use Dictation',
    PERMISSION_USE_ORDER_DICTATION: 'Permission to Use Order Dictation',
    PERMISSION_READ_IMPORT_DOCUMENT: 'Convert uploaded documents to imports',
    PERMISSION_WRITE_FAX_NUMBER: 'Edit Fax Number',
    PERMISSION_WRITE_ERX_ORIGINS: 'Write eRx Origins',
    PERMISSION_DOWNLOAD_FILE: 'Download files from the file endpoint',
    PERMISSION_READ_REPORT_QUEUE: 'Read access for the report viewset',
    PERMISSION_WRITE_REPORT_QUEUE: 'Write access for the report viewset',
    PERMISSION_READ_REMITS: 'Read Remits',
    PERMISSION_READ_RCM: 'Read RCM',
    PERMISSION_WRITE_WOUNDS: 'Write Wounds',
    PERMISSION_READ_WOUNDS: 'Read Wounds',
    PERMISSION_READ_PART_B_WORKLIST_LIST: 'View Part B Worklist List',
    ROLE_SUPER_ADMIN: 'Super Admin',
    ROLE_TASK_HISTORY: 'Download Task History',
    ROLE_DEMO: 'Demo',
    ROLE_EXPERIMENTAL_FEATURES: 'Experimental Features',
    ROLE_EXPERIMENTAL_ONLY: 'Experimental Features (developers)',
    ROLE_VISIT_PLANNER: 'Visit Planner',
    ROLE_MAINTENANCE: 'Maintenance',
    ROLE_LOCATIONS_SETUP: 'Locations Setup',
    ROLE_IMPORT: 'Import',
    ROLE_ENTRY_PROPOSALS: 'Entry Proposals',
    ROLE_USER_PROPOSALS: 'User Proposals',
    ROLE_PATIENT_PROPOSALS: 'Patient Proposals',
    ROLE_FACILITY_PROPOSALS: 'Facility Proposals',
    ROLE_COMMUNICATION: 'Communication',
    ROLE_FACILITY_TASKS: 'Facility Tasks',
    ROLE_ORGANIZATION_ADMIN: 'Organization - Admin (w. Devices)',
    ROLE_ORGANIZATION_ADMIN_LIGHT: 'Organization - Admin',
    ROLE_OFFICE: 'Organization - Office',
    ROLE_NURSING_DIRECTOR: 'Organization - Nursing Director',
    ROLE_ADMISSION_COORDINATOR: 'Organization - Admission Coordinator',
    ROLE_ARSANAMD_NS: 'ArsanaMD NS',
    ROLE_GATEWAY: 'Device/Gateway',
    ROLE_MR: 'Medical Records Admin',
    ROLE_CARE_SURVEILLANCE_I: 'Care Surveillance I',
    ROLE_ARSANAMD_NURSING: 'ArsanaMD Nursing',
    ROLE_ARSANA_LITE: 'Arsana Lite',
    ROLE_ACO_CLINICAL_OVERSIGHT: 'ACO - Clinical Oversight',
    ROLE_FREEMIUM_ALL_USER: 'Freemium All - User',
    ROLE_INFECTION_SURVEILLANCE: 'Infection Surveillance',
    ROLE_FREEMIUM_NURSING_DIRECTOR: 'Freemium - Nursing Director',
    ROLE_ACO_CALL_FREEMIUM: 'ACO/CALL Freemium',
    ROLE_BROADSTREET: 'BroadStreet',
    ROLE_SED_TESTING: 'SED Testing',
    ROLE_ARSANA_PHYSICIAN_ENCOUNTERS: 'Arsana Physician Encounters',
    ROLE_PHYSICIAN: 'Physician',
    ROLE_PHYSICIAN_CCM_ONLY: 'Physician - CCM-only',
    ROLE_PHYSICIAN_NO_EMR: 'Physician without EMR',
    ROLE_PHYSICIAN_BILLING_MANAGER: 'Billing Manager',
    ROLE_PHYSICIAN_OUTSIDE_BILLING: 'Outside Billing',
    ROLE_PHYSICIAN_VISIT_BILLING: 'Visit Billing',
    ROLE_BILLING_INFO_REQUEST: 'Billing Info Request',
    ROLE_DOCUMENT_STORAGE: 'Document Storage',
    ROLE_PHYSICIAN_TEAM_MEMBER: 'Physician Team Member',
    ROLE_PHYSICIAN_TEAM_MEMBER_CCM_ONLY: 'Physician Team Member - CCM-only',
    ROLE_PHYSICIAN_TEAM_PROBATION: 'Physician Team Probation',
    ROLE_PHYSICIAN_TEAM_INTERMEDIATE: 'Physician Team Intermediate',
    ROLE_PHYSICIAN_LITE: 'Physician Lite',
    ROLE_BEHAVIORAL_PHYSICIAN: 'Behavioral Physician',
    ROLE_SUBSPECIALIST: 'Subspecialist - Physician Team Member',
    ROLE_SUBSPECIALIST_ACCESS_ONLY: 'Subspecialist - Access only',
    ROLE_SUBSPECIALIST_VCM_ONLY: 'Subspecialist - VCM-only',
    ROLE_CLINIC: 'Clinic',
    ROLE_SOCIAL_WORKER: 'Social Worker',
    ROLE_PHARMACY_CONSULTANT: 'Pharmacy Consultant',
    ROLE_PHARMACY_CONSULTANT_LITE: 'Pharmacy Consultant Lite',
    ROLE_LAB_TESTING_ALL: 'Lab Testing - All',
    ROLE_LAB_ADMIN: 'Lab Admin',
    ROLE_LAB_OPERATIONS_ONLY: 'Lab Operations Only',
    ROLE_LAB_TECH: 'Lab Tech',
    ROLE_LAB_COLLECTION: 'Lab Collection',
    ROLE_LAB_BILLING: 'Lab Billing',
    ROLE_LAB: 'Lab',
    ROLE_LAB_HELIX: 'Lab - Helix',
    ROLE_EPIDEMIOLOGY_LAB: 'Epidemiology - Lab',
    ROLE_COVID_MAP: 'COVID Map',
    ROLE_LAB_BILLING_DETAILS: 'Lab Billing Details',
    ROLE_EDIT_PATIENTS: 'Edit Patients',
    ROLE_HIE: 'HIE',
    ROLE_HIE_PHARMACY: 'HIE Pharmacy',
    ROLE_PATIENT_ABSTRACT: 'Patient Abstract',
    ROLE_LAB_ACDX: 'Lab - Access DX',
    ROLE_LAB_WORKLIST: 'Lab Worklist',
    ROLE_MEDICATION_INTERACTIONS: 'Medication Interactions',
    ROLE_IMMUNIZATIONS: 'Immunizations',
    ROLE_DISEASE_MANAGEMENT: 'Disease Management',
    ROLE_PATIENT: 'Patient',
    ROLE_NP_ALL: 'Nurse Practitioner - All Patients',
    ROLE_NP_ASSIGNED: 'Nurse Practitioner - Assigned Patients',
    ROLE_NP_TEAM_MEMBER: 'Nurse Practitioner - Team Member',
    ROLE_USER: 'User',
    ROLE_SUSPENDED: 'Account to be Suspended',
    ROLE_PLACEHOLDER: 'Placeholder',
    ROLE_WOUND_PHYSICIAN: 'Wound Physician',
    TFA_EMAIL: 'Email',
    TFA_SMS: 'SMS',
    TWO_FACTOR_AUTH_ERROR_WRONG_PASSCODE: 'ERROR_WRONG_PASSCODE',
    TWO_FACTOR_AUTH_ERROR_NO_PASSCODE: 'ERROR_NO_PASSCODE',
    VITAL_TYPE_CALCULATED: 'CALCULATED',
    VITAL_TYPE_VITAL: 'VITAL',
    WL_TOPHEAD_FRONT: 'Top of Head / Scalp (Anterior)',
    WL_FOREHEAD: 'Forehead',
    WL_EAR_LEFT: 'Ear (Left)',
    WL_EAR_RIGHT: 'Ear (Right)',
    WL_NOSE: 'Nose',
    WL_CHEEK_LEFT: 'Cheek (Left)',
    WL_CHEEK_RIGHT: 'Cheek (Right)',
    WL_CHIN: 'Chin',
    WL_NECK_LEFT_FRONT: 'Neck (Left)',
    WL_NECK_RIGHT_FRONT: 'Neck (Right)',
    WL_SHOULDER_LEFT_FRONT: 'Shoulder (Left)',
    WL_SHOULDER_RIGHT_FRONT: 'Shoulder (Right)',
    WL_ARMPIT_LEFT: 'Armpit (Left)',
    WL_ARMPIT_RIGHT: 'Armpit (Right)',
    WL_UPPERARM_LEFT_FRONT: 'Upper Arm (Right)',
    WL_UPPERARM_RIGHT_FRONT: 'Upper Arm (Left)',
    WL_ELBOW_LEFT_FRONT: 'Elbow (Left)',
    WL_ELBOW_RIGHT_FRONT: 'Elbow (Right)',
    WL_FOREARM_LEFT_FRONT: 'Forearm (Left)',
    WL_FOREARM_RIGHT_FRONT: 'Forearm (Right)',
    WL_HAND_LEFT_FRONT: 'Hand (Left)',
    WL_HAND_RIGHT_FRONT: 'Hand (Right)',
    WL_FINGERS_LEFT: 'Fingers (Left)',
    WL_FINGERS_RIGHT: 'Fingers (Right)',
    WL_CHEST_LEFT: 'Chest (Left)',
    WL_CHEST_RIGHT: 'Chest (Right)',
    WL_STERNUM: 'Sternum',
    WL_RIBCAGE_LEFT: 'Ribcage (Left)',
    WL_RIBCAGE_RIGHT: 'Ribcage (Right)',
    WL_ABDOMEN_LEFT: 'Abdomen (Left)',
    WL_ABDOMEN_RIGHT: 'Abdomen (Right)',
    WL_BELLYBUTTON: 'Bellybutton',
    WL_GENITALIA: 'Genitalia',
    WL_THIGH_LEFT_FRONT: 'Thigh (Left)',
    WL_THIGH_RIGHT_FRONT: 'Thigh (Right)',
    WL_KNEE_LEFT_FRONT: 'Knee (Left)',
    WL_KNEE_RIGHT_FRONT: 'Knee (Right)',
    WL_SHIN_LEFT: 'Shin (Left)',
    WL_SHIN_RIGHT: 'Shin (Right)',
    WL_FOOT_LEFT_FRONT: 'Foot, Dorsal (Left)',
    WL_FOOT_RIGHT_FRONT: 'Foot, Dorsal (Right)',
    WL_TOES_LEFT: 'Toes (Right)',
    WL_TOES_RIGHT: 'Toes (Left)',
    WL_TOPHEAD_BACK: 'Top of Head / Scalp (Posterior)',
    WL_BACKOFHEAD: 'Occiput, Back of Head',
    WL_NECK_LEFT_BACK: 'Neck (Posterior)',
    WL_SCAPULA_RIGHT: 'Scapula (Right)',
    WL_SCAPULA_LEFT: 'Scapula (Left)',
    WL_SPINE: 'Spine',
    WL_LUMBARSPINE: 'Lumbar Spine',
    WL_SACRUM: 'Sacrum',
    WL_COCCYX: 'Coccyx',
    WL_GLUTEALCLEFT: 'Gluteal Cleft',
    WL_PERINEALAREA: 'Perineal Area',
    WL_THIGH_RIGHT_BACK: 'Thigh (Right, Posterior)',
    WL_THIGH_LEFT_BACK: 'Thigh (Left, Posterior)',
    WL_KNEE_RIGHT_BACK: 'Knee (Right, Posterior)',
    WL_KNEE_LEFT_BACK: 'Knee (Left, Posterior)',
    WL_HEEL_RIGHT: 'Heel (Right)',
    WL_HEEL_LEFT: 'Heel (Left)',
    WL_FOOT_RIGHT_BACK: 'Feet (Right, Bottom)',
    WL_FOOT_LEFT_BACK: 'Feet (Left, Bottom)',
    WL_BALLOFFOOT_RIGHT: 'Ball of Foot (Right)',
    WL_BALLOFFOOT_LEFT: 'Ball of Foot (Left)',
    WL_ISCHIALTUBEROSITY_RIGHT: 'Ischial Tuberosity (Right)',
    WL_ISCHIALTUBEROSITY_LEFT: 'Ischial Tuberosity (Left)',
    WL_ELBOW_RIGHT_BACK: 'Elbow (Right, Posterior)',
    WL_ELBOW_LEFT_BACK: 'Elbow (Left, Posterior)',
    WL_HAND_RIGHT_BACK: 'Hand (Right, Dorsum)',
    WL_HAND_LEFT_BACK: 'Hand (Left, Dorsum)',
    WL_BACK_LOWER_LEFT: 'Lower Back (Left)',
    WL_BACK_LOWER_RIGHT: 'Lower Back (Right)',
    WL_ANKLE_LEFT: 'Ankle (Left)',
    WL_ANKLE_RIGHT: 'Ankle (Right)',
    WL_EAR: 'Ear',
    WL_EYE: 'Eye',
    WL_NECK: 'Neck',
    WL_NECK_FRONT: 'Neck (Anterior)',
    WL_SHOULDER: 'Shoulder',
    WL_SHOULDER_FRONT: 'Shoulder (Anterior)',
    WL_SHOULDER_BACK: 'Shoulder (Posterior)',
    WL_UPPERARM: 'Upper Arm',
    WL_UPPERARM_FRONT: 'Upper Arm (Anterior)',
    WL_UPPERARM_BACK: 'Upper Arm (Posterior)',
    WL_ELBOW: 'Elbow',
    WL_ELBOW_FRONT: 'Elbow (Anterior)',
    WL_ELBOW_BACK: 'Elbow (Posterior)',
    WL_FOREARM: 'Forearm',
    WL_FOREARM_FRONT: 'Forearm (Anterior)',
    WL_FOREARM_BACK: 'Forearm (Posterior)',
    WL_HAND: 'Hand',
    WL_HAND_FRONT: 'Hand (Palmar)',
    WL_HAND_BACK: 'Hand (Dorsum)',
    WL_FINGERS: 'Fingers',
    WL_CHEST: 'Chest',
    WL_RIBCAGE: 'Ribcage',
    WL_ABDOMEN: 'Abdomen',
    WL_HIP: 'Hip',
    WL_HIP_LEFT: 'Hip (Left)',
    WL_HIP_RIGHT: 'Hip (Right)',
    WL_THIGH: 'Thigh',
    WL_THIGH_FRONT: 'Thigh (Anterior)',
    WL_THIGH_BACK: 'Thigh (Posterior)',
    WL_KNEE: 'Knee',
    WL_KNEE_FRONT: 'Knee (Anterior)',
    WL_KNEE_BACK: 'Knee (Posterior)',
    WL_SHIN: 'Shin',
    WL_CALF: 'Calf',
    WL_CALF_LEFT: 'Calf (Left)',
    WL_CALF_RIGHT: 'Calf (Right)',
    WL_FOOT: 'Foot',
    WL_FOOT_LEFT: 'Foot (Left)',
    WL_FOOT_RIGHT: 'Foot (Right)',
    WL_FOOT_FRONT: 'Foot (Dorsal)',
    WL_FOOT_BACK: 'Foot (Plantar)',
    WL_TOES: 'Toes',
    WL_HEEL: 'Heel',
    WL_GENITAL: 'Genital Area',
    WL_GLUTEAL: 'Gluteal Region',
    WL_BACK: 'Back',
    WL_BACK_LOWER: 'Lower Back',
    WL_BACK_LEFT: 'Back (Left)',
    WL_BACK_RIGHT: 'Back (Right)',
    WL_ANKLE: 'Ankle',
    NO_ICD_CODE_IDS: [72160, 73541, 73543, 73545, 73547, 73549, 73551, 73553, 73555, 73557, 73559, 73561, 73563, 73565, 73567, 73569, 73571, 73573, 73575, 73577, 73579]

};

export const ROLE_PERMISSIONS = {
    "ROLE_ACO_CALL_FREEMIUM":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CENSUS_HISTORY",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_COACHING_INFECTION_ADMINISTRATION",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_ASSESSMENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_INFECTION_ENTRIES",
        "RESTRICTION_NURSE",
        "RESTRICTION_PATIENT_DETAILS_CCM_ELIGIBLE",
     ],
    "ROLE_ACO_CLINICAL_OVERSIGHT":[
        "PERMISSION_FILTER_ADVANCED",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_REPORT_LIST",
        "PERMISSION_READ_MEDICATION_LIST",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ACO",
        "PERMISSION_READ_PATIENT_DOCUMENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_TASKS",
        "RESTRICTION_ACO",
     ],
    "ROLE_ADMISSION_COORDINATOR":[
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CENSUS_MAP",
        "PERMISSION_READ_CENSUS_TRENDS",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_FACILITY",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TRENDS",
        "PERMISSION_READ_UPLOADED_REPORTS",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_UPLOADED_REPORTS",
        "PERMISSION_WRITE_UPLOAD_REPORTS",
     ],
    "ROLE_ARSANAMD_NS":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CENSUS_GENERAL",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_COACHING_INFECTION_ADMINISTRATION",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_ASSESSMENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_EPISODIC_PROPOSALS",
        "RESTRICTION_NURSE",
     ],
    "ROLE_ARSANAMD_NURSING":[
        "PERMISSION_DECIDE_ACCESS_PERMISSIONS",
        "PERMISSION_FILTER_ADVANCED",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_COACHING_INFECTION_ADMINISTRATION",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_FACILITY_TASKS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_USER",
        "PERMISSION_RECEIVE_FACILITY_NOTIFICATIONS",
        "PERMISSION_WRITE_EPISODIC_PROPOSALS",
        "PERMISSION_WRITE_INFECTION_ENTRIES",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_TASKS",
     ],
    "ROLE_ARSANA_LITE":[
        "PERMISSION_FILTER_ADVANCED",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_COACHING_INFECTION_ADMINISTRATION",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_USER",
        "PERMISSION_RECEIVE_FACILITY_NOTIFICATIONS",
        "PERMISSION_WRITE_EPISODIC_PROPOSALS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_TASKS",
     ],
    "ROLE_ARSANA_PHYSICIAN_ENCOUNTERS":[
        "PERMISSION_DOWNLOAD_VCM_BILLING_SUMMARY_BY_FACILITY",
        "PERMISSION_FILTER_ADVANCED",
        "PERMISSION_FILTER_ASSIGNED_PATIENTS",
        "PERMISSION_FILTER_PATIENT_SPECIALTY",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_INCLUDE_ALL_ARSANA_MEDS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CCM_BILLING",
        "PERMISSION_READ_CENSUS_GENERAL",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_E_PRESCRIBING_WORKLIST",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_HE",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_INVOICES",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_REPORT_LIST",
        "PERMISSION_READ_LEVEL_OF_CARE_PAYERS",
        "PERMISSION_READ_MEDICAL_PROGRAM_BILLING",
        "PERMISSION_READ_MEDICATION_INTERACTIONS",
        "PERMISSION_READ_MEDICATION_LIST",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ASSIGNED",
        "PERMISSION_READ_PATIENT_ABSTRACT",
        "PERMISSION_READ_PATIENT_ASSESSMENTS",
        "PERMISSION_READ_PATIENT_DOCUMENTS",
        "PERMISSION_READ_PATIENT_HIE",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PATIENT_TIMELINE",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_QUESTIONNAIRES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SCHEDULER_WORKLIST",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_STATUS_OBSERVATIONS",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_TASK_SUMMARY",
        "PERMISSION_READ_TEAM_NOTES",
        "PERMISSION_READ_USER",
        "PERMISSION_READ_VISITS",
        "PERMISSION_READ_VISIT_BILLING_AUDIT",
        "PERMISSION_REQUEST_ACCESS_PERMISSIONS",
        "PERMISSION_REQUEST_PERSON_MERGE",
        "PERMISSION_VISIT_BILLING",
        "PERMISSION_WRITE_DIAGNOSES",
        "PERMISSION_WRITE_EPISODIC_PROPOSALS",
        "PERMISSION_WRITE_EVENTS",
        "PERMISSION_WRITE_MEDICAL_PROGRAMS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_PATIENTS",
        "PERMISSION_WRITE_PATIENT_CLINICAL",
        "PERMISSION_WRITE_PATIENT_DOCUMENTS",
        "PERMISSION_WRITE_PERSON_PROPOSALS",
        "PERMISSION_WRITE_STATUS_OBSERVATIONS",
        "PERMISSION_WRITE_TASKS",
        "PERMISSION_WRITE_VISITS",
        "RESTRICTION_PATIENT_DETAILS_ASSIGNED",
     ],
    "ROLE_BEHAVIORAL_PHYSICIAN":[
        "PERMISSION_DOWNLOAD_VCM_BILLING_SUMMARY_BY_FACILITY",
        "PERMISSION_FILTER_ADVANCED",
        "PERMISSION_FILTER_ASSIGNED_PATIENTS",
        "PERMISSION_FILTER_PATIENT_SPECIALTY",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CCM_BILLING",
        "PERMISSION_READ_CENSUS_GENERAL",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_E_PRESCRIBING_WORKLIST",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_HE",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_INVOICES",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_REPORT_LIST",
        "PERMISSION_READ_LEVEL_OF_CARE_PAYERS",
        "PERMISSION_READ_MEDICAL_PROGRAM_BILLING",
        "PERMISSION_READ_MEDICATION_INTERACTIONS",
        "PERMISSION_READ_MEDICATION_LIST",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ASSIGNED",
        "PERMISSION_READ_PATIENT_ASSESSMENTS",
        "PERMISSION_READ_PATIENT_DOCUMENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PATIENT_TIMELINE",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_QUESTIONNAIRES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_STATUS_OBSERVATIONS",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_TASK_SUMMARY",
        "PERMISSION_READ_TEAM_NOTES",
        "PERMISSION_READ_USER",
        "PERMISSION_READ_VISIT_BILLING_AUDIT",
        "PERMISSION_REQUEST_ACCESS_PERMISSIONS",
        "PERMISSION_REQUEST_PERSON_MERGE",
        "PERMISSION_WRITE_DIAGNOSES",
        "PERMISSION_WRITE_EPISODIC_PROPOSALS",
        "PERMISSION_WRITE_EVENTS",
        "PERMISSION_WRITE_MEDICAL_PROGRAMS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_PATIENTS",
        "PERMISSION_WRITE_PATIENT_CLINICAL",
        "PERMISSION_WRITE_PATIENT_DOCUMENTS",
        "PERMISSION_WRITE_PERSON_PROPOSALS",
        "PERMISSION_WRITE_STATUS_OBSERVATIONS",
        "PERMISSION_WRITE_TASKS",
        "PERMISSION_WRITE_VISITS",
        "RESTRICTION_PATIENT_DETAILS_ASSIGNED",
     ],
    "ROLE_BILLING_INFO_REQUEST":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_USER",
        "PERMISSION_SEND_BILLING_INFO_REQUESTS",
     ],
    "ROLE_BROADSTREET":[
        "PERMISSION_CREATE_USER_FROM_PATIENT",
        "PERMISSION_FILTER_ADVANCED",
        "PERMISSION_FILTER_ASSIGNED_PATIENTS",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_GENERATE_QRDA",
        "PERMISSION_IMPORT_PATIENT_FROM_CDA",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_APPLICATIONS",
        "PERMISSION_READ_CDA",
        "PERMISSION_READ_CDS",
        "PERMISSION_READ_CENSUS_GENERAL",
        "PERMISSION_READ_CLINICAL_DOCUMENTS",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_EHI_EXPORT",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_HE",
        "PERMISSION_READ_INVOICES",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LEVEL_OF_CARE_PAYERS",
        "PERMISSION_READ_MEDICATION_INTERACTIONS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ASSIGNED",
        "PERMISSION_READ_PATIENT_ASSESSMENTS",
        "PERMISSION_READ_PATIENT_DOCUMENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PI_MEASURES",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_QUESTIONNAIRES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_STATUS_OBSERVATIONS",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_TASK_SUMMARY",
        "PERMISSION_READ_USER",
        "PERMISSION_TRANSMIT_CDA",
        "PERMISSION_WRITE_CDS",
        "PERMISSION_WRITE_CLINICAL_DOCUMENTS",
        "PERMISSION_WRITE_DIAGNOSES",
        "PERMISSION_WRITE_EPISODIC_PROPOSALS",
        "PERMISSION_WRITE_EVENTS",
        "PERMISSION_WRITE_MEDICAL_PROGRAMS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_PATIENTS",
        "PERMISSION_WRITE_PATIENT_ADMIN",
        "PERMISSION_WRITE_PATIENT_CLINICAL",
        "PERMISSION_WRITE_PATIENT_DOCUMENTS",
        "PERMISSION_WRITE_STATUS_OBSERVATIONS",
        "PERMISSION_WRITE_SYSTEM_ALERTS",
        "PERMISSION_WRITE_TASKS",
        "PERMISSION_WRITE_VISITS",
        "RESTRICTION_PATIENT_DETAILS_ASSIGNED",
     ],
    "ROLE_CARE_SURVEILLANCE_I":[
        "PERMISSION_FILTER_ADVANCED",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ANTIBIOTICS",
        "PERMISSION_READ_ANTIPSYCHOTICS",
        "PERMISSION_READ_CENSUS_MAP",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_COACHING_INFECTION_ADMINISTRATION",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_IMMUNIZATIONS",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_USER",
        "PERMISSION_RECEIVE_FACILITY_NOTIFICATIONS",
        "PERMISSION_WRITE_EPISODIC_PROPOSALS",
        "PERMISSION_WRITE_IMMUNIZATIONS",
        "PERMISSION_WRITE_INFECTION_ENTRIES",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_TASKS",
     ],
    "ROLE_CLINIC":[
        "PERMISSION_DOWNLOAD_VCM_BILLING_SUMMARY_BY_FACILITY",
        "PERMISSION_FILTER_ADVANCED",
        "PERMISSION_FILTER_ASSIGNED_PATIENTS",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CCM_BILLING",
        "PERMISSION_READ_CENSUS_GENERAL",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_E_PRESCRIBING_WORKLIST",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_HE",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_INVOICES",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_REPORT_LIST",
        "PERMISSION_READ_LEVEL_OF_CARE_PAYERS",
        "PERMISSION_READ_MEDICAL_PROGRAM_BILLING",
        "PERMISSION_READ_MEDICATION_INTERACTIONS",
        "PERMISSION_READ_MEDICATION_LIST",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ASSIGNED",
        "PERMISSION_READ_PATIENT_ASSESSMENTS",
        "PERMISSION_READ_PATIENT_DOCUMENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PATIENT_TIMELINE",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_QUESTIONNAIRES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_STATUS_OBSERVATIONS",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_TASK_SUMMARY",
        "PERMISSION_READ_TEAM_NOTES",
        "PERMISSION_READ_USER",
        "PERMISSION_REQUEST_ACCESS_PERMISSIONS",
        "PERMISSION_REQUEST_PERSON_MERGE",
        "PERMISSION_WRITE_DIAGNOSES",
        "PERMISSION_WRITE_EPISODIC_PROPOSALS",
        "PERMISSION_WRITE_EVENTS",
        "PERMISSION_WRITE_MEDICAL_PROGRAMS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_PATIENTS",
        "PERMISSION_WRITE_PATIENT_CLINICAL",
        "PERMISSION_WRITE_PATIENT_DOCUMENTS",
        "PERMISSION_WRITE_PERSON_PROPOSALS",
        "PERMISSION_WRITE_STATUS_OBSERVATIONS",
        "PERMISSION_WRITE_TASKS",
        "PERMISSION_WRITE_VISITS",
        "RESTRICTION_PATIENT_DETAILS_ASSIGNED",
     ],
    "ROLE_COMMUNICATION":[
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_EPISODIC_PROPOSALS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_TASKS",
     ],
    "ROLE_COVID_MAP":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_COVID_MAP",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_USER",
     ],
    "ROLE_DEMO":[
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ADMIN_DASHBOARD",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_COACHING_INFECTION_ADMINISTRATION",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_FACILITY",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_ASSESSMENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PATIENT_TIMELINE",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_UPLOADED_REPORTS",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_TASKS",
        "PERMISSION_WRITE_UPLOADED_REPORTS",
     ],
    "ROLE_DISEASE_MANAGEMENT":[
        "PERMISSION_FILTER_PATIENT_SPECIALTY",
     ],
    "ROLE_DOCUMENT_STORAGE":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_UPLOADED_REPORTS",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_UPLOADED_REPORTS",
        "PERMISSION_WRITE_UPLOAD_REPORTS",
     ],
    "ROLE_EDIT_PATIENTS":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_FACILITY_PROPOSALS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_GROUPS",
        "PERMISSION_READ_IMPORT_ACCOUNTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORGANIZATION",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_PROPOSALS",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_USER",
        "PERMISSION_READ_USER_PROPOSALS",
        "PERMISSION_WRITE_FACILITY_PROPOSALS",
        "PERMISSION_WRITE_IMPORT_ACCOUNTS",
        "PERMISSION_WRITE_PATIENTS",
        "PERMISSION_WRITE_PATIENT_PROPOSALS",
        "PERMISSION_WRITE_USER_PROPOSALS",
     ],
    "ROLE_ENTRY_PROPOSALS":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_FACILITY_PROPOSALS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_GROUPS",
        "PERMISSION_READ_IMPORT_ACCOUNTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORGANIZATION",
        "PERMISSION_READ_PATIENT_PROPOSALS",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_USER",
        "PERMISSION_READ_USER_PROPOSALS",
        "PERMISSION_WRITE_FACILITY_PROPOSALS",
        "PERMISSION_WRITE_IMPORT_ACCOUNTS",
        "PERMISSION_WRITE_PATIENT_PROPOSALS",
        "PERMISSION_WRITE_USER_PROPOSALS",
     ],
    "ROLE_EPIDEMIOLOGY_LAB":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_LAB_INFECTION_CONTROL",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_REPORT_LIST",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_USER",
        "PERMISSION_RECEIVE_FACILITY_NOTIFICATIONS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
     ],
    "ROLE_EXPERIMENTAL_FEATURES":[
        "PERMISSION_EXPERIMENTAL_FEATURES",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_PATIENT_ASSESSMENTS",
        "PERMISSION_READ_PATIENT_TIMELINE",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TRENDS_EXPERIMENTAL_FEATURES",
        "PERMISSION_READ_USER",
     ],
    "ROLE_EXPERIMENTAL_ONLY":[
        "PERMISSION_READ_ANTIPSYCHOTICS",
     ],
    "ROLE_FACILITY_PROPOSALS":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_FACILITY_PROPOSALS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_GROUPS",
        "PERMISSION_READ_IMPORT_ACCOUNTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORGANIZATION",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_FACILITY_PROPOSALS",
        "PERMISSION_WRITE_IMPORT_ACCOUNTS",
     ],
    "ROLE_FACILITY_TASKS":[
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_FACILITY_TASKS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_EPISODIC_PROPOSALS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_TASKS",
     ],
    "ROLE_FREEMIUM_ALL_USER":[
        "PERMISSION_APPROVE_BILLING_INFO_REQUESTS",
        "PERMISSION_FILTER_ADVANCED",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_COACHING_INFECTION_ADMINISTRATION",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_UPLOADED_REPORTS",
        "PERMISSION_READ_USER",
        "PERMISSION_READ_USER_LIST",
        "PERMISSION_WRITE_EPISODIC_PROPOSALS",
        "PERMISSION_WRITE_INFECTION_ENTRIES",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_TASKS",
        "PERMISSION_WRITE_UPLOADED_REPORTS",
        "PERMISSION_WRITE_UPLOAD_REPORTS",
     ],
    "ROLE_FREEMIUM_NURSING_DIRECTOR":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CENSUS_HISTORY",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_COACHING_INFECTION_ADMINISTRATION",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_ASSESSMENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_INFECTION_ENTRIES",
        "RESTRICTION_NURSE",
        "RESTRICTION_PATIENT_DETAILS_CCM_ELIGIBLE",
     ],
    "ROLE_HIE":[
        "PERMISSION_READ_PATIENT_DOCUMENTS",
        "PERMISSION_READ_PATIENT_HIE",
     ],
    "ROLE_HIE_PHARMACY":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CENSUS_GENERAL",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_DOCUMENTS",
        "PERMISSION_READ_PATIENT_HIE",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_USER",
        "RESTRICTION_HIE_PHARMACY",
     ],
    "ROLE_IMMUNIZATIONS":[
        "PERMISSION_READ_IMMUNIZATIONS",
        "PERMISSION_WRITE_IMMUNIZATIONS",
     ],
    "ROLE_IMPORT":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_IMPORT",
        "PERMISSION_READ_IMPORT_LOGS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_IMPORT",
     ],
    "ROLE_INFECTION_SURVEILLANCE":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ANTIBIOTICS",
        "PERMISSION_READ_CENSUS_MAP",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_COACHING_INFECTION_ADMINISTRATION",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_ASSESSMENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_INFECTION_ENTRIES",
        "RESTRICTION_PATIENT_DETAILS_CCM_ELIGIBLE",
     ],
    "ROLE_LAB":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_REPORT_LIST",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_USER",
        "PERMISSION_RECEIVE_FACILITY_NOTIFICATIONS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "RESTRICTION_PATIENT_LABS",
     ],
    "ROLE_LAB_ACDX":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_REPORT_LIST",
        "PERMISSION_READ_LAB_WORKLIST",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_USER",
        "PERMISSION_RECEIVE_FACILITY_NOTIFICATIONS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "RESTRICTION_CONTENT_TYPE_LAB",
        "RESTRICTION_LAB_ACDX",
     ],
    "ROLE_LAB_ADMIN":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_COVID_TESTING",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_LAB_COLLECTION",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_REPORT_LIST",
        "PERMISSION_READ_LAB_SHIPMENTS",
        "PERMISSION_READ_LAB_TESTING",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_USER",
        "PERMISSION_RECEIVE_FACILITY_NOTIFICATIONS",
        "PERMISSION_WRITE_LAB_TESTING",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
     ],
    "ROLE_LAB_BILLING":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_LAB_BILLING",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_REPORT_LIST",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_USER",
     ],
    "ROLE_LAB_BILLING_DETAILS":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_LAB_BILLING",
        "PERMISSION_READ_LAB_BILLING_DETAILS",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_REPORT_LIST",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_USER",
     ],
    "ROLE_LAB_COLLECTION":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_COVID_TESTING",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_LAB_COLLECTION",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_TESTING",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_LAB_TESTING",
     ],
    "ROLE_LAB_HELIX":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_REPORT_LIST",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_USER",
        "PERMISSION_RECEIVE_FACILITY_NOTIFICATIONS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "RESTRICTION_LAB_HELIX",
        "RESTRICTION_PATIENT_LABS",
     ],
    "ROLE_LAB_OPERATIONS_ONLY":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_COVID_TESTING",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_LAB_COLLECTION",
        "PERMISSION_READ_LAB_SHIPMENTS",
        "PERMISSION_READ_LAB_TESTING",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_LAB_TESTING",
        "RESTRICTION_PATIENT_NO_DETAILS",
     ],
    "ROLE_LAB_TECH":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_COVID_TESTING",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_LAB_ACCESSION",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_TESTING",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_LAB_TESTING",
     ],
    "ROLE_LAB_TESTING_ALL":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_COVID_TESTING",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_LAB_ACCESSION",
        "PERMISSION_READ_LAB_COLLECTION",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_REPORT_LIST",
        "PERMISSION_READ_LAB_SHIPMENTS",
        "PERMISSION_READ_LAB_SHIPMENT_STATUS",
        "PERMISSION_READ_LAB_TESTING",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_USER",
        "PERMISSION_RECEIVE_FACILITY_NOTIFICATIONS",
        "PERMISSION_WRITE_LAB_SHIPMENT_STATUS",
        "PERMISSION_WRITE_LAB_TESTING",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
     ],
    "ROLE_LAB_WORKLIST":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_REPORT_LIST",
        "PERMISSION_READ_LAB_WORKLIST",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_USER",
     ],
    "ROLE_LOCATIONS_SETUP":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_BEDS",
        "PERMISSION_READ_BED_ASSOCIATIONS",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_LOCATIONS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_BEDS",
        "PERMISSION_WRITE_BED_ASSOCIATIONS",
        "PERMISSION_WRITE_FLOOR_PLANS",
        "PERMISSION_WRITE_LOCATIONS",
     ],
    "ROLE_MAINTENANCE":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_LOGS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_USER",
     ],
    "ROLE_MEDICATION_INTERACTIONS":[
        "PERMISSION_READ_MEDICATION_INTERACTIONS",
     ],
    "ROLE_MR":[
        "PERMISSION_APPROVE_BILLING_INFO_REQUESTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_UPLOADED_REPORTS",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_UPLOADED_REPORTS",
        "PERMISSION_WRITE_UPLOAD_REPORTS",
     ],
    "ROLE_NP_ALL":[
        "PERMISSION_FILTER_ADVANCED",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_USER",
        "PERMISSION_READ_VISITS",
        "PERMISSION_WRITE_FACE_TO_FACE_PROPOSALS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_TASKS",
        "PERMISSION_WRITE_VISITS",
     ],
    "ROLE_NP_ASSIGNED":[
        "PERMISSION_FILTER_ADVANCED",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ASSIGNED",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_USER",
        "PERMISSION_READ_VISITS",
        "PERMISSION_WRITE_FACE_TO_FACE_PROPOSALS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_TASKS",
        "PERMISSION_WRITE_VISITS",
     ],
    "ROLE_NP_TEAM_MEMBER":[
        "PERMISSION_FILTER_ADVANCED",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_USER",
        "PERMISSION_READ_VISITS",
        "PERMISSION_WRITE_FACE_TO_FACE_PROPOSALS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_TASKS",
        "PERMISSION_WRITE_VISITS",
     ],
    "ROLE_NURSING_DIRECTOR":[
        "PERMISSION_DECIDE_ACCESS_PERMISSIONS",
        "PERMISSION_FILTER_ADVANCED",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ADMIN_DASHBOARD",
        "PERMISSION_READ_ANTIBIOTICS",
        "PERMISSION_READ_CENSUS_MAP",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CENSUS_TRENDS",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_COACHING_INFECTION_ADMINISTRATION",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_FACILITY",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_IMMUNIZATIONS",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_REPORT_LIST",
        "PERMISSION_READ_MEDICATION_LIST",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_ASSESSMENTS",
        "PERMISSION_READ_PATIENT_DOCUMENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_TRENDS",
        "PERMISSION_READ_UPLOADED_REPORTS",
        "PERMISSION_READ_USER",
        "PERMISSION_RECEIVE_FACILITY_NOTIFICATIONS",
        "PERMISSION_WRITE_EPISODIC_PROPOSALS",
        "PERMISSION_WRITE_IMMUNIZATIONS",
        "PERMISSION_WRITE_INFECTION_ENTRIES",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_TASKS",
        "PERMISSION_WRITE_UPLOADED_REPORTS",
        "PERMISSION_WRITE_UPLOAD_REPORTS",
     ],
    "ROLE_OFFICE":[
        "HOMEPAGE_CENSUS_TRENDS",
        "PERMISSION_APPROVE_BILLING_INFO_REQUESTS",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CENSUS_MAP",
        "PERMISSION_READ_CENSUS_TRENDS",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_FACILITY",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TRENDS",
        "PERMISSION_READ_UPLOADED_REPORTS",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_UPLOADED_REPORTS",
        "PERMISSION_WRITE_UPLOAD_REPORTS",
     ],
    "ROLE_ORGANIZATION_ADMIN":[
        "PERMISSION_APPROVE_BILLING_INFO_REQUESTS",
        "PERMISSION_FILTER_ADVANCED",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ACF_REPORT",
        "PERMISSION_READ_ADMIN_DASHBOARD",
        "PERMISSION_READ_ANTIBIOTICS",
        "PERMISSION_READ_CENSUS_MAP",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CENSUS_TRENDS",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_COACHING_INFECTION_ADMINISTRATION",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_FACILITY",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_INFECTION_ENTRY",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_REPORT_LIST",
        "PERMISSION_READ_LOCATIONS",
        "PERMISSION_READ_MEDICATION_LIST",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_ORGANIZATION",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_ASSESSMENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SNF_REPORT",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_TRENDS",
        "PERMISSION_READ_UPLOADED_REPORTS",
        "PERMISSION_READ_USER",
        "PERMISSION_READ_USER_LIST",
        "PERMISSION_WRITE_FACILITY",
        "PERMISSION_WRITE_FLOOR_PLANS",
        "PERMISSION_WRITE_LOCATIONS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_UPLOADED_REPORTS",
        "PERMISSION_WRITE_UPLOAD_REPORTS",
     ],
    "ROLE_ORGANIZATION_ADMIN_LIGHT":[
        "PERMISSION_APPROVE_BILLING_INFO_REQUESTS",
        "PERMISSION_FILTER_ADVANCED",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ACF_REPORT",
        "PERMISSION_READ_ADMIN_DASHBOARD",
        "PERMISSION_READ_ANTIBIOTICS",
        "PERMISSION_READ_CENSUS_MAP",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CENSUS_TRENDS",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_COACHING_INFECTION_ADMINISTRATION",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_FACILITY",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_INFECTION_ENTRY",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_REPORT_LIST",
        "PERMISSION_READ_LOCATIONS",
        "PERMISSION_READ_MEDICATION_LIST",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_ORGANIZATION",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_ASSESSMENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SNF_REPORT",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_TRENDS",
        "PERMISSION_READ_UPLOADED_REPORTS",
        "PERMISSION_READ_USER",
        "PERMISSION_READ_USER_LIST",
        "PERMISSION_WRITE_FACILITY",
        "PERMISSION_WRITE_FLOOR_PLANS",
        "PERMISSION_WRITE_LOCATIONS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_UPLOADED_REPORTS",
        "PERMISSION_WRITE_UPLOAD_REPORTS",
     ],
    "ROLE_PATIENT":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CDA",
        "PERMISSION_READ_CENSUS_HISTORY",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_DIAGNOSES",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_AS_REPRESENTATIVE",
        "PERMISSION_READ_PATIENT_DOCUMENTS",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_QUESTIONNAIRES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_USER",
        "PERMISSION_TRANSMIT_CDA",
     ],
    "ROLE_PATIENT_ABSTRACT":[
        "PERMISSION_READ_PATIENT_ABSTRACT",
        "PERMISSION_READ_PATIENT_DOCUMENTS",
     ],
    "ROLE_PATIENT_PROPOSALS":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_GROUPS",
        "PERMISSION_READ_IMPORT_ACCOUNTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORGANIZATION",
        "PERMISSION_READ_PATIENT_PROPOSALS",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_IMPORT_ACCOUNTS",
        "PERMISSION_WRITE_PATIENT_PROPOSALS",
     ],
    "ROLE_PATIENT_REPRESENTATIVE":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CDA",
        "PERMISSION_READ_CENSUS_HISTORY",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_DIAGNOSES",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_AS_REPRESENTATIVE",
        "PERMISSION_READ_PATIENT_DOCUMENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_QUESTIONNAIRES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_USER",
        "PERMISSION_TRANSMIT_CDA",
     ],
    "ROLE_PHARMACY_CONSULTANT":[
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ANTIBIOTICS",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_E_PRESCRIBING_WORKLIST",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_REPORT_LIST",
        "PERMISSION_READ_MEDICATION_LIST",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_TASKS",
     ],
    "ROLE_PHARMACY_CONSULTANT_LITE":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ANTIBIOTICS",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_REPORT_LIST",
        "PERMISSION_READ_MEDICATION_LIST",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_USER",
     ],
    "ROLE_PHYSICIAN":[
        "PERMISSION_DOWNLOAD_VCM_BILLING_SUMMARY_BY_FACILITY",
        "PERMISSION_FILTER_ADVANCED",
        "PERMISSION_FILTER_ASSIGNED_PATIENTS",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CCM_BILLING",
        "PERMISSION_READ_CENSUS_GENERAL",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_E_PRESCRIBING_WORKLIST",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_HE",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_INVOICES",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_REPORT_LIST",
        "PERMISSION_READ_LEVEL_OF_CARE_PAYERS",
        "PERMISSION_READ_MEDICAL_PROGRAM_BILLING",
        "PERMISSION_READ_MEDICATION_INTERACTIONS",
        "PERMISSION_READ_MEDICATION_LIST",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ASSIGNED",
        "PERMISSION_READ_PATIENT_ASSESSMENTS",
        "PERMISSION_READ_PATIENT_DOCUMENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PATIENT_TIMELINE",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_QUESTIONNAIRES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_STATUS_OBSERVATIONS",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_TASK_SUMMARY",
        "PERMISSION_READ_TEAM_NOTES",
        "PERMISSION_READ_USER",
        "PERMISSION_REQUEST_ACCESS_PERMISSIONS",
        "PERMISSION_REQUEST_PERSON_MERGE",
        "PERMISSION_WRITE_DIAGNOSES",
        "PERMISSION_WRITE_EPISODIC_PROPOSALS",
        "PERMISSION_WRITE_EVENTS",
        "PERMISSION_WRITE_MEDICAL_PROGRAMS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_PATIENTS",
        "PERMISSION_WRITE_PATIENT_CLINICAL",
        "PERMISSION_WRITE_PATIENT_DOCUMENTS",
        "PERMISSION_WRITE_PERSON_PROPOSALS",
        "PERMISSION_WRITE_STATUS_OBSERVATIONS",
        "PERMISSION_WRITE_TASKS",
        "PERMISSION_WRITE_VISITS",
        "RESTRICTION_PATIENT_DETAILS_ASSIGNED",
     ],
    "ROLE_PHYSICIAN_BILLING_MANAGER":[
        "PERMISSION_DOWNLOAD_VCM_BILLING_SUMMARY_BY_FACILITY",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CCM_BILLING",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_MEDICAL_PROGRAM_BILLING",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_PATIENTS_ASSIGNED",
        "PERMISSION_READ_PRODUCTIVITY_REPORT",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_SUMMARY",
        "PERMISSION_READ_USER",
        "PERMISSION_READ_VISIT_BILLING",
        "PERMISSION_UPDATE_TASKS",
        "PERMISSION_WRITE_PATIENT_ADMIN",
     ],
    "ROLE_PHYSICIAN_CCM_ONLY":[
        "PERMISSION_DOWNLOAD_VCM_BILLING_SUMMARY_BY_FACILITY",
        "PERMISSION_FILTER_ADVANCED",
        "PERMISSION_FILTER_ASSIGNED_PATIENTS",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CCM_BILLING",
        "PERMISSION_READ_CENSUS_GENERAL",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_HE",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_INVOICES",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_REPORT_LIST",
        "PERMISSION_READ_LEVEL_OF_CARE_PAYERS",
        "PERMISSION_READ_MEDICAL_PROGRAM_BILLING",
        "PERMISSION_READ_MEDICATION_LIST",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ASSIGNED",
        "PERMISSION_READ_PATIENT_ASSESSMENTS",
        "PERMISSION_READ_PATIENT_DOCUMENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PATIENT_TIMELINE",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_QUESTIONNAIRES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_STATUS_OBSERVATIONS",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_TASK_SUMMARY",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_DIAGNOSES",
        "PERMISSION_WRITE_EPISODIC_PROPOSALS",
        "PERMISSION_WRITE_EVENTS",
        "PERMISSION_WRITE_MEDICAL_PROGRAMS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_PATIENTS",
        "PERMISSION_WRITE_PATIENT_CLINICAL",
        "PERMISSION_WRITE_PATIENT_DOCUMENTS",
        "PERMISSION_WRITE_STATUS_OBSERVATIONS",
        "PERMISSION_WRITE_TASKS",
        "PERMISSION_WRITE_VISITS",
        "RESTRICTION_PATIENT_DETAILS_ASSIGNED",
     ],
    "ROLE_PHYSICIAN_LITE":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ASSIGNED",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_USER",
     ],
    "ROLE_PHYSICIAN_NO_EMR":[
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ASSIGNED",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_UPLOADED_REPORTS",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_EPISODIC_PROPOSALS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_TASKS",
        "PERMISSION_WRITE_UPLOADED_REPORTS",
        "PERMISSION_WRITE_UPLOAD_REPORTS",
     ],
    "ROLE_PHYSICIAN_OUTSIDE_BILLING":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_PATIENTS_ASSIGNED",
        "PERMISSION_READ_PATIENT_DOCUMENTS",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_USER",
        "PERMISSION_READ_VISIT_BILLING",
        "PERMISSION_READ_VISIT_BILLING_AUDIT",
        "PERMISSION_UPDATE_TASKS",
        "PERMISSION_WRITE_PATIENT_ADMIN",
     ],
    "ROLE_PHYSICIAN_TEAM_INTERMEDIATE":[
        "PERMISSION_FILTER_ADVANCED",
        "PERMISSION_FILTER_ASSIGNED_PATIENTS",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CENSUS_GENERAL",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_HE",
        "PERMISSION_READ_INVOICES",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LEVEL_OF_CARE_PAYERS",
        "PERMISSION_READ_MEDICAL_PROGRAM_BILLING",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ASSIGNED",
        "PERMISSION_READ_PATIENT_ASSESSMENTS",
        "PERMISSION_READ_PATIENT_DOCUMENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PATIENT_TIMELINE",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_QUESTIONNAIRES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_STATUS_OBSERVATIONS",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_TASK_SUMMARY",
        "PERMISSION_READ_TEAM_NOTES",
        "PERMISSION_READ_USER",
        "PERMISSION_READ_VISITS",
        "PERMISSION_READ_VISIT_BILLING_AUDIT",
        "PERMISSION_WRITE_DIAGNOSES",
        "PERMISSION_WRITE_EPISODIC_PROPOSALS",
        "PERMISSION_WRITE_EVENTS",
        "PERMISSION_WRITE_MEDICAL_PROGRAMS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_PATIENTS",
        "PERMISSION_WRITE_PATIENT_CLINICAL",
        "PERMISSION_WRITE_PATIENT_DOCUMENTS",
        "PERMISSION_WRITE_STATUS_OBSERVATIONS",
        "PERMISSION_WRITE_TASKS",
        "PERMISSION_WRITE_VISITS",
        "RESTRICTION_PATIENT_DETAILS_ASSIGNED",
        "RESTRICTION_PROBATION",
     ],
    "ROLE_PHYSICIAN_TEAM_MEMBER":[
        "PERMISSION_DOWNLOAD_VCM_BILLING_SUMMARY_BY_FACILITY",
        "PERMISSION_FILTER_ADVANCED",
        "PERMISSION_FILTER_ASSIGNED_PATIENTS",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CCM_BILLING",
        "PERMISSION_READ_CENSUS_GENERAL",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_E_PRESCRIBING_WORKLIST",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_HE",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_INVOICES",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_REPORT_LIST",
        "PERMISSION_READ_LEVEL_OF_CARE_PAYERS",
        "PERMISSION_READ_MEDICAL_PROGRAM_BILLING",
        "PERMISSION_READ_MEDICATION_INTERACTIONS",
        "PERMISSION_READ_MEDICATION_LIST",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ASSIGNED",
        "PERMISSION_READ_PATIENT_ASSESSMENTS",
        "PERMISSION_READ_PATIENT_DOCUMENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PATIENT_TIMELINE",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_QUESTIONNAIRES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_STATUS_OBSERVATIONS",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_TASK_SUMMARY",
        "PERMISSION_READ_TEAM_NOTES",
        "PERMISSION_READ_USER",
        "PERMISSION_REQUEST_ACCESS_PERMISSIONS",
        "PERMISSION_REQUEST_PERSON_MERGE",
        "PERMISSION_WRITE_DIAGNOSES",
        "PERMISSION_WRITE_EPISODIC_PROPOSALS",
        "PERMISSION_WRITE_EVENTS",
        "PERMISSION_WRITE_MEDICAL_PROGRAMS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_PATIENTS",
        "PERMISSION_WRITE_PATIENT_CLINICAL",
        "PERMISSION_WRITE_PATIENT_DOCUMENTS",
        "PERMISSION_WRITE_PERSON_PROPOSALS",
        "PERMISSION_WRITE_STATUS_OBSERVATIONS",
        "PERMISSION_WRITE_TASKS",
        "PERMISSION_WRITE_VISITS",
        "RESTRICTION_PATIENT_DETAILS_ASSIGNED",
     ],
    "ROLE_PHYSICIAN_TEAM_MEMBER_CCM_ONLY":[
        "PERMISSION_DOWNLOAD_VCM_BILLING_SUMMARY_BY_FACILITY",
        "PERMISSION_FILTER_ADVANCED",
        "PERMISSION_FILTER_ASSIGNED_PATIENTS",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CCM_BILLING",
        "PERMISSION_READ_CENSUS_GENERAL",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_HE",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_INVOICES",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_REPORT_LIST",
        "PERMISSION_READ_LEVEL_OF_CARE_PAYERS",
        "PERMISSION_READ_MEDICAL_PROGRAM_BILLING",
        "PERMISSION_READ_MEDICATION_LIST",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ASSIGNED",
        "PERMISSION_READ_PATIENT_ASSESSMENTS",
        "PERMISSION_READ_PATIENT_DOCUMENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PATIENT_TIMELINE",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_QUESTIONNAIRES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_STATUS_OBSERVATIONS",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_TASK_SUMMARY",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_DIAGNOSES",
        "PERMISSION_WRITE_EPISODIC_PROPOSALS",
        "PERMISSION_WRITE_EVENTS",
        "PERMISSION_WRITE_MEDICAL_PROGRAMS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_PATIENTS",
        "PERMISSION_WRITE_PATIENT_CLINICAL",
        "PERMISSION_WRITE_PATIENT_DOCUMENTS",
        "PERMISSION_WRITE_STATUS_OBSERVATIONS",
        "PERMISSION_WRITE_TASKS",
        "PERMISSION_WRITE_VISITS",
        "RESTRICTION_PATIENT_DETAILS_ASSIGNED",
     ],
    "ROLE_PHYSICIAN_TEAM_PROBATION":[
        "PERMISSION_FILTER_ADVANCED",
        "PERMISSION_FILTER_ASSIGNED_PATIENTS",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CENSUS_GENERAL",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_HE",
        "PERMISSION_READ_INVOICES",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LEVEL_OF_CARE_PAYERS",
        "PERMISSION_READ_MEDICAL_PROGRAM_BILLING",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ASSIGNED",
        "PERMISSION_READ_PATIENT_ASSESSMENTS",
        "PERMISSION_READ_PATIENT_DOCUMENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PATIENT_TIMELINE",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_QUESTIONNAIRES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_STATUS_OBSERVATIONS",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_TASK_SUMMARY",
        "PERMISSION_READ_TEAM_NOTES",
        "PERMISSION_READ_USER",
        "PERMISSION_READ_VISITS",
        "PERMISSION_READ_VISIT_BILLING_AUDIT",
        "PERMISSION_WRITE_DIAGNOSES",
        "PERMISSION_WRITE_EPISODIC_PROPOSALS",
        "PERMISSION_WRITE_EVENTS",
        "PERMISSION_WRITE_MEDICAL_PROGRAMS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_PATIENTS",
        "PERMISSION_WRITE_PATIENT_CLINICAL",
        "PERMISSION_WRITE_PATIENT_DOCUMENTS",
        "PERMISSION_WRITE_STATUS_OBSERVATIONS",
        "PERMISSION_WRITE_TASKS",
        "PERMISSION_WRITE_VISITS",
        "RESTRICTION_PATIENT_DETAILS_ASSIGNED",
        "RESTRICTION_PROBATION",
     ],
    "ROLE_PHYSICIAN_VISIT_BILLING":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_PATIENTS_ASSIGNED",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_USER",
        "PERMISSION_READ_VISIT_BILLING",
        "PERMISSION_UPDATE_TASKS",
        "PERMISSION_WRITE_PATIENT_ADMIN",
     ],
    "ROLE_PLACEHOLDER":[
        "PERMISSION_PLACEHOLDER",
     ],
    "ROLE_SED_TESTING":[
        "PERMISSION_FILTER_ASSIGNED_PATIENTS",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_IMPORT_PATIENT_FROM_CDA",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CDA",
        "PERMISSION_READ_CENSUS_GENERAL",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_HE",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LEVEL_OF_CARE_PAYERS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ASSIGNED",
        "PERMISSION_READ_PATIENT_ASSESSMENTS",
        "PERMISSION_READ_PATIENT_DOCUMENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_QUESTIONNAIRES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_STATUS_OBSERVATIONS",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_CLINICAL_DOCUMENTS",
        "PERMISSION_WRITE_DIAGNOSES",
        "PERMISSION_WRITE_EPISODIC_PROPOSALS",
        "PERMISSION_WRITE_EVENTS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_PATIENTS",
        "PERMISSION_WRITE_PATIENT_CLINICAL",
        "PERMISSION_WRITE_PATIENT_DOCUMENTS",
        "PERMISSION_WRITE_STATUS_OBSERVATIONS",
        "PERMISSION_WRITE_TASKS",
        "PERMISSION_WRITE_USER",
        "RESTRICTION_PATIENT_DETAILS_ASSIGNED",
     ],
    "ROLE_SOCIAL_WORKER":[
        "PERMISSION_DOWNLOAD_VCM_BILLING_SUMMARY_BY_FACILITY",
        "PERMISSION_FILTER_ADVANCED",
        "PERMISSION_FILTER_ASSIGNED_PATIENTS",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CCM_BILLING",
        "PERMISSION_READ_CENSUS_GENERAL",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_E_PRESCRIBING_WORKLIST",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_HE",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_INVOICES",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_REPORT_LIST",
        "PERMISSION_READ_LEVEL_OF_CARE_PAYERS",
        "PERMISSION_READ_MEDICAL_PROGRAM_BILLING",
        "PERMISSION_READ_MEDICATION_INTERACTIONS",
        "PERMISSION_READ_MEDICATION_LIST",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ASSIGNED",
        "PERMISSION_READ_PATIENT_ASSESSMENTS",
        "PERMISSION_READ_PATIENT_DOCUMENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PATIENT_TIMELINE",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_QUESTIONNAIRES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_STATUS_OBSERVATIONS",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_TASK_SUMMARY",
        "PERMISSION_READ_TEAM_NOTES",
        "PERMISSION_READ_USER",
        "PERMISSION_REQUEST_ACCESS_PERMISSIONS",
        "PERMISSION_REQUEST_PERSON_MERGE",
        "PERMISSION_WRITE_DIAGNOSES",
        "PERMISSION_WRITE_EPISODIC_PROPOSALS",
        "PERMISSION_WRITE_EVENTS",
        "PERMISSION_WRITE_MEDICAL_PROGRAMS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_PATIENTS",
        "PERMISSION_WRITE_PATIENT_CLINICAL",
        "PERMISSION_WRITE_PATIENT_DOCUMENTS",
        "PERMISSION_WRITE_PERSON_PROPOSALS",
        "PERMISSION_WRITE_STATUS_OBSERVATIONS",
        "PERMISSION_WRITE_TASKS",
        "PERMISSION_WRITE_VISITS",
        "RESTRICTION_PATIENT_DETAILS_ASSIGNED",
     ],
    "ROLE_SUBSPECIALIST":[
        "PERMISSION_FILTER_ADVANCED",
        "PERMISSION_FILTER_ASSIGNED_PATIENTS",
        "PERMISSION_FILTER_PATIENT_SPECIALTY",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CENSUS_GENERAL",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_HE",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LEVEL_OF_CARE_PAYERS",
        "PERMISSION_READ_MEDICATION_INTERACTIONS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ASSIGNED",
        "PERMISSION_READ_PATIENT_ASSESSMENTS",
        "PERMISSION_READ_PATIENT_DOCUMENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PATIENT_TIMELINE",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_QUESTIONNAIRES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_STATUS_OBSERVATIONS",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_TEAM_NOTES",
        "PERMISSION_READ_USER",
        "PERMISSION_REQUEST_ACCESS_PERMISSIONS",
        "PERMISSION_WRITE_DIAGNOSES",
        "PERMISSION_WRITE_EPISODIC_PROPOSALS",
        "PERMISSION_WRITE_EVENTS",
        "PERMISSION_WRITE_MEDICAL_PROGRAMS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_PATIENTS",
        "PERMISSION_WRITE_PATIENT_CLINICAL",
        "PERMISSION_WRITE_PATIENT_DOCUMENTS",
        "PERMISSION_WRITE_STATUS_OBSERVATIONS",
        "PERMISSION_WRITE_TASKS",
        "PERMISSION_WRITE_VISITS",
        "RESTRICTION_PATIENT_DETAILS_ASSIGNED",
     ],
    "ROLE_SUBSPECIALIST_ACCESS_ONLY":[
        "PERMISSION_FILTER_ADVANCED",
        "PERMISSION_FILTER_ASSIGNED_PATIENTS",
        "PERMISSION_FILTER_PATIENT_SPECIALTY",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CENSUS_GENERAL",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_HE",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LEVEL_OF_CARE_PAYERS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ASSIGNED",
        "PERMISSION_READ_PATIENT_ASSESSMENTS",
        "PERMISSION_READ_PATIENT_DOCUMENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PATIENT_TIMELINE",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_QUESTIONNAIRES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_STATUS_OBSERVATIONS",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_USER",
        "PERMISSION_REQUEST_ACCESS_PERMISSIONS",
        "PERMISSION_WRITE_DIAGNOSES",
        "PERMISSION_WRITE_EPISODIC_PROPOSALS",
        "PERMISSION_WRITE_EVENTS",
        "PERMISSION_WRITE_MEDICAL_PROGRAMS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_PATIENTS",
        "PERMISSION_WRITE_PATIENT_CLINICAL",
        "PERMISSION_WRITE_PATIENT_DOCUMENTS",
        "PERMISSION_WRITE_STATUS_OBSERVATIONS",
        "PERMISSION_WRITE_TASKS",
        "PERMISSION_WRITE_VISITS",
        "RESTRICTION_PATIENT_DETAILS_ASSIGNED",
     ],
    "ROLE_SUBSPECIALIST_VCM_ONLY":[
        "PERMISSION_FILTER_ADVANCED",
        "PERMISSION_FILTER_ASSIGNED_PATIENTS",
        "PERMISSION_FILTER_PATIENT_SPECIALTY",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CCM_BILLING",
        "PERMISSION_READ_CENSUS_GENERAL",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_HE",
        "PERMISSION_READ_INVOICES",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LEVEL_OF_CARE_PAYERS",
        "PERMISSION_READ_MEDICAL_PROGRAM_BILLING",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ASSIGNED",
        "PERMISSION_READ_PATIENT_ASSESSMENTS",
        "PERMISSION_READ_PATIENT_DOCUMENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PATIENT_TIMELINE",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_QUESTIONNAIRES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_STATUS_OBSERVATIONS",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_TASK_SUMMARY",
        "PERMISSION_READ_USER",
        "PERMISSION_REQUEST_ACCESS_PERMISSIONS",
        "PERMISSION_WRITE_DIAGNOSES",
        "PERMISSION_WRITE_EPISODIC_PROPOSALS",
        "PERMISSION_WRITE_EVENTS",
        "PERMISSION_WRITE_MEDICAL_PROGRAMS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_PATIENTS",
        "PERMISSION_WRITE_PATIENT_CLINICAL",
        "PERMISSION_WRITE_PATIENT_DOCUMENTS",
        "PERMISSION_WRITE_STATUS_OBSERVATIONS",
        "PERMISSION_WRITE_TASKS",
        "PERMISSION_WRITE_VISITS",
        "RESTRICTION_PATIENT_DETAILS_ASSIGNED",
     ],
    "ROLE_SUPER_ADMIN":[
        "PERMISSION_APPROVE_BILLING_INFO_REQUESTS",
        "PERMISSION_APPROVE_TASKS",
        "PERMISSION_BATCH_DELETE_TREATMENT_HISTORY",
        "PERMISSION_CONFIRM_PERSON_PROPOSAL",
        "PERMISSION_CREATE_USER_FROM_PATIENT",
        "PERMISSION_DECIDE_ACCESS_PERMISSIONS",
        "PERMISSION_DOWNLOAD_FILE",
        "PERMISSION_DOWNLOAD_VCM_BILLING_SUMMARY_BY_FACILITY",
        "PERMISSION_DOWNLOAD_VCM_BILLING_TASK_REPORTS",
        "PERMISSION_DOWNLOAD_VISIT_BILLING_EXTENDED_REPORTS",
        "PERMISSION_EXPERIMENTAL_FEATURES",
        "PERMISSION_FILTER_ADVANCED",
        "PERMISSION_FILTER_ASSIGNED_PATIENTS",
        "PERMISSION_FILTER_PATIENT_SPECIALTY",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_GENERATE_AI_SUMMARY",
        "PERMISSION_GENERATE_QRDA",
        "PERMISSION_IMPORT_PATIENT_FROM_CDA",
        "PERMISSION_INCLUDE_ALL_ARSANA_MEDS",
        "PERMISSION_MERGE_PATIENTS",
        "PERMISSION_PATIENT_DETAILS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ACF_REPORT",
        "PERMISSION_READ_ADMIN_DASHBOARD",
        "PERMISSION_READ_ANTIBIOTICS",
        "PERMISSION_READ_ANTIPSYCHOTICS",
        "PERMISSION_READ_APPLICATIONS",
        "PERMISSION_READ_BEDS",
        "PERMISSION_READ_BED_ASSOCIATIONS",
        "PERMISSION_READ_BILLING_CODES",
        "PERMISSION_READ_BILLING_GROUP",
        "PERMISSION_READ_CCM_BILLING",
        "PERMISSION_READ_CDA",
        "PERMISSION_READ_CDS",
        "PERMISSION_READ_CENSUS_GENERAL",
        "PERMISSION_READ_CENSUS_MAP",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CENSUS_TRENDS",
        "PERMISSION_READ_CHARGE_AMOUNT_SETTINGS",
        "PERMISSION_READ_CLAIMS",
        "PERMISSION_READ_CLINICAL_DOCUMENTS",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_COACHING_INFECTION_ADMINISTRATION",
        "PERMISSION_READ_COVID_MAP",
        "PERMISSION_READ_COVID_TESTING",
        "PERMISSION_READ_CUSTOMERS",
        "PERMISSION_READ_EHI_EXPORT",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EMR_UPLOADS",
        "PERMISSION_READ_ERX_ORIGINS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_E_PRESCRIBING_WORKLIST",
        "PERMISSION_READ_FACILITY",
        "PERMISSION_READ_FACILITY_EMR_SETTINGS",
        "PERMISSION_READ_FACILITY_PROPOSALS",
        "PERMISSION_READ_FACILITY_TASKS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_GROUPS",
        "PERMISSION_READ_HE",
        "PERMISSION_READ_HL7_MESSAGES",
        "PERMISSION_READ_IMMUNIZATIONS",
        "PERMISSION_READ_IMPORT",
        "PERMISSION_READ_IMPORT_ACCOUNTS",
        "PERMISSION_READ_IMPORT_ACCOUNTS_LIST",
        "PERMISSION_READ_IMPORT_DOCUMENT",
        "PERMISSION_READ_IMPORT_ERRORS",
        "PERMISSION_READ_IMPORT_PRESET",
        "PERMISSION_READ_INFECTION_ENTRY",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_INITIAL_IMPORT",
        "PERMISSION_READ_INVOICES",
        "PERMISSION_READ_LABELS",
        "PERMISSION_READ_LAB_ACCESSION",
        "PERMISSION_READ_LAB_BILLING",
        "PERMISSION_READ_LAB_BILLING_DETAILS",
        "PERMISSION_READ_LAB_COLLECTION",
        "PERMISSION_READ_LAB_INFECTION_CONTROL",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_REPORT_LIST",
        "PERMISSION_READ_LAB_SHIPMENTS",
        "PERMISSION_READ_LAB_SHIPMENT_STATUS",
        "PERMISSION_READ_LAB_TESTING",
        "PERMISSION_READ_LAB_WORKLIST",
        "PERMISSION_READ_LEVEL_OF_CARE_PAYERS",
        "PERMISSION_READ_LOCATIONS",
        "PERMISSION_READ_LOGS",
        "PERMISSION_READ_MEDICAID_PRICE_SETTINGS",
        "PERMISSION_READ_MEDICAL_PROFESSIONALS",
        "PERMISSION_READ_MEDICAL_PROGRAM_BILLING",
        "PERMISSION_READ_MEDICAL_SPECIALTIES",
        "PERMISSION_READ_MEDICATION_INTERACTIONS",
        "PERMISSION_READ_MEDICATION_LIST",
        "PERMISSION_READ_MISSING_IMPORT_PERMISSIONS",
        "PERMISSION_READ_NEXT_VISIT_SCHEDULER",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_ORDERS_REPORT",
        "PERMISSION_READ_ORGANIZATION",
        "PERMISSION_READ_PART_B_WORKLIST_LIST",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENTS_ASSIGNED",
        "PERMISSION_READ_PATIENT_ABSTRACT",
        "PERMISSION_READ_PATIENT_ASSESSMENTS",
        "PERMISSION_READ_PATIENT_DOCUMENTS",
        "PERMISSION_READ_PATIENT_HIE",
        "PERMISSION_READ_PATIENT_INFORMATION_AUDIT",
        "PERMISSION_READ_PATIENT_INSURANCE",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_PROPOSALS",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PATIENT_RISK_SUMMARY",
        "PERMISSION_READ_PATIENT_TIMELINE",
        "PERMISSION_READ_PHYSICIAN_TEAMS",
        "PERMISSION_READ_PI_MEASURES",
        "PERMISSION_READ_PRODUCTIVITY_REPORT",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_QUESTIONNAIRES",
        "PERMISSION_READ_RCM",
        "PERMISSION_READ_REHOSPITALIZATION",
        "PERMISSION_READ_REMITS",
        "PERMISSION_READ_REPORT_QUEUE",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SCHEDULER_WORKLIST",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SNF_REPORT",
        "PERMISSION_READ_STATUS_OBSERVATIONS",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_SYSTEM_OPERATIONS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_HISTORY",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_TASK_SUMMARY",
        "PERMISSION_READ_TEAM_NOTES",
        "PERMISSION_READ_TRENDS",
        "PERMISSION_READ_TRENDS_EXPERIMENTAL_FEATURES",
        "PERMISSION_READ_UNMATCHED_PATIENT_ACTIONS",
        "PERMISSION_READ_UPLOADED_REPORTS",
        "PERMISSION_READ_USER",
        "PERMISSION_READ_USER_LIST",
        "PERMISSION_READ_USER_PROPOSALS",
        "PERMISSION_READ_VISITS",
        "PERMISSION_READ_VISIT_BILLING",
        "PERMISSION_READ_VISIT_BILLING_AUDIT",
        "PERMISSION_READ_WOUNDS",
        "PERMISSION_RECEIVE_FACILITY_NOTIFICATIONS",
        "PERMISSION_REQUEST_ACCESS_PERMISSIONS",
        "PERMISSION_REQUEST_PERSON_MERGE",
        "PERMISSION_SEND_BILLING_INFO_REQUESTS",
        "PERMISSION_TRANSMIT_CDA",
        "PERMISSION_UPDATE_CENSUS",
        "PERMISSION_UPDATE_TASKS",
        "PERMISSION_USE_DICTATION",
        "PERMISSION_USE_ORDER_DICTATION",
        "PERMISSION_WRITE_APPLICATIONS",
        "PERMISSION_WRITE_BEDS",
        "PERMISSION_WRITE_BED_ASSOCIATIONS",
        "PERMISSION_WRITE_BILLING_CODES",
        "PERMISSION_WRITE_BILLING_GROUP",
        "PERMISSION_WRITE_CAPTCHA",
        "PERMISSION_WRITE_CDS",
        "PERMISSION_WRITE_CENSUS_HISTORY",
        "PERMISSION_WRITE_CHARGE_AMOUNT_SETTINGS",
        "PERMISSION_WRITE_CLAIMS",
        "PERMISSION_WRITE_CLINICAL_DOCUMENTS",
        "PERMISSION_WRITE_CUSTOMERS",
        "PERMISSION_WRITE_DIAGNOSES",
        "PERMISSION_WRITE_DIAGNOSIS_CATEGORIES",
        "PERMISSION_WRITE_DRUG_CATEGORIES",
        "PERMISSION_WRITE_ELIGIBILITY_REQUESTS",
        "PERMISSION_WRITE_EMR_UPLOADS",
        "PERMISSION_WRITE_EPISODIC_PROPOSALS",
        "PERMISSION_WRITE_ERX_ORIGINS",
        "PERMISSION_WRITE_EVENTS",
        "PERMISSION_WRITE_FACILITY",
        "PERMISSION_WRITE_FACILITY_EMR_SETTINGS",
        "PERMISSION_WRITE_FACILITY_PROPOSALS",
        "PERMISSION_WRITE_FLOOR_PLANS",
        "PERMISSION_WRITE_GROUPS",
        "PERMISSION_WRITE_HL7_MESSAGES",
        "PERMISSION_WRITE_IMMUNIZATIONS",
        "PERMISSION_WRITE_IMPORT",
        "PERMISSION_WRITE_IMPORT_ACCOUNTS",
        "PERMISSION_WRITE_IMPORT_PRESET",
        "PERMISSION_WRITE_INFECTION_ENTRIES",
        "PERMISSION_WRITE_LABELS",
        "PERMISSION_WRITE_LAB_SHIPMENT_STATUS",
        "PERMISSION_WRITE_LAB_TESTING",
        "PERMISSION_WRITE_LEVEL_OF_CARE_PAYERS",
        "PERMISSION_WRITE_LOCATIONS",
        "PERMISSION_WRITE_MEDICAID_PRICE_SETTINGS",
        "PERMISSION_WRITE_MEDICAL_PROFESSIONALS",
        "PERMISSION_WRITE_MEDICAL_PROGRAMS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_ORGANIZATION",
        "PERMISSION_WRITE_PATIENTS",
        "PERMISSION_WRITE_PATIENT_ADMIN",
        "PERMISSION_WRITE_PATIENT_CLINICAL",
        "PERMISSION_WRITE_PATIENT_CMRNS",
        "PERMISSION_WRITE_PATIENT_DOCUMENTS",
        "PERMISSION_WRITE_PATIENT_INSURANCE",
        "PERMISSION_WRITE_PATIENT_PROPOSALS",
        "PERMISSION_WRITE_PATIENT_REPRESENTATIVE",
        "PERMISSION_WRITE_PERSONS",
        "PERMISSION_WRITE_PERSON_PROPOSALS",
        "PERMISSION_WRITE_PROGRESS_NOTE",
        "PERMISSION_WRITE_PSYCHOTROPIC_GROUPS",
        "PERMISSION_WRITE_REPORT_QUEUE",
        "PERMISSION_WRITE_STATUS_OBSERVATIONS",
        "PERMISSION_WRITE_SYSTEM_ALERTS",
        "PERMISSION_WRITE_TASKS",
        "PERMISSION_WRITE_TASK_TYPES",
        "PERMISSION_WRITE_UNMATCHED_PATIENT_ACTIONS",
        "PERMISSION_WRITE_UPLOADED_REPORTS",
        "PERMISSION_WRITE_UPLOAD_REPORTS",
        "PERMISSION_WRITE_USER",
        "PERMISSION_WRITE_USER_PASSWORD",
        "PERMISSION_WRITE_USER_PROPOSALS",
        "PERMISSION_WRITE_VISITS",
        "PERMISSION_WRITE_WOUNDS",
        "RESTRICTION_PATIENT_DETAILS_ASSIGNED",
     ],
    "ROLE_SUSPENDED":[
        "HOMEPAGE_SUSPENDED",
     ],
    "ROLE_TASK_HISTORY":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_PATIENTS_ASSIGNED",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASK_HISTORY",
        "PERMISSION_READ_USER",
     ],
    "ROLE_USER":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_USER",
     ],
    "ROLE_USER_PROPOSALS":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_GROUPS",
        "PERMISSION_READ_IMPORT_ACCOUNTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORGANIZATION",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_USER",
        "PERMISSION_READ_USER_PROPOSALS",
        "PERMISSION_WRITE_IMPORT_ACCOUNTS",
        "PERMISSION_WRITE_USER_PROPOSALS",
     ],
    "ROLE_VISIT_PLANNER":[
        "PERMISSION_FILTER_ASSIGNED_PATIENTS",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CENSUS_GENERAL",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_HE",
        "PERMISSION_READ_INVOICES",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_MEDICAL_PROGRAM_BILLING",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ASSIGNED",
        "PERMISSION_READ_PATIENT_ASSESSMENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PATIENT_TIMELINE",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SCHEDULER_WORKLIST",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_TASK_SUMMARY",
        "PERMISSION_READ_USER",
        "PERMISSION_READ_VISITS",
        "PERMISSION_READ_VISIT_BILLING_AUDIT",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_TASKS",
        "PERMISSION_WRITE_VISITS",
        "RESTRICTION_PATIENT_DETAILS_ASSIGNED",
     ],
    "ROLE_WOUND_PHYSICIAN":[
        "PERMISSION_READ_WOUNDS",
        "PERMISSION_WRITE_WOUNDS",
     ],
    "_ROLE_CLINICAL_BASE":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_USER",
     ],
    "_ROLE_CLINICAL_EXTENDED":[
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_REPORT_LIST",
        "PERMISSION_READ_MEDICATION_LIST",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
     ],
    "_ROLE_CLINICAL_MEDIUM":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_USER",
     ],
    "_ROLE_ENTRY_PROPOSALS_BASE":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_GROUPS",
        "PERMISSION_READ_IMPORT_ACCOUNTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORGANIZATION",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_IMPORT_ACCOUNTS",
     ],
    "_ROLE_LAB_BASE":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_USER",
     ],
    "_ROLE_LAB_READ_BASE":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_REPORT_LIST",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_USER",
     ],
    "_ROLE_LAB_TESTING_BASE":[
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_COVID_TESTING",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LAB_TESTING",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ALL",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_LAB_TESTING",
     ],
    "_ROLE_NP":[
        "PERMISSION_FILTER_ADVANCED",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_INFECTION_MAP",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_USER",
        "PERMISSION_READ_VISITS",
        "PERMISSION_WRITE_FACE_TO_FACE_PROPOSALS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_TASKS",
        "PERMISSION_WRITE_VISITS",
     ],
    "_ROLE_PHYSICIAN_BASE":[
        "PERMISSION_FILTER_ASSIGNED_PATIENTS",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CENSUS_GENERAL",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_HE",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LEVEL_OF_CARE_PAYERS",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ASSIGNED",
        "PERMISSION_READ_PATIENT_ASSESSMENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_QUESTIONNAIRES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_STATUS_OBSERVATIONS",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_DIAGNOSES",
        "PERMISSION_WRITE_EVENTS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_PATIENTS",
        "PERMISSION_WRITE_PATIENT_CLINICAL",
        "PERMISSION_WRITE_PATIENT_DOCUMENTS",
        "PERMISSION_WRITE_STATUS_OBSERVATIONS",
        "RESTRICTION_PATIENT_DETAILS_ASSIGNED",
     ],
    "_ROLE_PHYSICIAN_VCM_BASE":[
        "PERMISSION_FILTER_ADVANCED",
        "PERMISSION_FILTER_ASSIGNED_PATIENTS",
        "PERMISSION_FOLLOW_PATIENTS",
        "PERMISSION_READ_ACCESS_LOG",
        "PERMISSION_READ_CCM_BILLING",
        "PERMISSION_READ_CENSUS_GENERAL",
        "PERMISSION_READ_CENSUS_OVERSIGHT",
        "PERMISSION_READ_CLINICAL_GENERAL",
        "PERMISSION_READ_CLINICAL_OVERSIGHT",
        "PERMISSION_READ_ELIGIBILITY_REQUESTS",
        "PERMISSION_READ_EVENTS",
        "PERMISSION_READ_GENERAL",
        "PERMISSION_READ_HE",
        "PERMISSION_READ_INVOICES",
        "PERMISSION_READ_LAB_REPORTS",
        "PERMISSION_READ_LEVEL_OF_CARE_PAYERS",
        "PERMISSION_READ_MEDICAL_PROGRAM_BILLING",
        "PERMISSION_READ_NOTIFICATIONS",
        "PERMISSION_READ_ORDERS",
        "PERMISSION_READ_PATIENTS",
        "PERMISSION_READ_PATIENTS_ASSIGNED",
        "PERMISSION_READ_PATIENT_ASSESSMENTS",
        "PERMISSION_READ_PATIENT_DOCUMENTS",
        "PERMISSION_READ_PATIENT_LIST",
        "PERMISSION_READ_PATIENT_REPRESENTATIVES",
        "PERMISSION_READ_PATIENT_TIMELINE",
        "PERMISSION_READ_PROGRESS_NOTES",
        "PERMISSION_READ_QUESTIONNAIRES",
        "PERMISSION_READ_RESIDENT_NOTES",
        "PERMISSION_READ_SEARCH",
        "PERMISSION_READ_STATUS_OBSERVATIONS",
        "PERMISSION_READ_SYSTEM_ALERTS",
        "PERMISSION_READ_TASKS",
        "PERMISSION_READ_TASK_PROPOSALS",
        "PERMISSION_READ_TASK_SUMMARY",
        "PERMISSION_READ_USER",
        "PERMISSION_WRITE_DIAGNOSES",
        "PERMISSION_WRITE_EPISODIC_PROPOSALS",
        "PERMISSION_WRITE_EVENTS",
        "PERMISSION_WRITE_MEDICAL_PROGRAMS",
        "PERMISSION_WRITE_NOTIFICATION_SETTINGS",
        "PERMISSION_WRITE_PATIENTS",
        "PERMISSION_WRITE_PATIENT_CLINICAL",
        "PERMISSION_WRITE_PATIENT_DOCUMENTS",
        "PERMISSION_WRITE_STATUS_OBSERVATIONS",
        "PERMISSION_WRITE_TASKS",
        "PERMISSION_WRITE_VISITS",
        "RESTRICTION_PATIENT_DETAILS_ASSIGNED",
     ],

};

export function getConst(key: string): string {
    return CONSTANTS[key] ? CONSTANTS[key] : key;
}

export function getConstSet(chunk: string) {
    const keys = Object.keys(CONSTANTS).filter(k => {
        return k.toLowerCase().indexOf(chunk.toLowerCase()) == 0;
    });
    let re = {};
    keys.forEach(a => {
        re[a] = CONSTANTS[a];
    });
    return re;
}

export function getConstSetAsIdName(chunk: string): { id: string, name: string }[] {
    const keys = Object.keys(CONSTANTS).filter(k => {
        return k.toLowerCase().indexOf(chunk.toLowerCase()) == 0;
    });
    let re = [];
    keys.forEach((a) => {
        re.push({id: a, name: CONSTANTS[a]});
    });
    return re;
}

export function getTimezones() {
    let obj: {[key: string]: string} = {};
    TIMEZONES.forEach(b => obj[b] = b);
    return obj;
}

export function getTimezonesAsIdName() {
    return Object.entries(getTimezones()).map(([key, val]) => ({id: key, name: val}));
}

@Injectable({
    providedIn: 'root',
})
export class Constants {
    getConstSet(chunk: string): any {
        return of(this.getConstSetSync(chunk));
    }

    getConstSetSync(chunk: string) {
        return getConstSet(chunk);
    }

    getConstSetAsIdName(chunk: string) {
        return of(this.getConstSetAsIdNameSync(chunk));
    }

    getConstSetAsIdNameSync(chunk: string): { id: string, name: string }[] {
        return getConstSetAsIdName(chunk);
    }

    getTimezones() {
        return of(getTimezones());
    }

    getTimezonesAsIdName() {
        return of(getTimezonesAsIdName());
    }
}
